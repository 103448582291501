export const USER_ROLES = {
  ADMIN: {
    label: 'Admin',
    color: 'danger'
  },
  'DUR-ADMIN': {
    label: 'Kierownik utrzymania ruchu',
    color: 'info'
  },
  'SERVICE-MANAGER': {
    label: 'Kierownik serwisu',
    color: 'info'
  },
  'PROJECT-MANAGER': {
    label: 'Kierownik projektu',
    color: 'info'
  },
  'CONSTRUCTION-MANAGER': {
    label: 'Kierownik budowy',
    color: 'info'
  },
  'WORK-MANAGER': {
    label: 'Kierownik prac',
    color: 'info'
  },
  'BRIGADE-MANAGER': {
    label: 'Brygadzista',
    color: 'info'
  },
  SERVICE: {
    label: 'Serwisant',
    color: 'secondary'
  }
};

export const ADMIN_ROLES = ['SUPERADMIN', 'ADMIN', 'DUR-ADMIN'];
