export default [
  {
    version: '1.3.0',
    publish: '2024-07-02',
    logs: {
      Dodano: [
        'Przeniesienie lokalizacji menu listy na wyższy poziom',
        'Opcje edycji pól listy kontrolnej',
        'Pole ID sprzętu do wyszukiwania sprzętu w formularzu transferu',
        'Opcję wykluczenia dat dla urządzeń przypisanych do budowy',
        'Modal z polem notatek do odrzucania transferu',
        'Opcję tworzenia transferu sprzętu bezpośrednio w liście sprzętu',
        'Opcje edycji dla dekad w edycji budowy',
        'Dane nazwy harmonogramu do żądania raportu budowy',
        'Symbol MPK do danych budowy',
        'Modal z danymi szczegółowymi transferu na stronie listy transferów',
        'Modal z danymi szczegółowymi przypisanego do budowy urządzenia',
        'Aktualizację domyślnych dat dekad',
        'Aktualizację typów budowy',
        'Aktualizację informacji o sprzęcie',
        'Aktualizację wyświetlania danych kolumny sprzętu na liście transferów'
      ],
      Naprawiono: [
        'Dodano informację o dozwolonych typach plików, zawężono dozwolone typy plików do jpg, png i pdf',
        'Dodano powiadomienie o nieprawidłowym typie pliku',
        'Naprawiono tytuł sprzętu w dodawaniu transferu',
        'Naprawiono problemy z listą kategorii',
        'Naprawiono sortowanie według nazwy na liście zadań',
        'Naprawiono listę transferów',
        'Inne drobne blędy'
      ]
    }
  },
  {
    version: '1.2.2',
    publish: '2024-06-05',
    logs: {
      Naprawiono: [
        'Naprawiono błąd w szczegółach sprzętu, gdy nie wybrano producenta',
        'Usunięto prefiks "#" z identyfikatora sprzętu',
        'Zaktualizowano paginację, dodano opcję zmiany liczby elementów wyświetlanych na stronie'
      ]
    }
  },
  {
    version: '1.2.1',
    publish: '2024-05-21',
    logs: {
      Naprawiono: [
        'Naprawiono drobne problemy związane z transferami',
        'Zaktualizowano mechanizm pobierania listy brygad',
        'Zaktualizowano wyświetlanie i opcje aktualizacji budowy'
      ]
    }
  },
  {
    version: '1.2.0',
    publish: '2023-04-30',
    logs: {
      Dodano: [
        'Dodano ustawienie ważne/nieważne dla elementów listy kontrolnej',
        'Dodano funckjonalność zarządzania budowami wraz z harmonogramem dekad',
        'Dodano funckjonalność zarządzania brygadami wraz z przypisaniem użytkowników',
        'Zaimplementowano funkcjonalność listy transferowej z możliwością przekazywania urządzeń między budowami',
        'Zaktualizowano mechanizm podsumowania budowy i generowania raportu',
        'Zaktualizowano role w formularzu tworzenia użytkownika',
        'Zaktualizowano komponenty oparte na rolach'
      ],
      Naprawiono: ['Drobne błędy w formularzu listy kontrolnej']
    }
  },
  {
    version: '1.1.0',
    publish: '2023-12-10',
    logs: {
      Dodano: [
        'Dodano kosz dla zadań i zrefaktoryzowano usuwanie',
        'Zrefaktoryzowano akcje masowe, mechanizm pobierania dla każdej tabeli, dodano kosz dla sprzętu, zrefaktoryzowano eksporty',
        'Dodano funkcję trwałego usuwania z kosza',
        'Dodano przycisk cofnij po usunięciu zadania',
        'Filtry zadań: zamieniono pola wyboru statusu'
      ],
      Naprawiono: [
        'Zadania: Ukryto przyciski edycji i utworzenia nowego zadania z widoku szczegółów zadania, jeśli zadanie znajduje się w koszu'
      ]
    }
  },
  {
    version: '1.0.0',
    publish: '2023-12-10',
    logs: {
      Dodano: [
        'Filtrowanie tabeli z wyposażeniem, użytkownikami, urządzeniami i listami kontrolnymi',
        'Funkcjonalność eksportu tabeli',
        'Obsługę pola awaria urządzenia',
        'Pole priorytetu w formularzu kontaktowym',
        'Modyfikację komponentu wyboru daty'
      ],
      Naprawiono: [
        'Błędy filtrowania, eksportu i inne mniejsze problemy związane z tabelami'
      ]
    }
  },
  {
    version: '1.0.0-beta.4',
    publish: '2023-10-26',
    logs: {
      Dodano: [
        'Możliwość wyboru wielu list kontrolnych dla jednego zadania',
        'Możliwość duplikowania list kontrolnych',
        'Możliwość zmiany kolejności pól w listach kontrolnych',
        'Pole typu tak/nie w polach niestandardowych w listach kontrolnych',
        'Wizualną reprezentację pól typu tak/nie',
        'Możliwość aktywacji zadania z ekranu edycji'
      ],
      Naprawiono: [
        'Błąd wyświetlania komunikatu o braku uprawnień użytkownika',
        'Brak parametru w komponencie resetowania hasła',
        'Nieprawiłowe elementy powiązanye z plikiem raportu'
      ]
    }
  },
  {
    version: '1.0.0-beta.3',
    publish: '2023-10-02',
    logs: {
      Dodano: [
        'Pola zgłaszającego w formularzu zadania',
        'Kolumna z autorem zadania w tabeli listy zadań',
        'Zaktualizowane domyślne ustawienia prywatności zadania',
        'Tłumaczenia dla etykiet tooltipów w menu'
      ],
      Naprawiono: [
        'Błąd wyświetlania komunikatów logowania',
        'Brak strony po kliknięciu linku do resetowania hasła',
        'Możliwości edycji danych użytkownika',
        'Połączenie formularza resetowania hasła profilu z API',
        'Podstawowe ustawienia dokumentu HTML',
        'Błąd wyświetlania powiadomień w menu podręcznym',
        'Błędy zmian masowych w tabeli list kontrolnych',
        'Wyświetlanie linków do raportów',
        'Błąd wyświetlania aktywności użytkownika',
        'Wyświetlanie zadań powiązanych w profilu użytkownika'
      ]
    },
    children:
      'W obecnej wersji aplikacji zoptymalizowano połączenia z serwerem oraz wprowadzono następujące zmiany:'
  },
  {
    version: '1.0.0-beta.2',
    publish: '2023-09-20',
    logs: {
      Dodano: [
        'Możliwość wysyłania zgłoszeń do pomocy technicznej',
        'Możliwość wyświetlania lokalizacji użytkownika na mapie',
        'Dodatkowa kolumna z nazwą pracownika w tabeli listy zadań',
        'Widok tabeli dla kolekcji list kontrolnych'
      ],
      Naprawiono: [
        'Błąd wyświetlania kalendarza w polu daty',
        'Błąd wyświetlania nazwy roli użytkownika',
        'Błąd wyświetlania liczby elementów na stronie w tabelach'
      ]
    }
  },
  {
    version: '1.0.0-beta.1',
    publish: '2023-09-17',
    logs: {
      Naprawiono: [
        'Błąd wyświetlania pól raportu w zadaniach zarchiwizowanych',
        'Brak tłumaczenia na polski nazw ról użytkowników',
        'Brak walidacji pola adresu użytkownika',
        'Błąd wyświetlania menu podręcznego na liście zadań',
        'Błąd filtrowania użytkowników po roli serwisowej',
        'Błąd wyświetlania dodatkowych opcji logowania',
        'Błąd wyświetlania opcji prywatności zadania',
        'Błąd wyświetlania adresu e-mail w linkach na stronie błędu'
      ]
    }
  },
  {
    version: '1.0.0-beta.0',
    publish: '31-08-2023',
    children: 'Publikacja wersji beta'
  },
  {
    version: '1.0.0-alpha.0',
    publish: '28-02-2023',
    children: 'Publikacja wersji alpha'
  }
];
