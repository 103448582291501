import team1 from 'assets/img/team/avatar.png';

export default [
  {
    id: 0,
    avatarSrc: team1,
    name: 'Jan Kowalski',
    institution: 'Harvard Korea Society',
    profileLink: '/user/profile',
    institutionLink: '#!',
    mutual: 5,
    status: 'status-away'
  }
];
