import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import CreateEquipment from 'components/app/equipments/create-equipment/CreateEquipment';
import EquipmentList from 'components/app/equipments/equipment-list/EquipmentList';
import EquipmentManufacturerList from 'components/app/equipments/equipment-manufacturer-list/EquipmentManufacturerList';
import EquipmentDetail from 'components/app/equipments/equipment-detail/EquipmentDetail';
import EquipmentCategoryList from 'components/app/equipments/equipment-category-list/EquipmentCategoryList';
import EquipmentTrash from 'components/app/equipments/equipment-trash/EquipmentTrash';

import CreateConstruction from 'components/app/constructions/pages/create-construction/CreateConstruction';
import ConstructionList from 'components/app/constructions/pages/construction-list/ConstructionList';
import ConstructionTransferList from 'components/app/constructions/pages/construction-transfer-list/ConstructionTransferList';
import ConstructionDetail from 'components/app/constructions/pages/construction-detail/ConstructionDetail';
import ConstructionCategoryList from 'components/app/constructions/pages/construction-category-list/ConstructionCategoryList';
import ConstructionTrash from 'components/app/constructions/pages/construction-trash/ConstructionTrash';

import CreateTask from 'components/app/tasks/create-task/CreateTask';
import TaskList from 'components/app/tasks/task-list/TaskList';
import TaskDetail from 'components/app/tasks/task-detail/TaskDetail';
import ChecklistCollection from 'components/app/tasks/task-checklists/ChecklistCollection';
import CreateChecklist from 'components/app/tasks/task-checklists/CreateChecklist';
import TaskCategoryList from 'components/app/tasks/task-category-list/TaskCategoryList';
import TaskLocationList from 'components/app/tasks/task-locations/TaskLocationList';
import TaskTrash from 'components/app/tasks/task-trash/TaskTrash';

import UserList from 'components/app/users/user-list/UserList';
import UserProfile from 'components/app/users/user-profile/UserProfile';
import CreateUser from 'components/app/users/create-user/CreateUser';

import Chat from 'components/app/chat/Chat';
import Calendar from 'components/app/calendar/Calendar';
import ProjectManagement from 'components/dashboards/project-management';
import FaqAccordion from 'components/pages/faq/FaqAccordion';
import HelpForm from 'components/pages/help/HelpForm';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitForgetPassword from 'components/authentication/split/ForgotPassword';
import SplitPasswordReset from 'components/authentication/split/PasswordReset';
import SplitLockScreen from 'components/authentication/split/LockScreen';

import AppContext from 'context/Context';

import Changelog from 'components/documentation/change-log/ChangeLog';
import PrivateRoute from 'components/utilities/PrivateRoute';
import BrigadeList from 'components/app/brigades/pages/brigade-list/BrigadeList';
import CreateBrigade from 'components/app/brigades/pages/create-brigade/CreateBrigade';
import BrigadeDetail from 'components/app/brigades/pages/brigade-detail/BrigadeDetail';
import BrigadeTrash from 'components/app/brigades/pages/brigade-trash/BrigadeTrash';

const MANAGER_ACCESS = [
  'SUPERADMIN',
  'ADMIN',
  'DUR-ADMIN',
  'SERVICE-MANAGER',
  'CONSTRUCTION-MANAGER'
];

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  const createPrivateRoute = (
    path,
    element,
    authorizeRoles = MANAGER_ACCESS
  ) => {
    return {
      path,
      element: (
        <PrivateRoute authorizeRoles={authorizeRoles}>{element}</PrivateRoute>
      )
    };
  };

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="/errors/404" element={<Error404 />} />
          <Route path="/errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}
        <Route path="/login" element={<SplitLogin />} />
        <Route path="/logout" element={<SplitLogout />} />
        <Route path="/forgot-password" element={<SplitForgetPassword />} />
        <Route
          path="/reset-password/:resetToken"
          element={<SplitPasswordReset />}
        />
        <Route path="/lock-screen" element={<SplitLockScreen />} />

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route {...createPrivateRoute('/', <ProjectManagement />)} />

          {/*App*/}
          <Route {...createPrivateRoute('/calendar', <Calendar />)} />
          <Route {...createPrivateRoute('/chat', <Chat />)} />

          {/* Tasks */}
          <Route {...createPrivateRoute('/create-task', <CreateTask />)} />
          <Route {...createPrivateRoute('/create-task/:id', <CreateTask />)} />
          <Route {...createPrivateRoute('/task/:taskId', <TaskDetail />)} />
          <Route {...createPrivateRoute('/task-list', <TaskList />)} />
          <Route
            {...createPrivateRoute(
              '/task-checklist-list',
              <ChecklistCollection />
            )}
          />
          <Route
            {...createPrivateRoute('/create-checklist/', <CreateChecklist />)}
          />
          <Route
            {...createPrivateRoute(
              '/task-category-list/',
              <TaskCategoryList />
            )}
          />
          <Route
            {...createPrivateRoute('/task-location-list', <TaskLocationList />)}
          />
          <Route
            {...createPrivateRoute(
              '/checklist/:checklistId',
              <CreateChecklist />
            )}
          />
          <Route
            {...createPrivateRoute('/task/*', <Navigate to="/task-list" />)}
          />
          <Route {...createPrivateRoute('/task-trash', <TaskTrash />)} />

          {/* Equipment routes */}
          <Route
            {...createPrivateRoute(
              '/equipment/:equipmentId',
              <EquipmentDetail />
            )}
          />
          <Route
            {...createPrivateRoute('/create-equipment', <CreateEquipment />)}
          />
          <Route
            {...createPrivateRoute(
              '/create-equipment/:id',
              <CreateEquipment />
            )}
          />
          <Route
            {...createPrivateRoute('/equipment-list/', <EquipmentList />)}
          />
          <Route
            {...createPrivateRoute(
              '/equipment-manufacturer-list',
              <EquipmentManufacturerList />
            )}
          />
          <Route
            {...createPrivateRoute(
              '/equipment-category-list',
              <EquipmentCategoryList />
            )}
          />
          <Route
            {...createPrivateRoute(
              '/equipment/*',
              <Navigate to="/equipment-list" />
            )}
          />
          <Route
            {...createPrivateRoute('/equipment-trash', <EquipmentTrash />)}
          />

          {/* Brigade routes */}
          <Route
            {...createPrivateRoute('/brigade/:brigadeId', <BrigadeDetail />)}
          />
          <Route
            {...createPrivateRoute('/create-brigade', <CreateBrigade />)}
          />
          <Route
            {...createPrivateRoute('/create-brigade/:id', <CreateBrigade />)}
          />
          <Route {...createPrivateRoute('/brigade-list/', <BrigadeList />)} />
          <Route
            {...createPrivateRoute(
              '/brigade/*',
              <Navigate to="/brigade-list" />
            )}
          />
          <Route {...createPrivateRoute('/brigade-trash', <BrigadeTrash />)} />
          {/* Constructions routes */}
          <Route
            {...createPrivateRoute(
              '/construction/:constructionId',
              <ConstructionDetail />
            )}
          />
          <Route
            {...createPrivateRoute(
              '/create-construction',
              <CreateConstruction />
            )}
          />
          <Route
            {...createPrivateRoute(
              '/create-construction/:id',
              <CreateConstruction />
            )}
          />
          <Route
            {...createPrivateRoute('/construction-list/', <ConstructionList />)}
          />
          <Route
            {...createPrivateRoute(
              '/construction-transfer-list/',
              <ConstructionTransferList />
            )}
          />
          <Route
            {...createPrivateRoute(
              '/construction-category-list',
              <ConstructionCategoryList />
            )}
          />
          <Route
            {...createPrivateRoute(
              '/construction/*',
              <Navigate to="/construction-list" />
            )}
          />
          <Route
            {...createPrivateRoute(
              '/construction-trash',
              <ConstructionTrash />
            )}
          />

          {/* Users */}
          <Route
            {...createPrivateRoute('/user-list', <UserList />, ['ADMIN'])}
          />
          <Route {...createPrivateRoute('/user/:userId', <UserProfile />)} />
          <Route
            {...createPrivateRoute('/create-user', <CreateUser />, ['ADMIN'])}
          />
          <Route
            {...createPrivateRoute('/create-user/:userId', <CreateUser />)}
          />
          <Route
            {...createPrivateRoute('/user/*', <Navigate to="/user-list" />)}
          />

          {/* Pages */}
          <Route path="/help/faq-accordion" element={<FaqAccordion />} />
          <Route path="/help/contact-form" element={<HelpForm />} />

          {/* Changelog */}
          <Route {...createPrivateRoute('/changelog', <Changelog />)} />
        </Route>
        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
