import React from 'react';
import Avatar from 'components/common/Avatar';
import LockScreenForm from 'components/authentication/LockScreenForm';
import { Col, Row } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar.png';
import bgVideo from 'assets/video/romgos_intro_final.mp4';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import Flex from 'components/common/Flex';

const LockScreen = () => {
  return (
    <AuthSplitLayout bgProps={{ video: [bgVideo], position: '50% 20%' }}>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Flex alignItems="center">
            <Avatar src={avatar} size="4xl" className="me-4" />
            <div>
              <h4>Witaj!</h4>
              <p className="mb-0">
                Wprowadź swoje hasło, <br /> aby uzyskać dostęp do panelu.
              </p>
            </div>
          </Flex>
          <LockScreenForm className="mt-4" userName="Mateusz Cierzniak" />
        </Col>
      </Row>
    </AuthSplitLayout>
  );
};

export default LockScreen;
