import React, { useState, useEffect } from 'react';
import EquipmentDetails from './EquipmentDetails';
import EquipmentUpload from './EquipmentUpload';
import EquipmentBar from './EquipmentBar';
import { Col, Form, Row } from 'react-bootstrap';
import EquipmentOtherInfo from './EquipmentOtherInfo';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import LoadingSpinner from 'components/common/LoadingSpinner';

const fieldMapping = {
  equipmentId: 'equipmentId',
  equipmentCatalogNumber: 'catalogNumber',
  equipmentName: 'name',
  equipmentSerialNumber: 'serialNumber',
  equipmentInventoryNumber: 'inventoryNumber',
  equipmentDescription: 'description',
  costPerDay: 'costPerDay',
  assignedUser: 'assignedUser',
  manufacturer: 'manufacturer',
  categories: 'categories',
  storageType: 'storageType',
  uploadedFiles: 'uploadedFiles',
  malfunction: 'malfunction'
};

const CreateEquipment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {};

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/equipment/${id}`
          );
          const equipment = response.data.equipment;
          if (equipment) {
            Object.keys(equipment).forEach(key => {
              const formField = Object.keys(fieldMapping).find(
                field => fieldMapping[field] === key
              );
              if (formField) {
                if (key === 'manufacturer') {
                  setValue(formField, {
                    value: equipment.manufacturer._id,
                    label: equipment.manufacturer.name
                  });
                } else if (key === 'categories') {
                  const categories = equipment.categories.map(category => ({
                    value: category._id,
                    label: category.name
                  }));
                  setValue(formField, categories);
                } else if (key === 'uploadedFiles') {
                  setValue('uploadedFiles', '');
                } else if (key === '_id') {
                  setValue('equipmentCatalogNumber', equipment._id);
                } else {
                  setValue(formField, equipment[key]);
                }
              }
            });
          }
        }
      } catch (error) {
        console.error('Failed to fetch equipment', error);
        toast.error('Błąd podczas pobierania urządzenia');
      }
    };

    fetchData();
  }, [id, setValue]);

  const onSubmit = async data => {
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(data).forEach(key => {
      const apiField = fieldMapping[key];
      if (apiField && data[key]) {
        if (key === 'manufacturer') {
          formData.append(apiField, data[key].value);
        } else if (key === 'categories') {
          data[key].forEach(category => {
            formData.append(apiField, category.value);
          });
        } else if (key === 'uploadedFiles') {
          for (let i = 0; i < data[key].length; i++) {
            formData.append(apiField, data[key][i]);
          }
        } else if (key === 'malfunction') {
          return;
        } else {
          formData.append(apiField, data[key]);
        }
      }
    });

    formData.append(
      fieldMapping['malfunction'],
      data['malfunction'] ? true : false
    );

    try {
      if (id) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/equipment/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        toast.success('Urządzenie zostało zaktualizowane');
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/equipment/add`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        toast.success(
          id ? 'Urządzenie zostało zapisane' : 'Urządzenie zostało dodane'
        );
      }
    } catch (error) {
      console.error('Failed to create/update equipment', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
      navigate('/equipment-list', { replace: true });
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
      <Row className="g-3">
        <Col xs={12}>
          <EquipmentBar title={id ? 'Edytuj urządzenie' : 'Dodaj urządzenie'} />
        </Col>
        <Col lg={8}>
          <EquipmentDetails
            register={register}
            errors={errors}
            setValue={setValue}
          />
          <EquipmentUpload
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar mb-3">
            <EquipmentOtherInfo
              register={register}
              setValue={setValue}
              control={control}
            />
          </div>
        </Col>
        <Col xs={12}>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {id ? 'Zapisz urządzenie' : 'Dodaj urządzenie'}
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateEquipment;
