import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { handleMultiple } from '../../../../../helpers/bulkAction';
import { BrigadeFilters } from './BrigadeFilters';
import { CSVLink } from 'react-csv';
import { useRef, useEffect } from 'react';
import dayjs from 'dayjs';

const BrigadeTableHeader = ({
  selectedRowIds,
  setBrigadeList,
  brigadeList,
  setQueryParams
}) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [open, setOpen] = useState(false);
  const csvLink = useRef();
  const [items, setItems] = useState([]);

  const headers = [
    { label: 'Numer ID', key: 'equipmentId' },
    { label: 'Urządzenie niesprawne?', key: 'malfunction' },
    { label: 'Nazwa urządzenia', key: 'name' },
    { label: 'Numer katalogowy', key: 'catalogNumber' },
    { label: 'Numer seryjny', key: 'serialNumber' },
    { label: 'Numer inwentarzowy', key: 'inventoryNumber' },
    { label: 'Producent', key: 'manufacturer.name' }
  ];

  useEffect(() => {
    setItems(
      Object.keys(selectedRowIds).map(id => {
        const brigade = brigadeList.find(item => item._id === id);

        return {
          ...brigade
        };
      })
    );
  }, [selectedRowIds]);

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'delete':
        handleMultiple(
          selectedRowIds,
          'brigade/delete',
          brigadeList,
          setBrigadeList,
          {
            deletedAt: new Date()
          }
        );
        break;
      case 'export':
        if (items.length < 0) return;
        csvLink?.current?.link?.click();
        break;
    }
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Brygady</h5>
      </Col>
      <Col
        xs={8}
        sm="auto"
        className="ms-auto text-end ps-0 d-flex justify-content-end"
      >
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-3"
          className="mx-2"
          onClick={() => setOpen(!open)}
        >
          <span>Filtruj</span>
        </IconButton>
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Działania masowe"
              value={selectedAction}
              onChange={e => setSelectedAction(e.target.value)}
            >
              <option value="">Działania masowe</option>
              <option value="delete">Usuń</option>
              <option value="export">Eksportuj</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleApplyAction}
              disabled={!selectedAction}
            >
              Zastosuj
            </Button>

            {items.length >= 0 && (
              <CSVLink
                data={items}
                headers={headers}
                filename={`brygady_${dayjs().format('DD-MM-YYYY_HH-mm')}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            )}
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              as={Link}
              to={`/create-brigade`}
              transform="shrink-3"
              className="ms-2"
            >
              <span className="d-none d-sm-inline-block ms-1">
                Dodaj brygade
              </span>
            </IconButton>
          </div>
        )}
      </Col>
      <Col xs={12} className="mt-2">
        <BrigadeFilters
          open={open}
          setQueryParams={setQueryParams}
          setItems={setBrigadeList}
        />
      </Col>
    </Row>
  );
};

BrigadeTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  brigadeList: PropTypes.array,
  setBrigadeList: PropTypes.func,
  setQueryParams: PropTypes.func
};

export default BrigadeTableHeader;
