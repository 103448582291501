import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form } from 'react-bootstrap';
import CustomButtonGroup from 'components/common/CustomButtonGroup';

const ChecklistCustomFieldEditable = ({
  type,
  name,
  options,
  handleRemove,
  handleMoveDown,
  handleMoveUp,
  id,
  register
}) => {
  const [hasOptions, setHasOptions] = useState(
    type === 'select' || type === 'radio' || type === 'checkboxes'
  );
  const optionsRegister = register(`customFields.${id}.options`, {
    value: options
      ?.map(option => (option.valid ? '!' : '') + option.name)
      .join(', ')
  });

  return (
    <Card.Body className="p-0 position-relative hover-actions-trigger mb-3">
      <CustomButtonGroup
        handleRemove={handleRemove}
        id={id}
        handleMoveDown={handleMoveDown}
        handleMoveUp={handleMoveUp}
      />
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1">
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group controlId="name">
              <Form.Label>Nazwa pola</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder=""
                {...register(`customFields.${id}.name`, {
                  required: true,
                  value: name
                })}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Typ</Form.Label>
              <Form.Select
                size="sm"
                style={{ padding: '6px 12px' }}
                aria-label="Default select example"
                {...register(`customFields.${id}.type`, {
                  required: true,
                  value: type,
                  onChange: e => {
                    if (
                      e.target.value === 'select' ||
                      e.target.value === 'radio' ||
                      e.target.value === 'checkboxes'
                    ) {
                      setHasOptions(true);
                    } else {
                      setHasOptions(false);
                    }
                  }
                })}
              >
                <option value="text">Tekstowe</option>
                <option value="number">Liczbowe</option>
                <option value="email">E-mail</option>
                <option value="checkboxes">Pola wielokrotnego wyboru</option>
                <option value="radio">Pola pojedynczego wyboru</option>
                <option value="textarea">Pole opisowe</option>
                <option value="select">Wybór</option>
                <option value="boolean">Tak/Nie</option>
              </Form.Select>
            </Form.Group>
          </Col>
          {hasOptions && (
            <Col md="12">
              <Form.Group>
                <Form.Label>Opcje Pola</Form.Label>
                <Form.Control as="textarea" rows={3} {...optionsRegister} />
                <Form.Text className="text-warning fs--1">
                  * Oddziel opcje przecinkiem, możesz oznaczyć poprawne opcje
                  znakiem <strong>!</strong>
                </Form.Text>
              </Form.Group>
            </Col>
          )}
        </Row>
      </div>
    </Card.Body>
  );
};

ChecklistCustomFieldEditable.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  handleRemove: PropTypes.func,
  handleMoveDown: PropTypes.func,
  handleMoveUp: PropTypes.func,
  id: PropTypes.string,
  register: PropTypes.func
};

export default ChecklistCustomFieldEditable;
