export const users = [
  {
    id: 1,
    firstName: 'Jan',
    lastName: 'Kowalski',
    avatar: {
      name: 'Jan Kowalski'
    },
    position: 'Specjalista od napraw silników',
    address: 'ul. Kwiatowa 5, 00-001 Warszawa',
    email: 'jan.kowalski@example.com',
    phone: '+48 123 456 789',
    description:
      'Doświadczony specjalista od napraw silników z ponad 10-letnim doświadczeniem w branży motoryzacyjnej.',
    createdAt: '2019/01/12 23:13',
    recentActivity: '2023/02/26',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  },
  {
    id: 2,
    firstName: 'Anna',
    lastName: 'Nowak',
    avatar: {
      name: 'Anna Nowak'
    },
    position: 'Ekspert ds. układów hamulcowych',
    address: 'ul. Piękna 10, 00-002 Warszawa',
    email: 'anna.nowak@example.com',
    phone: '+48 123 456 789',
    description:
      'Absolwentka mechaniki z 5-letnim doświadczeniem w branży motoryzacyjnej, specjalizująca się w naprawie i konserwacji układów hamulcowych.',
    createdAt: '2019/02/03 10:23',
    recentActivity: '2023/02/25',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  },
  {
    id: 3,
    firstName: 'Piotr',
    lastName: 'Wiśniewski',
    avatar: {
      name: 'Piotr Wiśniewski'
    },
    position: 'Lakiernik specjalizujący się w blacharstwie',
    address: 'ul. Główna 15, 00-003 Warszawa',
    email: 'piotr.wisniewski@example.com',
    phone: '+48 123 456 789',
    description:
      'Absolwent lakiernictwa z 7-letnim doświadczeniem w branży motoryzacyjnej, specjalizujący się w blacharstwie.',
    createdAt: '2019/03/17 14:47',
    recentActivity: '2023/02/24',
    role: 'SERVICE',
    inTraining: true,
    allowLogin: false
  },
  {
    id: 4,
    firstName: 'Magdalena',
    lastName: 'Kowalczyk',
    avatar: {
      name: 'Magdalena Kowalczyk'
    },
    position: 'Specjalista od napraw zawieszenia',
    address: 'ul. Mickiewicza 20, 00-004 Warszawa',
    email: 'magdalena.kowalczyk@example.com',
    phone: '+48 123 456 789',
    description:
      'Doświadczona specjalistka od napraw zawieszenia z 5-letnim doświadczeniem w branży motoryzacyjnej.',
    createdAt: '2019/05/01 09:10',
    recentActivity: '2023/02/23',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  },
  {
    id: 5,
    firstName: 'Anna',
    lastName: 'Nowak',
    avatar: {
      name: 'Anna Nowak'
    },
    position: 'Specjalista od napraw układów hamulcowych',
    address: 'ul. Piękna 10, 02-001 Warszawa',
    email: 'anna.nowak@example.com',
    phone: '+48 123 456 789',
    description:
      'Wysoko wykwalifikowany specjalista od napraw układów hamulcowych z ponad 15-letnim doświadczeniem w branży motoryzacyjnej.',
    createdAt: '2020/03/10 14:20',
    recentActivity: '2023/03/10',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  },
  {
    id: 6,
    firstName: 'Marek',
    lastName: 'Kot',
    avatar: {
      name: 'Marek Kot'
    },
    position: 'Mistrz warsztatu',
    address: 'ul. Kwiatowa 20, 00-001 Warszawa',
    email: 'marek.kot@example.com',
    phone: '+48 123 456 789',
    description:
      'Doświadczony mistrz warsztatu z ponad 20-letnim doświadczeniem w branży motoryzacyjnej. Specjalizuje się w diagnostyce samochodowej oraz naprawie skomplikowanych usterek.',
    createdAt: '2018/10/10 08:00',
    recentActivity: '2023/03/08',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  },
  {
    id: 7,
    firstName: 'Piotr',
    lastName: 'Lewandowski',
    avatar: {
      name: 'Piotr Lewandowski'
    },
    position: 'Specjalista od napraw układów kierowniczych',
    address: 'ul. Słoneczna 5, 03-001 Warszawa',
    email: 'piotr.lewandowski@example.com',
    phone: '+48 123 456 789',
    description:
      'Doświadczony specjalista od napraw układów kierowniczych z ponad 10-letnim doświadczeniem w branży motoryzacyjnej. Posiada szeroką wiedzę na temat systemów kierowniczych różnych marek samochodów.',
    createdAt: '2020/12/05 12:00',
    recentActivity: '2023/03/06',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  },
  {
    id: 9,
    firstName: 'Tomasz',
    lastName: 'Kaczmarek',
    avatar: {
      name: 'Tomasz Kaczmarek'
    },
    position: 'Mechanik samochodowy',
    address: 'ul. Powstańców Warszawy 7, 04-001 Warszawa',
    email: 'tomasz.kaczmarek@example.com',
    phone: '+48 123 456 789',
    description:
      'Doświadczony mechanik samochodowy z ponad 7-letnim doświadczeniem w branży motoryzacyjnej. Posiada szeroką wiedzę na temat różnych systemów mechanicznych oraz elektrycznych w samochodach.',
    createdAt: '2021/06/20 09:45',
    recentActivity: '2023/03/10',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  },
  {
    id: 10,
    firstName: 'Magdalena',
    lastName: 'Nowak',
    avatar: {
      name: 'Magdalena Nowak'
    },
    position: 'Diagnosta samochodowy',
    address: 'ul. Słoneczna 3, 02-001 Warszawa',
    email: 'magdalena.nowak@example.com',
    phone: '+48 123 456 789',
    description:
      'Doświadczona diagnostka samochodowa z ponad 5-letnim doświadczeniem w branży motoryzacyjnej. Posiada rozległą wiedzę na temat różnych systemów elektronicznych w samochodach, w tym systemów diagnostycznych i sterowników silnika.',
    createdAt: '2022/01/15 18:30',
    recentActivity: '2023/03/10',
    role: 'SERVICE',
    inTraining: false,
    allowLogin: false
  }
];
