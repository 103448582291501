import React from 'react';
import PropTypes from 'prop-types';

import SoftBadge from './SoftBadge';

import { USER_ROLES } from '../../constants/users';

const RoleBadge = ({ role }) => {
  if (USER_ROLES[role] === undefined) {
    return null;
  }
  return (
    <SoftBadge pill bg={USER_ROLES[role].color}>
      {USER_ROLES[role].label}
    </SoftBadge>
  );
};

RoleBadge.propTypes = {
  role: PropTypes.string.isRequired
};

export default RoleBadge;
