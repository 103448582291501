import { useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';

const BrigadeOtherInfo = () => {
  const {
    formState: { errors },
    control
  } = useFormContext();
  const [userOptions, setUserOptions] = useState([]);

  const fetchData = async () => {
    try {
      // Fetch user options
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`
      );
      const userOptionsData = usersResponse?.data?.users?.map(
        ({ _id, firstName, lastName }) => ({
          value: _id,
          label: `${firstName} ${lastName}`
        })
      );
      setUserOptions(userOptionsData || []);
    } catch (error) {
      console.error(error);
      toast.error('Błąd podczas pobierania listy użytkowników');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Card>
        <Card.Header as="h5">Pozostałe informacje</Card.Header>
        <Card.Body className="bg-light">
          <Form.Group className="mb-3">
            <Flex className="flex-between-center">
              <Form.Label>Brygadzista</Form.Label>
            </Flex>
            <Controller
              name="manager"
              rules={{ required: 'To pole jest wymagane' }}
              render={({ ref, field }) => (
                <MultiSelect
                  ref={ref}
                  {...field}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={userOptions}
                  placeholder="Wybierz managera..."
                  isInvalid={!!errors.user}
                />
              )}
              control={control}
            />
            {errors?.users && (
              <Form.Text className="text-danger">
                {errors.users.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Flex className="flex-between-center">
              <Form.Label>Pracownicy</Form.Label>
            </Flex>
            <Controller
              name="users"
              rules={{ required: 'To pole jest wymagane' }}
              render={({ ref, field }) => (
                <MultiSelect
                  ref={ref}
                  {...field}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  options={userOptions}
                  placeholder="Wybierz pracowników..."
                  isInvalid={!!errors.user}
                />
              )}
              control={control}
            />
            {errors?.users && (
              <Form.Text className="text-danger">
                {errors.users.message}
              </Form.Text>
            )}
          </Form.Group>
        </Card.Body>
      </Card>
    </>
  );
};

export default BrigadeOtherInfo;
