import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import { Card, Dropdown } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

const ConstructionUpload = () => {
  const [files, setFiles] = useState([]);

  const { setValue } = useFormContext();

  useEffect(() => {
    if (setValue) {
      setValue('uploadedFiles', files);
    }
  }, [files, setValue]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpg', 'image/png', 'application/pdf'],
    onDrop: acceptedFiles => {
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    },
    onDropRejected: () => {
      toast.error('Nieprawidłowy format pliku');
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Dodaj pliki</Card.Header>
      <Card.Body className="bg-light">
        <div {...getRootProps({ className: 'dropzone-area py-6' })}>
          <input name="uploadedFiles" {...getInputProps()} />
          <Flex justifyContent="center" alignItems="center" direction="column">
            <Flex>
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-1 mb-0 text-700">Upuść pliki tutaj</p>
            </Flex>
            <p className="fs-0 mb-0 text-400">
              Dozwolone typy plików: jpg, png, pdf
            </p>
          </Flex>
        </div>
        <div>
          {files.map((file, index) => (
            <Flex
              alignItems="center"
              className="py-3 border-bottom btn-reveal-trigger"
              key={index}
            >
              <img
                className="rounded"
                width={40}
                height={40}
                src={file.preview}
                alt={file.path}
              />

              <Flex
                justifyContent="between"
                alignItems="center"
                className="ms-3 flex-1"
              >
                <div>
                  <p className="mb-0 text-700">{file.name}</p>
                  <p className="mb-0 text-muted">{getSize(file.size)}</p>
                </div>
                <CardDropdown>
                  <Dropdown.Item onClick={() => handleRemove(file.path)}>
                    Usuń
                  </Dropdown.Item>
                </CardDropdown>
              </Flex>
            </Flex>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ConstructionUpload;
