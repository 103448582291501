import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AuthContext from 'context/auth/AuthContext';
import { useForm } from 'react-hook-form';

const LoginForm = ({ hasLabel }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loginUser } = authContext;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated]);

  const onSubmit = async data => {
    await loginUser({
      email: data.email,
      password: data.password,
      remember: data.remember
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Adres email w domenie romgos.pl</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Adres email w domenie romgos.pl' : ''}
          {...register('email', {
            required: 'Adres e-mail jest wymagany',
            pattern: {
              value: /^([a-zA-Z0-9._%+-]+@romgos\.pl|[A-Z]{2}_[a-z]\.[a-z]+)$/,
              message: 'Wymagany poprawny adrese w domenie @romgos.pl'
            }
          })}
          isInvalid={!!errors.email}
          type="text"
        />
        <Form.Control.Feedback type="invalid">
          {errors.email && errors.email.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Hasło do konta domenowego</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Hasło' : ''}
          {...register('password', { required: 'Hasło jest wymagane' })}
          isInvalid={!!errors.password}
          type="password"
        />
        <Form.Control.Feedback type="invalid">
          {errors.password && errors.password.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input type="checkbox" {...register('remember')} />
            <Form.Check.Label className="mb-0 text-700">
              Zapamiętaj mnie
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={`/forgot-password`}>
            Zapomniałeś hasła?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={isSubmitting}
        >
          Zaloguj się
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
