import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import CategoryTableHeader from './LocationTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import { useForm } from 'react-hook-form';

const columns = [
  {
    accessor: 'name',
    Header: 'Nazwa lokalizacji',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name } = rowData.row.original;
      return (
        <p className="mb-0">
          <strong>{name}</strong>
        </p>
      );
    }
  },
  {
    accessor: 'streetName',
    Header: 'Nazwa ulicy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { streetName } = rowData.row.original;
      return (
        <p className="mb-0">
          <strong>{streetName}</strong>
        </p>
      );
    }
  },
  {
    accessor: 'city',
    Header: 'Miasto',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { city } = rowData.row.original;
      return (
        <p className="mb-0">
          <strong>{city}</strong>
        </p>
      );
    }
  },
  {
    accessor: 'zip',
    Header: 'Kod pocztowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { zip } = rowData.row.original;
      return (
        <p className="mb-0">
          <strong>{zip}</strong>
        </p>
      );
    }
  },
  {
    accessor: 'country',
    Header: 'Kraj',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { country } = rowData.row.original;
      return (
        <p className="mb-0">
          <strong>{country}</strong>
        </p>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id, name } = rowData.row.original;
      const { handleDeleteClick, handleEditClick, loadingRows } =
        rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item
              as="button"
              onClick={() => handleEditClick(_id, name)}
            >
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const TaskLocationList = ({ loadingRows, setRowLoading }) => {
  const [locations, setLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    unregister
  } = useForm({});

  const handleDeleteClick = async id => {
    const confirmed = window.confirm(
      'Jesteś pewny, że chcesz usunąć tę lokalizacje?'
    );

    if (confirmed) {
      try {
        setRowLoading(id, true);
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/tasks/locations/${id}`
        );
        toast.success(`Usunięto ${response?.data?.location?.name}`);
        setLocations(locations.filter(location => location._id !== id));
      } catch (error) {
        console.error(error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  const handleEditClick = id => {
    const location = locations.find(location => location._id === id);
    setValue('name', location.name);
    setValue('streetName', location.streetName);
    setValue('city', location.city);
    setValue('zip', location.zip);
    setValue('country', location.country);
    setValue('id', location._id);
    setShowModal(true);
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/locations/`
      );
      setLocations(response.data.locations);
    } catch (error) {
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    if (locations.length > 0) return;
    fetchLocations();
  }, [locations]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleDeleteClick,
        handleEditClick,
        loadingRows
      }))}
      data={locations}
      selection
      sortable
      pagination
    >
      <Card className="mb-3">
        <Card.Header>
          <CategoryTableHeader
            table
            setLocations={setLocations}
            locations={locations}
            showModal={showModal}
            setShowModal={setShowModal}
            register={register}
            unregister={unregister}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={locations.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

TaskLocationList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(TaskLocationList);
