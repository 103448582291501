import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import useTranslation from 'hooks/useTranslation';
import Flex from 'components/common/Flex';
import BrigadeTableHeader from './BrigadeTableHeader';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const columns = [
  {
    accessor: 'name',
    Header: 'Nazwa brygady',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name } = rowData.row.original;
      return (
        <>
          <Link to={`/brigade/${_id}`}>
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'manager',
    Header: 'Brygadzista',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { manager } = rowData.row.original;
      return (
        <>
          <Link to={`/user/${manager?._id}`}>
            <strong>{`${manager?.firstName} ${manager?.lastName}`}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'users',
    Header: 'Pracownicy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { users } = rowData.row.original;
      return (
        <>
          {users.map((user, index) => (
            <React.Fragment key={index}>
              <Link to={`/user/${user?._id}`}>
                <strong>{`${user?.firstName} ${user?.lastName}`}</strong>
              </Link>
              {index < users.length - 1 && ', '}
            </React.Fragment>
          ))}
        </>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id } = rowData.row.original;
      const { handleDeleteClick, loadingRows } = rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/brigade/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item as={Link} to={`/create-brigade/${_id}`}>
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const BrigadeList = ({ loadingRows }) => {
  const [brigadeList, setBrigadeList] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/brigade?${queryParams}&deletedAt[in]`
      );
      setBrigadeList(response.data.brigade);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleRevertClick = async id => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/brigade/${id}`,
        {
          deletedAt: null
        }
      );
      setBrigadeList([response.data.brigade, ...brigadeList]);
      toast.success('Brygada przywrócone pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleDeleteClick = async id => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/brigade/${id}`, {
        deletedAt: new Date()
      });

      setBrigadeList(brigadeList.filter(brigade => brigade._id !== id));
      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Brygada usunięte pomyślnie.
            <span
              className="p-0 link-dark text-decoration-underline"
              onClick={() => handleRevertClick(id)}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    fetchData();
  }, [queryParams]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleDeleteClick,
        loadingRows
      }))}
      data={brigadeList}
      selection
      sortable
      pagination
      perPage={25}
      setClearFunction={setClearSelect}
    >
      <Card className="mb-3">
        <Card.Header>
          <BrigadeTableHeader
            table
            setBrigadeList={setBrigadeList}
            brigadeList={brigadeList}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={brigadeList.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

BrigadeList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(BrigadeList);
