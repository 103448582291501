import React from 'react';
import { PropTypes } from 'prop-types';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import Lottie from 'lottie-react';
import warningLight from 'assets/img/animated-icons/warning-light.json';

const EquipmentDetailHeader = ({ equipment }) => {
  const navigate = useNavigate();

  const handleRevertClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/equipment/${equipment._id}`,
        {
          deletedAt: null
        }
      );
      toast.success('Urządzenie przywrócone pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('task-list');
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/equipment/${equipment._id}`,
        {
          deletedAt: new Date()
        }
      );

      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Urządzenie usunięte pomyślnie.
            <span
              className="p-0 link-dark text-decoration-underline"
              onClick={handleRevertClick}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('task-list');
    }
  };

  if (!equipment)
    return (
      <Card className="p-0 mb-3">
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="fs--1 ms-2 flex-1">
                  <h5 className="fs-0 text-capitalize"></h5>
                  <p className="mb-0 text-capitalize"></p>
                </div>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );

  const { name, _id, malfunction } = equipment;

  return (
    <Card className="p-0 mb-3">
      <Card.Body className="overflow-hidden">
        <Row className="flex-center">
          <Col>
            <Flex>
              <div className="fs--1 ms-2 flex-1">
                <h5
                  className={`d-flex fs-0 text-capitalize ${
                    malfunction && 'text-warning'
                  }`}
                >
                  {malfunction && (
                    <Lottie
                      animationData={warningLight}
                      style={{
                        height: '32px',
                        width: '32px',
                        margin: '-7px 0'
                      }}
                    />
                  )}
                  {name}
                </h5>
                <p className="mb-0 text-capitalize">{_id}</p>
              </div>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0">
            <Flex>
              {equipment.deletedAt ? (
                <Button
                  variant="falcon-success"
                  size="sm"
                  className="px-4 px-sm-5"
                  onClick={handleRevertClick}
                >
                  Przywróć
                </Button>
              ) : (
                <>
                  <Button
                    variant="falcon-default"
                    size="sm"
                    className="me-2"
                    as={Link}
                    to={`/create-task?equipmentID=${_id}&equipmentName=${name}`}
                  >
                    Stwórz zadanie
                  </Button>
                  <Button
                    className="me-2"
                    variant="falcon-default"
                    size="sm"
                    icon="share-alt"
                    as={Link}
                    to={`/create-equipment/${_id}`}
                  >
                    Edytuj
                  </Button>
                  <Button
                    variant="falcon-danger"
                    size="sm"
                    className="px-4 px-sm-5"
                    onClick={handleDeleteClick}
                  >
                    Usuń
                  </Button>
                </>
              )}
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EquipmentDetailHeader.propTypes = {
  equipment: PropTypes.object
};

export default EquipmentDetailHeader;
