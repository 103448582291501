import React from 'react';
import useFormatDate from 'hooks/useFormatDate';
import SharedFiles from 'components/common/SharedFiles';
import { Row, Card, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { constructionTypes } from '../create-construction/ConstructionOtherInfo';
import ConstructionDetailSummary from './ConstructionDetailSummary';

const ConstructionDetailAside = ({ construction }) => {
  const {
    _id,
    startDate,
    locationName,
    streetName,
    city,
    zip,
    uploadedFiles,
    manager,
    brigades
  } = construction;
  const [formattedStartDate] = useFormatDate(startDate);

  if (!construction) {
    return (
      <Card className="mb-3 fs--1">
        <Card.Body>
          <Placeholder as={Row} animation="glow" />
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6>Miejsce realizacji</h6>
          <div className="mb-1">
            {locationName} <br />
            {streetName} <br />
            {zip} {city} <br />
          </div>
          <h6 className="mt-3">Osoba odpowiedzialna</h6>
          <p className="mb-1">{`${manager.firstName} ${manager.lastName}`}</p>
          <h6 className="mt-3">Przypisane brygady</h6>
          <ul className="mb-1 ps-3">
            {brigades.map(brigade => (
              <li key={brigade._id}>{brigade.name}</li>
            ))}
          </ul>
          <h6 className="mt-3">Data i czas realizacji</h6>
          <p className="mb-1">
            od: {formattedStartDate} <br />
          </p>
          <h6 className="mt-3">Typ budowy</h6>
          <p className="mb-1">
            {constructionTypes.find(type => type.value === construction.type)
              ?.label || 'Brak informacji'}
          </p>
        </Card.Body>
      </Card>
      <ConstructionDetailSummary construction={construction} />
      {uploadedFiles.length > 0 && (
        <SharedFiles
          className="mb-3"
          files={uploadedFiles}
          parent={{ _id, type: 'constructions' }}
        />
      )}
    </div>
  );
};

ConstructionDetailAside.propTypes = {
  construction: PropTypes.object.isRequired
};

export default ConstructionDetailAside;
