import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './context/reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import AuthState from 'context/auth/AuthState';
import ActivityState from 'context/activities/ActivityState';

import { setAuthToken } from 'helpers/setAuthToken';

import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

Sentry.init({
  dsn:
    process.env.REACT_APP_ENVIRONMENT !== 'development'
      ? process.env.REACT_APP_SENTRY_DSN
      : null,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect
      )
    }),
    new Sentry.Replay()
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate:
    process.env.REACT_APP_ENVIRONMENT === 'development' ? 0 : 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    Sentry.withScope(scope => {
      scope.setExtras({
        errorMessage: error.response?.data?.message,
        dataSent: error.response?.config?.data,
        headersSent: error.response?.config?.headers
      });
      Sentry.captureException(error);
    });
    return Promise.reject(error);
  }
);

const Main = props => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  return (
    <AuthState>
      <AppContext.Provider value={{ config, setConfig, configDispatch }}>
        <ActivityState>{props.children}</ActivityState>
      </AppContext.Provider>
    </AuthState>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
