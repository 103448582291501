const translations = {
  en: {
    'Invalid credentials': 'Invalid credentials',
    'Unauthorized access': 'Unauthorized access',
    'Invalid email or password': 'Invalid email or password',
    'Duplicate email entered': 'Duplicate email entered',
    'User created successfully': 'User created successfully',
    'Session Token is invalid. Login and try again.':
      'Session Token is invalid. Login and try again.',
    'Please add a valid email': 'Please add a valid email',
    'Duplicate _id entered': 'Duplicate _id entered',
    'Duplicate name entered': 'Duplicate name entered',
    'Duplicate _fts,_ftsx entered': 'Duplicate name entered',
    'Checklist created': 'Checklist created',
    'Name cannot be more than 100 characters':
      'Name cannot be more than 100 characters',
    'Equipment is assigned to a task and cannot be deleted':
      'Equipment is assigned to a task and cannot be deleted',
    'Manufacturer is assigned to an equipment and cannot be deleted':
      'Manufacturer is assigned to an equipment and cannot be deleted',
    'Checklist updated': 'Checklist updated',
    'Checklist not found': 'Checklist not found',
    'File deleted successfully': 'File deleted successfully',
    'Task updated': 'Task updated',
    'Task deleted': 'Task deleted',
    'User updated': 'User updated',
    'Equipment deleted successfully': 'Equipment deleted successfully',
    'Could not send email due to an error.':
      'Could not send email due to an error.',
    'Password Reset Token is invalid or has expired':
      'Password Reset Token is invalid or has expired',
    'Current password is incorrect': 'Current password is incorrect',
    'Passwords do not match': 'Passwords do not match',
    'Transfer request already exists for this equipment':
      'Transfer request already exists for this equipment',
    'Please choose destination brigade': 'Please choose destination brigade',
    'Date already excluded': 'Date already excluded',
    'Please add a MPK symbol': 'Please add a MPK symbol',
    'MPK symbol cannot be more than 10 characters':
      'MPK symbol cannot be more than 10 characters',
    'User already exists': 'User already exists',
    'User not found': 'User not found',
    'Please provide correct user email': 'Please provide correct user email'
  },
  pl: {
    'Invalid credentials': 'Nieprawidłowe dane logowania',
    'Unauthorized access': 'Brak uprawnień do wybranej funkcji',
    'Invalid email or password': 'Nieprawidłowe dane logowania',
    'Duplicate email entered': 'Podany adres email już istnieje',
    'User created successfully': 'Użytkownik został utworzony',
    'Session Token is invalid. Login and try again.':
      'Sesja wygasła. Zaloguj się ponownie.',
    'Please add a valid email': 'Podaj prawidłowy adres email',
    'Duplicate _id entered': 'Podany numer identyfikacyjny już istnieje',
    'Duplicate name entered': 'Podana nazwa już istnieje',
    'Duplicate _fts,_ftsx entered': 'Podana nazwa już istnieje',
    'Checklist created': 'Checklista została utworzona',
    'Name cannot be more than 100 characters':
      'Nazwa nie może być dłuższa niż 100 znaków',
    'Equipment is assigned to a task and cannot be deleted':
      'Urządzenie jest przypisane do zadania i nie może zostać usunięte',
    'Manufacturer is assigned to an equipment and cannot be deleted':
      'Producent jest przypisany do urządzenia i nie może zostać usunięty',
    'Checklist updated': 'Checklista została zaktualizowana',
    'Checklist not found': 'Nie znaleziono takiej checklisty',
    'File deleted successfully': 'Plik został usunięty',
    'Task updated': 'Zadanie zaktualizowane',
    'Task deleted': 'Zadanie usunięte',
    'User updated': 'Użytkownik zaktualizowany',
    'Equipment deleted successfully': 'Urządzenie usunięte',
    'Could not send email due to an error.':
      'Nie udało się wysłać wiadomości email z powodu błędu.',
    'Password Reset Token is invalid or has expired':
      'Niewłaściwy klucz resetowania hasła lub klucz wygasł.',
    'Current password is incorrect': 'Obecne hasło jest nieprawidłowe',
    'Passwords do not match': 'Podane hasła nie są takie same',
    'Transfer request already exists for this equipment':
      'Transfer już istnieje dla tego urządzenia',
    'Please choose destination brigade': 'Wybierz brygadę docelową',
    'Date already excluded': 'Podana data została już wykluczona',
    'Please add a MPK symbol': 'Podaj symbol MPK',
    'MPK symbol cannot be more than 10 characters':
      'Symbol MPK nie może być dłuższy niż 10 znaków',
    'User already exists': 'Użytkownik już istnieje',
    'User not found': 'Użytkownik nie został znaleziony',
    'Please provide correct user email': 'Podaj prawidłowy adres email'
  }
};

export default translations;
