import React from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Placeholder } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import PropTypes from 'prop-types';

const BrigadeDetailHeader = ({ brigade }) => {
  const { _id } = brigade;
  const navigate = useNavigate();

  const handleRevertClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/brigade/${brigade._id}`,
        {
          deletedAt: null
        }
      );
      toast.success('Budowa przywrócone pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('/brigade-list');
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/brigade/${brigade._id}`,
        {
          deletedAt: new Date()
        }
      );

      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Budowa usunięta pomyślnie.
            <span
              className="p-0 link-dark text-decoration-underline"
              onClick={handleRevertClick}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('/brigade-list');
    }
  };

  if (!brigade) {
    return (
      <Card className="mb-3">
        <Card.Body className="bg-light border-top">
          <Placeholder as={Row} animation="glow" />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="p-0 mb-3">
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="fs-1 flex-1">
                  <h3 className="fs-1 text-capitalize">{brigade.name}</h3>
                </div>
              </Flex>
            </Col>
            <Col md="auto" className="mt-4 mt-md-0">
              <Flex>
                {brigade.deletedAt ? (
                  <Button
                    variant="falcon-success"
                    size="sm"
                    className="px-4 px-sm-5"
                    onClick={handleRevertClick}
                  >
                    Przywróć
                  </Button>
                ) : (
                  <>
                    <Button
                      as={Link}
                      to={`/create-brigade/${_id}`}
                      className="me-2"
                      variant="falcon-default"
                      size="sm"
                      icon="share-alt"
                    >
                      Edytuj
                    </Button>
                    <Button
                      variant="falcon-danger"
                      size="sm"
                      className="px-4 px-sm-5"
                      onClick={handleDeleteClick}
                    >
                      Usuń
                    </Button>
                  </>
                )}
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

BrigadeDetailHeader.propTypes = {
  brigade: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    manager: PropTypes.object.isRequired,
    users: PropTypes.array,
    deletedAt: PropTypes.string
  }).isRequired
};

export default BrigadeDetailHeader;
