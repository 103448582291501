import { Collapse, Form, Col, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const BrigadeFilters = ({ open, setQueryParams, setItems }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      categories: [],
      type: [],
      users: []
    }
  });
  const [userOptions, setUserOptions] = useState([]);
  const formValues = watch();

  const fetchData = async () => {
    try {
      // Fetch user options
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/users?role=SERVICE`
      );
      const userOptionsData = usersResponse.data.users.map(
        ({ _id, firstName, lastName }) => ({
          value: _id,
          label: `${firstName} ${lastName}`
        })
      );
      setUserOptions(userOptionsData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const onSubmit = data => {
    let queryParts = [];

    if (data.name) {
      queryParts.push(`q=${encodeURIComponent(data.name)}`);
    }

    if (data.users.length > 0) {
      data.users.map(user => {
        queryParts.push(`users[in]=${user.value}`);
      });
    }

    setQueryParams(queryParts?.join('&') || '');
    setItems([]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Collapse in={open}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="name" className=" d-flex flex-column">
              <Form.Label>Szukaj</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nazwa brygady"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="users" className="mb-3 d-flex flex-column">
              <Form.Label>Pracownik</Form.Label>
              <Controller
                name="users"
                render={({ ref, field }) => (
                  <MultiSelect
                    ref={ref}
                    {...field}
                    closeMenuOnSelect={false}
                    isMulti
                    isOptionDisabled={option =>
                      option.isDisabled || formValues.users?.length >= 5
                    }
                    clearable
                    options={userOptions}
                    placeholder="Wybierz pracownika..."
                    isInvalid={!!errors.users}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Flex
              justifyContent="end"
              alignItems="end"
              style={{
                height: '100%'
              }}
            >
              <Button
                size="sm"
                variant="falcon-primary"
                type="submit"
                transform="shrink-3"
              >
                <span>Zastosuj filtry</span>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

BrigadeFilters.propTypes = {
  open: PropTypes.bool,
  setQueryParams: PropTypes.func,
  setItems: PropTypes.func
};
