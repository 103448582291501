import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomButtonGroup = ({
  handleMoveUp,
  handleMoveDown,
  handleRemove,
  id
}) => {
  return (
    <div
      id={id}
      className="position-absolute end-0 top-0 gap-2 z-index-1 hover-actions"
    >
      <Button size="sm" variant="link" className="p-0" onClick={handleMoveUp}>
        <FontAwesomeIcon className="text-info" icon="chevron-up" />
      </Button>
      <Button size="sm" variant="link" className="p-0" onClick={handleMoveDown}>
        <FontAwesomeIcon className="text-info" icon="chevron-down" />
      </Button>
      <Button
        size="sm"
        variant="link"
        className="p-0"
        onClick={() => handleRemove(id)}
      >
        <FontAwesomeIcon className="text-danger" icon="times-circle" />
      </Button>
    </div>
  );
};

const ChecklistCustomFieldPreview = ({ name, type, options, index }) => {
  {
    switch (type) {
      case 'number':
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
          >
            <Form.Label>{name}</Form.Label>
            <Form.Control
              type="number"
              name={`customField${index}`}
              placeholder={`Podaj ${name.toLowerCase()} ...`}
            />
          </Form.Group>
        );

      case 'email':
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
          >
            <Form.Label>{name}</Form.Label>
            <Form.Control
              type="email"
              name={`customField${index}`}
              placeholder={`Podaj ${name.toLowerCase} ...`}
            />
          </Form.Group>
        );

      case 'checkboxes':
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
          >
            <Form.Label>{name}</Form.Label>

            {options?.map((option, key) => (
              <Form.Check id={`customCheckbox${name}${key}`} key={key}>
                <Form.Check.Input value={option.name} type="checkbox" />
                <Form.Check.Label className="mb-0">
                  {option.name}
                </Form.Check.Label>
              </Form.Check>
            ))}
          </Form.Group>
        );
      case 'radio':
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
            controlId={name}
          >
            <Form.Label>{name}</Form.Label>

            {options?.map((option, key) => (
              <Form.Check id={`customRadio${name}${key}`} key={key}>
                <Form.Check.Input
                  value={option.name}
                  type="radio"
                  name={name}
                />
                <Form.Check.Label className="mb-0">
                  {option.name}
                </Form.Check.Label>
              </Form.Check>
            ))}
          </Form.Group>
        );

      case 'select':
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
          >
            <Form.Label>{name}</Form.Label>
            <Form.Select>
              {options?.map((option, key) => (
                <option
                  key={key}
                  value={option.name}
                  style={{
                    color: option.valid
                      ? 'var(--falcon-success)'
                      : 'var(--falcon-danger)'
                  }}
                >
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        );

      case 'textarea':
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
            controlId="ControlTextarea"
          >
            <Form.Label>{name}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={`Opisz ${name?.toLowerCase()} ...`}
            />
          </Form.Group>
        );

      case 'boolean':
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
          >
            <Form.Label>{name}</Form.Label>
            <div className="d-flex gap-2">
              <span className="me-2">NIE</span>
              <Form.Check id="defaultSwitch" type="switch" />
              <span>TAK</span>
            </div>
          </Form.Group>
        );

      default:
        return (
          <Form.Group
            className={classNames('position-relative', {
              'mt-3': index !== 0
            })}
          >
            <Form.Label>{name}</Form.Label>
            <Form.Control
              type="text"
              name={`name${index}`}
              placeholder={`Opisz ${name?.toLowerCase()} ...`}
            />
          </Form.Group>
        );
    }
  }
};

CustomButtonGroup.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  handleRemove: PropTypes.func,
  handleMoveDown: PropTypes.func,
  handleMoveUp: PropTypes.func
};

ChecklistCustomFieldPreview.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  index: PropTypes.number,
  register: PropTypes.func,
  setValue: PropTypes.func,
  id: PropTypes.string,
  handleRemove: PropTypes.func,
  handleMoveDown: PropTypes.func,
  handleMoveUp: PropTypes.func
};

export default ChecklistCustomFieldPreview;
