import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row } from 'react-bootstrap';

const ConstructionBar = ({ title, className, isLoading }) => {
  const { id } = useParams();

  return (
    <Card className={className}>
      <Card.Body>
        <Row className="flex-between-center">
          {title && (
            <Col md>
              <h5 className="mb-2 mb-md-0">{title}</h5>
            </Col>
          )}
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-primary"
              type="submit"
              disabled={isLoading}
            >
              {id ? 'Zapisz' : 'Opublikuj'}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ConstructionBar.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ConstructionBar;
