import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const BrigadeDetails = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>Nazwa brygady</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  {...register('name', {
                    required: 'To pole jest wymagane'
                  })}
                  placeholder="Nazwa budowy"
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  Pole jest wymagane
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="description">
                <Form.Label>Opis</Form.Label>
                <Form.Control
                  type="textarea"
                  name="name"
                  {...register('description', {
                    required: 'To pole jest wymagane'
                  })}
                  placeholder="Opis brygady"
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  Pole jest wymagane
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default BrigadeDetails;
