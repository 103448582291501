import React, { useReducer, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import AuthContext from './AuthContext';

import useTranslation from 'hooks/useTranslation';
import { setAuthToken } from 'helpers/setAuthToken';
import { authReducer } from '../reducers/authReducer';
import { getItemFromStore } from 'helpers/utils';

const AuthState = props => {
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    isLoading: true,
    user: null,
    error: null
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const token = getItemFromStore('token');
    if (token) {
      setAuthToken(token);
    }
    loadUser();
  }, []);

  /**
   * Load user
   * @returns {void}
   */
  const loadUser = async () => {
    dispatch({ type: 'LOAD_USER_REQUEST' });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/profile`
      );
      dispatch({
        type: 'LOAD_USER_SUCCESS',
        payload: res.data.user
      });
    } catch (error) {
      dispatch({
        type: 'AUTH_ERROR'
      });
    }
  };

  /**
   * Login user
   * @param {object} formData
   * @param {string} formData.email
   * @param {string} formData.password
   * @returns {void}
   */
  const loginUser = async formData => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        formData
      );

      const decodedToken = jwt_decode(res.data.token);
      if (decodedToken.role === 'SERVICE') {
        toast.error('Nie masz uprawnień do tego panelu');
        return;
      }

      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: res.data
      });

      setAuthToken(res.data.token);

      toast.success('Zalogowano pomyślnie', { theme: 'colored' });

      loadUser();
    } catch (error) {
      if (!error.response) {
        toast.error('Błąd łączenia z serwerem');
        return;
      }
      toast.error(useTranslation(error.response.data.message, 'pl'));
    }
  };

  /**
   * Logout user
   * @returns {void}
   */
  const logoutUser = () => dispatch({ type: 'LOGOUT' });

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        isLoading: state.isLoading,
        user: state.user,
        error: state.error,
        loadUser,
        loginUser,
        logoutUser
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthState.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthState;
