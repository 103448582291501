import React from 'react';
import { taskPropTypes } from 'shapes/taskPropTypes';
import { Row, Card, Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TaskDetailGmap from './ConstructionDetailGmap';

const ConstructionDetailContent = ({ construction }) => {
  const { description, categories, location, name } = construction;

  if (!construction) {
    return (
      <Card>
        <Card.Body>
          <h5 className="fs-0 mb-3">Opis zadania</h5>
          <Placeholder as={Row} animation="glow" />
          <h5 className="fs-0 mt-5 mb-2 ">Kategorie</h5>
          <p>Brak kategorii</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body>
        <h5 className="fs-0 mb-3">{name}</h5>
        {description}
        {categories.length > 0 && (
          <>
            <h5 className="fs-0 mt-5 mb-2">Kategorie</h5>
            {categories.map(category => (
              <Link
                key={category._id}
                to="#!"
                className="badge border link-secondary text-decoration-none me-1"
              >
                {category.name}
              </Link>
            ))}
          </>
        )}
        <TaskDetailGmap center={location.coordinates} />
      </Card.Body>
    </Card>
  );
};

ConstructionDetailContent.propTypes = {
  construction: taskPropTypes.isRequired
};

export default ConstructionDetailContent;
