import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import EquipmentTableHeader from './EquipmentTrashHeader';

const columns = [
  {
    accessor: 'equipmentId',
    Header: 'Numer id',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { equipmentId } = rowData.row.original;
      return <p className="mb-0">#{equipmentId}</p>;
    }
  },
  {
    accessor: 'name',
    Header: 'Nazwa urządzenia',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name } = rowData.row.original;
      return (
        <>
          <Link to={`/equipment/${_id}`}>
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'catalogNumber',
    Header: 'Numer katalogowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { catalogNumber } = rowData.row.original;
      return <p className="mb-0">{catalogNumber}</p>;
    }
  },
  {
    accessor: 'serialNumber',
    Header: 'Numer seryjny',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { serialNumber } = rowData.row.original;
      return <p className="mb-0">{serialNumber}</p>;
    }
  },
  {
    accessor: 'inventoryNumber',
    Header: 'Numer inwentarzowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { inventoryNumber } = rowData.row.original;
      return <p className="mb-0">{inventoryNumber}</p>;
    }
  },
  {
    accessor: 'manufacturer',
    Header: 'Producent',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { manufacturer } = rowData.row.original;
      return <p className="mb-0">{manufacturer?.name}</p>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id } = rowData.row.original;
      const { loadingRows, handleRestore, handlePermanentDelete } =
        rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/equipment/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-success"
              onClick={() => handleRestore(_id)}
            >
              Przywróć
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handlePermanentDelete(_id)}
            >
              Usuń na stałe
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const EquipmentTrash = ({ loadingRows, setRowLoading }) => {
  const [equipmentList, setEquipmentList] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment?${queryParams}&deletedAt[nin]`
      );
      setEquipmentList(response.data.equipment);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleRestore = async id => {
    try {
      setRowLoading(id, true);
      await axios.put(`${process.env.REACT_APP_API_URL}/equipment/${id}`, {
        deletedAt: null
      });
      toast.success('Urządzenie przywrócone pomyślnie');
      setEquipmentList([]);
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(id, false);
    }
  };

  const handlePermanentDelete = async id => {
    const confirmation = window.confirm(
      'Czy na pewno chcesz usunąć urządzenie na stałe?'
    );
    if (confirmation) {
      try {
        setRowLoading(id, true);
        await axios.delete(`${process.env.REACT_APP_API_URL}/equipment/${id}`);
        toast.success('Urządzenie usunięte pomyślnie');
        setEquipmentList([]);
      } catch (error) {
        console.error('Error updating data:', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    if (equipmentList.length > 0) return;

    fetchData();
  }, [queryParams, equipmentList]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleRestore,
        handlePermanentDelete,
        loadingRows
      }))}
      data={equipmentList}
      selection
      sortable
      pagination
      perPage={25}
      setClearFunction={setClearSelect}
    >
      <Card className="mb-3">
        <Card.Header>
          <EquipmentTableHeader
            table
            setEquipmentList={setEquipmentList}
            equipmentList={equipmentList}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={equipmentList.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

EquipmentTrash.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(EquipmentTrash);
