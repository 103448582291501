import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Form, Button, Modal } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import EquipmentTableHeader from './EquipmentTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import { toast } from 'react-toastify';
import axios from 'axios';
import useTranslation from 'hooks/useTranslation';
import Flex from 'components/common/Flex';
import Lottie from 'lottie-react';
import warningLight from 'assets/img/animated-icons/warning-light.json';
import MultiSelect from 'components/common/MultiSelect';
import debounce from 'lodash';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import pl from 'date-fns/locale/pl';
import classNames from 'classnames';
import AuthContext from 'context/auth/AuthContext';
import { ADMIN_ROLES } from 'constants/users';

const columns = [
  {
    accessor: 'equipmentId',
    Header: 'Numer id',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { equipmentId } = rowData.row.original;
      return <p className="mb-0">{equipmentId}</p>;
    }
  },
  {
    accessor: 'malfunction',
    disableSortBy: true,
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { malfunction } = rowData.row.original;

      if (!malfunction) return;
      return (
        <Lottie
          animationData={warningLight}
          style={{ height: '32px', width: '32px', margin: '-16px 0' }}
        />
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Nazwa urządzenia',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name, malfunction } = rowData.row.original;
      return (
        <>
          <Link
            className={malfunction ? 'text-warning' : ''}
            to={`/equipment/${_id}`}
          >
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'catalogNumber',
    Header: 'Numer katalogowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { catalogNumber } = rowData.row.original;
      return <p className="mb-0">{catalogNumber}</p>;
    }
  },
  {
    accessor: 'serialNumber',
    Header: 'Numer seryjny',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { serialNumber } = rowData.row.original;
      return <p className="mb-0">{serialNumber}</p>;
    }
  },
  {
    accessor: 'inventoryNumber',
    Header: 'Numer inwentarzowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { inventoryNumber } = rowData.row.original;
      return <p className="mb-0">{inventoryNumber}</p>;
    }
  },
  {
    accessor: 'manufacturer',
    Header: 'Producent',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { manufacturer } = rowData.row.original;
      return <p className="mb-0">{manufacturer?.name}</p>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id, malfunction } = rowData.row.original;
      const {
        handleDeleteClick,
        handleMakeMalfunction,
        loadingRows,
        handleShowModal,
        user
      } = rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/equipment/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            {ADMIN_ROLES.includes(user.role) && (
              <Dropdown.Item as="button" onClick={() => handleShowModal(_id)}>
                Transfer urządzenia
              </Dropdown.Item>
            )}
            <Dropdown.Divider as="div" />
            <Dropdown.Item as={Link} to={`/create-equipment/${_id}`}>
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className={malfunction ? 'text-success' : 'text-danger'}
              onClick={() => handleMakeMalfunction(_id, malfunction)}
            >
              {`Oznacz jako ${malfunction ? 'sprawne' : 'niesprawne'}`}
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const EquipmentList = ({ loadingRows, setRowLoading }) => {
  const [equipmentList, setEquipmentList] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasCustomTransferDate, setHasCustomTransferDate] = useState(false);
  const [customTransferDate, setCustomTransferDate] = useState(null);
  const [destinationConstruction, setDestinationConstruction] = useState([]);
  const [constructions, setConstructions] = useState([]);

  const { user } = useContext(AuthContext);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment?${queryParams}&deletedAt[in]`
      );
      setEquipmentList(response.data.equipment);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleMakeMalfunction = async (id, malfunction) => {
    try {
      setRowLoading(id, true);
      await axios.put(`${process.env.REACT_APP_API_URL}/equipment/${id}`, {
        malfunction: !malfunction
      });
      toast.success('Oznaczono urządzenie jako niesprawne');
      setEquipmentList(
        equipmentList.map(equipment =>
          equipment._id === id
            ? { ...equipment, malfunction: !malfunction }
            : equipment
        )
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(id, false);
    }
  };

  const handleRevertClick = async id => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/equipment/${id}`, {
        deletedAt: null
      });
      setEquipmentList([]);
      toast.success('Urządzenie przywrócone pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleDeleteClick = async id => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/equipment/${id}`, {
        deletedAt: new Date()
      });

      setEquipmentList([]);
      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Urządzenie usunięte pomyślnie.
            <span
              className="p-0 link-dark text-decoration-underline"
              onClick={() => handleRevertClick(id)}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    if (equipmentList.length > 0) return;

    fetchData();
  }, [queryParams, equipmentList]);

  const handleShowModal = _id => {
    setShowModal(true);
    const item = equipmentList.find(item => item._id === _id);

    setSelectedOptions({
      value: item._id,
      label: `${item.equipmentId} | ${item.name}`,
      equipmentId: item.equipmentId,
      currentAssignment: item.currentAssignment || 'Brak przypisania'
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setHasCustomTransferDate(false);
  };

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const handleDestinationConstructionChange = selected => {
    setDestinationConstruction(selected);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const {
      value: equipmentId,
      currentAssignment: { construction, brigade }
    } = selectedOptions;

    const transferData = {
      equipmentId,
      originConstructionId: construction._id,
      originBrigadeId: brigade?._id,
      destinationConstructionId: destinationConstruction.value,
      customTransferDate: hasCustomTransferDate ? customTransferDate : null,
      status: 'admin_approved'
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/construction/transfers/add`,
        transferData
      );
      handleCloseModal();
      setSelectedOptions([]);

      toast.success('Dodano transfer urządzenia', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    const fetchConstructions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/construction`
        );
        setConstructions(response.data.construction);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania zadania');
      }
    };

    fetchConstructions();
  }, []);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns.map(column => ({
          ...column,
          handleDeleteClick,
          handleMakeMalfunction,
          handleShowModal,
          user,
          loadingRows
        }))}
        data={equipmentList !== false ? equipmentList : []}
        selection
        sortable
        pagination
        perPage={25}
        setClearFunction={setClearSelect}
      >
        <Card className="mb-3">
          <Card.Header>
            <EquipmentTableHeader
              table
              setEquipmentList={setEquipmentList}
              equipmentList={equipmentList}
              setQueryParams={setQueryParams}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={equipmentList.length}
              table
              rowsPerPageSelection
            />
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Form onSubmit={e => debounce(handleSubmit(e))}>
          <Modal.Header closeButton>
            <Modal.Title>Nowy transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="equipment" className="mb-3">
              <Form.Label>Wybierz urządzenia</Form.Label>
              <MultiSelect
                closeMenuOnSelect={true}
                isMulti={false}
                clearable
                options={equipmentList
                  .filter(item => item.currentAssignment !== null)
                  .map(item => ({
                    value: item._id,
                    label: `${item.equipmentId} | ${item.name}`,
                    equipmentId: item.equipmentId,
                    currentAssignment: item.currentAssignment
                  }))}
                placeholder="Wyszukaj..."
                value={selectedOptions}
                onChange={handleSelectChange}
              />
            </Form.Group>
            {/* TODO: Develop handling of multiple transfers */}
            {selectedOptions.length !== 0 && (
              <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
                <p className="mb-2 fs--1">
                  Przenosisz <strong>{selectedOptions.label}</strong>
                  <br />
                  Aktualna lokalizacja:{' '}
                  <strong>
                    {selectedOptions.currentAssignment?.construction?.name}
                  </strong>{' '}
                  <br />
                  Aktualna brygada:{' '}
                  <strong>
                    {selectedOptions.currentAssignment?.brigade?.name ||
                      'Brak przypisanej brygady'}
                  </strong>
                </p>
                <Form.Group className="mb-3" controlId="categoryName">
                  <Form.Label>Budowa docelowa</Form.Label>
                  <MultiSelect
                    closeMenuOnSelect={true}
                    isMulti={false}
                    clearable
                    options={constructions.map(construction => ({
                      value: construction._id,
                      label: construction.name
                    }))}
                    placeholder="Wyszukaj..."
                    value={destinationConstruction}
                    onChange={handleDestinationConstructionChange}
                  />
                </Form.Group>
                <Button
                  size="sm"
                  variant="link"
                  className={classNames('p-0', {
                    'd-none': hasCustomTransferDate
                  })}
                  onClick={() =>
                    setHasCustomTransferDate(!hasCustomTransferDate)
                  }
                >
                  Ustaw datę transferu
                </Button>
                {hasCustomTransferDate && (
                  <Form.Group
                    controlId="customTransferDate"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Form.Label>Data transferu</Form.Label>
                    <DatePicker
                      selected={customTransferDate}
                      locale={pl}
                      dateFormat="dd/MM/yyyy"
                      onChange={newDate => {
                        setCustomTransferDate(newDate);
                      }}
                      customInput={
                        <CustomDateInput
                          formControlProps={{
                            placeholder: 'd/m/y'
                          }}
                        />
                      }
                    />
                  </Form.Group>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Anuluj
            </Button>
            <Button variant="primary" type="submit">
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

EquipmentList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(EquipmentList);
