import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useState, useRef, useEffect } from 'react';
import { handleMultiple } from '../../../../helpers/bulkAction';
import { Link } from 'react-router-dom';
import { UserFilters } from './UserFilters';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import { USER_ROLES } from '../../../../constants/users';

const UserTableHeader = ({
  selectedRowIds,
  users,
  setUsers,
  setQueryParams
}) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);

  const csvLink = useRef();

  useEffect(() => {
    setItems(
      Object.keys(selectedRowIds).map(id => {
        const user = users.find(item => item._id === id);
        if (user) {
          return {
            ...user,
            ...{
              name: `${user.firstName} ${user.lastName}`,
              role: USER_ROLES[user.role].label
            }
          };
        }
        return;
      })
    );
  }, [selectedRowIds]);

  const headers = [
    { label: 'Imię', key: 'name' },
    { label: 'Uprawnienia', key: 'role' },
    { label: 'Email', key: 'email' },
    { label: 'Telefon', key: 'phone' },
    { label: 'Adres', key: 'address' }
  ];

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'archive':
        handleMultiple(selectedRowIds, 'users/archive', users, setUsers, {
          status: 'archived'
        });
        break;
      case 'export':
        if (items.length < 0) return;
        csvLink?.current?.link?.click();
        break;
    }
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
          Lista użytkowników
        </h5>
      </Col>
      <Col
        xs={8}
        sm="auto"
        className="ms-auto text-end ps-0 d-flex justify-content-end"
      >
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-3"
          className="mx-2"
          onClick={() => setOpen(!open)}
        >
          <span>Filtruj</span>
        </IconButton>
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Działania masowe"
              value={selectedAction}
              onChange={e => setSelectedAction(e.target.value)}
            >
              <option>Działania masowe</option>
              <option value="archive">Archiwizuj</option>
              <option value="export">Eksportuj</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleApplyAction}
            >
              Zastosuj
            </Button>
            {items.length >= 0 && (
              <CSVLink
                data={items}
                headers={headers}
                filename={`uzytkownicy_${dayjs().format(
                  'DD-MM-YYYY_HH-mm'
                )}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            )}
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              className="ms-2"
              as={Link}
              to={'/create-user'}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Dodaj użytkownika
              </span>
            </IconButton>
          </div>
        )}
      </Col>
      <Col xs={12} className="mt-2">
        <UserFilters
          open={open}
          setQueryParams={setQueryParams}
          setItems={setUsers}
        />
      </Col>
    </Row>
  );
};

UserTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  users: PropTypes.array,
  setUsers: PropTypes.func,
  setQueryParams: PropTypes.func
};

export default UserTableHeader;
