import React, { useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import PropTypes from 'prop-types';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import useTranslation from 'hooks/useTranslation';
import { toast } from 'react-toastify';
import axios from 'axios';

const ChecklistOtherInfo = ({ control }) => {
  const [categoriesList, setCategoriesList] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmitCategory = async () => {
    if (!categoryName)
      return toast.error('Nazwa kategorii jest wymagana', { theme: 'colored' });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tasks/categories/add`,
        {
          name: categoryName
        }
      );
      setCategoryName('');
      handleCloseModal();
      handleCategoriesAdded(response.data.category);
      toast.success('Dodano kategorię urządzenia', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleCategoriesAdded = addedCategories => {
    setCategoriesList([
      { value: addedCategories._id, label: addedCategories.name },
      ...categoriesList
    ]);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks/categories`
        );
        const categories = response.data.categories.map(category => ({
          value: category._id,
          label: category.name
        }));
        setCategoriesList(categories);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania listy kategorii');
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">Pozostałe informacje</Card.Header>
        <Card.Body className="bg-light">
          <Form.Group className="mb-3">
            <Flex alignItems="center" justifyContent="between">
              <Form.Label>Kategorie</Form.Label>
              <Button
                size="sm"
                variant="link"
                className="p-0"
                onClick={handleShowModal}
              >
                Dodaj nową
              </Button>
            </Flex>
            <Controller
              name="categories"
              render={({ field, ref }) => (
                <MultiSelect
                  {...field}
                  ref={ref}
                  closeMenuOnSelect={false}
                  isMulti
                  options={categoriesList}
                  placeholder="Wybierz kategorię..."
                />
              )}
              control={control}
            />
          </Form.Group>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj kategorię</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoryName">
              <Form.Label>Nazwa kategorii</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę kategorii"
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleSubmitCategory}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
ChecklistOtherInfo.propTypes = {
  control: PropTypes.object.isRequired
};

export default ChecklistOtherInfo;
