import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import axios from 'axios';

import CardDropdown from 'components/common/CardDropdown';
import ManufacturerTableHeader from './ManufacturerTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';

import useTranslation from 'hooks/useTranslation';

const columns = [
  {
    accessor: 'name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name } = rowData.row.original;
      return (
        <p className="mb-0">
          <strong>{name}</strong>
        </p>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id, name } = rowData.row.original;
      const { handleEditClick, handleDeleteClick, loadingRows } =
        rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item
              as="button"
              onClick={() => handleEditClick(_id, name)}
            >
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const EquipmentManufacturerList = ({ loadingRows, setRowLoading }) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [manufacturerModal, setManufacturerModal] = useState({
    isOpen: false,
    id: '',
    name: ''
  });

  const handleCloseModal = () =>
    setManufacturerModal({ isOpen: false, id: '', name: '' });

  const handleEditClick = (id, name) => {
    setManufacturerModal({ isOpen: true, id, name });
  };

  const handleEditSubmit = async () => {
    if (!manufacturerModal.name) {
      toast.error('Nazwa producenta nie może być pusta');
      return;
    }

    try {
      setRowLoading(manufacturerModal.id, true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/equipment/manufacturers/${manufacturerModal.id}`,
        { name: manufacturerModal.name }
      );
      toast.success(`Zmieniono nazwę ${response.data.manufacturer.name}`);
      setManufacturers(
        manufacturers.map(manufacturer =>
          manufacturer._id === manufacturerModal.id
            ? { ...manufacturer, name: manufacturerModal.name }
            : manufacturer
        )
      );
      setManufacturerModal({ isOpen: false, id: '', name: '' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(manufacturerModal.id, false);
    }
  };

  const handleChangeManufacturerName = e => {
    setManufacturerModal({ ...manufacturerModal, name: e.target.value });
  };

  const handleDeleteClick = async id => {
    const confirmed = window.confirm(
      'Jesteś pewny, że chcesz usunąć tego producenta?'
    );

    if (confirmed) {
      try {
        setRowLoading(id, true);
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/equipment/manufacturers/${id}`
        );
        toast.success(`Usunięto ${response.data.manufacturer.name}`);
        setManufacturers(
          manufacturers.filter(manufacturer => manufacturer._id !== id)
        );
      } catch (error) {
        console.error(error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  const handleManufacturerAdded = addedManufacturer => {
    setManufacturers([addedManufacturer.data, ...manufacturers]);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/manufacturers/`
      );
      setManufacturers(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns.map(column => ({
          ...column,
          handleEditClick,
          handleDeleteClick,
          loadingRows
        }))}
        data={manufacturers}
        selection
        sortable
        pagination
      >
        <Card className="mb-3">
          <Card.Header>
            <ManufacturerTableHeader
              table
              onManufacturerAdded={handleManufacturerAdded}
              manufacturers={manufacturers}
              setManufacturers={setManufacturers}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={manufacturers.length}
              table
              rowsPerPageSelection
            />
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      <Modal show={manufacturerModal.isOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edytuj producenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="manufacturerName">
              <Form.Label>Nazwa producenta</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę producenta"
                value={manufacturerModal.name}
                onChange={handleChangeManufacturerName}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Zmień nazwę
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EquipmentManufacturerList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(EquipmentManufacturerList);
