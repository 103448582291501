import React from 'react';
import PropTypes from 'prop-types';
import { userPropTypes } from 'shapes/userPropTypes';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useParams } from 'react-router-dom';
import { emailRegex } from 'helpers/validation';
import debounce from 'lodash/debounce';

const AccountDetails = ({
  register,
  errors,
  isLoading,
  onSubmit,
  handleEmailBlur
}) => {
  const { userId } = useParams();

  const debHandleEmailBlur = debounce(handleEmailBlur, 500);

  return (
    <Card>
      <FalconCardHeader
        title={userId ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}
      />
      <Card.Body className="bg-light">
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={6}
            controlId="email"
            className="position-relative"
          >
            <Form.Label>Email w domenie romgos.pl</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              {...register('email', {
                pattern: {
                  value: emailRegex,
                  message: 'Email musi zawierać domenę romgos.pl'
                },
                required: true
              })}
              readOnly={userId}
              onBlur={debHandleEmailBlur}
              className={errors.email ? 'is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && errors.email.message}
            </Form.Control.Feedback>
            {isLoading && (
              <Spinner
                animation="border"
                size="sm"
                className="position-absolute end-0 top-50 me-3 mt-1 opacity-50"
              />
            )}
          </Form.Group>
          <Form.Group as={Col} lg={6} controlId="phone">
            <Form.Label>Telefon</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telefon"
              name="phone"
              {...register('phone', {
                required: 'Numer telefonu jest wymagany'
              })}
              className={errors.phone ? 'is-invalid' : ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={6}
            controlId="firstName"
            className="position-relative"
          >
            <Form.Label>Imię</Form.Label>
            <Form.Control
              type="text"
              placeholder="Imię"
              name="firstName"
              {...register('firstName', { required: 'Imię jest wymagane' })}
              className={errors.firstName ? 'is-invalid' : ''}
              readOnly
            />
            {isLoading && (
              <Spinner
                animation="border"
                size="sm"
                className="position-absolute end-0 top-50 me-3 mt-1 opacity-50"
              />
            )}
          </Form.Group>

          <Form.Group
            as={Col}
            lg={6}
            controlId="lastName"
            className="position-relative"
          >
            <Form.Label>Nazwisko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nazwisko"
              name="lastName"
              {...register('lastName', { required: 'Nazwisko jest wymagane' })}
              className={errors.lastName ? 'is-invalid' : ''}
              readOnly
            />
            {isLoading && (
              <Spinner
                animation="border"
                size="sm"
                className="position-absolute end-0 top-50 me-3 mt-1 opacity-50"
              />
            )}
          </Form.Group>
        </Row>

        <Form.Group className="mb-3" controlId="address">
          <Form.Label>Adres</Form.Label>
          <Form.Control
            type="text"
            placeholder="Adres"
            name="address"
            {...register('address')}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="position">
          <Form.Label>Stanowisko</Form.Label>
          <Form.Control
            type="text"
            placeholder="Stanowisko"
            name="position"
            {...register('position')}
          />
        </Form.Group>
        <div className="text-end">
          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={
              isLoading ||
              errors.email ||
              errors.phone ||
              errors.firstName ||
              errors.lastName
            }
          >
            Zapisz
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

AccountDetails.propTypes = {
  user: userPropTypes,
  isLoading: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleEmailBlur: PropTypes.func.isRequired
};

export default AccountDetails;
