import React from 'react';
import { taskPropTypes } from 'shapes/taskPropTypes';
import { Row, Card, Placeholder, Table } from 'react-bootstrap';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';

const BrigadeDetailContent = ({ brigade }) => {
  const { description, name, users } = brigade;

  if (!brigade) {
    return (
      <Card>
        <Card.Body>
          <h5 className="fs-0 mb-3">Opis zadania</h5>
          <Placeholder as={Row} animation="glow" />
          <h5 className="fs-0 mt-5 mb-2 ">Kategorie</h5>
          <p>Brak kategorii</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <h5 className="fs-0 mb-3">{name}</h5>
          {description}
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="p-0">
          <Table
            borderless
            className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info"
          >
            <thead className="bg-light">
              <tr className="text-900">
                <th>Status użytkowników</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  className={classNames({
                    'border-bottom border-200': index !== users.length - 1
                  })}
                  key={index}
                >
                  <td className="align-middle">
                    <Flex alignItems="center" className="position-relative">
                      {user.avatar.img ? (
                        <Avatar
                          className={`status-${status}`}
                          size="xl"
                          src={user.avatar.img}
                          width="60"
                          alt={user.firstName + ' ' + user.lastName}
                        />
                      ) : (
                        <Avatar
                          size="xl"
                          name={user.avatar.name}
                          className={`status-${status}`}
                        />
                      )}
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 fw-semi-bold">
                          <Link
                            className="text-dark stretched-link"
                            to={`/user/${user._id}`}
                          >
                            {user.firstName} {user.lastName}
                          </Link>
                        </h6>
                        <p className="fs--2 mb-0 text-500">{user.role}</p>
                      </div>
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

BrigadeDetailContent.propTypes = {
  brigade: taskPropTypes.isRequired
};

export default BrigadeDetailContent;
