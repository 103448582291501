import { Collapse, Form, Col, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const EquipmentFilters = ({ open, setQueryParams, setItems }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      manufacturer: []
    }
  });
  const [manufacturersList, setManufacturersList] = useState([]);

  const fetchData = async () => {
    try {
      const manufacturersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/manufacturers`
      );
      const manufacturers = manufacturersResponse.data.data.map(
        manufacturer => ({
          value: manufacturer._id,
          label: manufacturer.name
        })
      );
      setManufacturersList(manufacturers);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const onSubmit = data => {
    let queryParts = [];

    if (data.name) {
      queryParts.push(`q=${encodeURIComponent(data.name)}`);
    }

    if (data.manufacturer?.length > 0) {
      data.manufacturer.map(manufacturer => {
        queryParts.push(`manufacturer[in]=${manufacturer.value}`);
      });
    }

    if (data.isBroken?.length > 0) {
      data.isBroken.map(isBroken => {
        queryParts.push(`malfunction[in]=${isBroken.value}`);
      });
    }

    setQueryParams(queryParts?.join('&') || '');
    setItems([]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Collapse in={open}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="name" className="mb-3 d-flex flex-column">
              <Form.Label>Szukaj</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nazwa urządzenia lub numer id..."
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group className="mb-3 d-flex flex-column">
              <Form.Label>Producent</Form.Label>
              <Controller
                name="manufacturer"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    isMulti
                    clearable
                    closeMenuOnSelect={true}
                    options={manufacturersList}
                    placeholder="Wybierz producenta..."
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group className="mb-3 d-flex flex-column">
              <Form.Label>Awaria?</Form.Label>
              <Controller
                name="isBroken"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    isMulti
                    clearable
                    closeMenuOnSelect={true}
                    options={[
                      { value: true, label: 'Tak' },
                      { value: false, label: 'Nie' }
                    ]}
                    placeholder="Wybierz..."
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Flex
              justifyContent="end"
              alignItems="end"
              style={{
                height: '100%'
              }}
            >
              <Button
                size="sm"
                variant="falcon-primary"
                type="submit"
                transform="shrink-3"
              >
                <span>Zastosuj filtry</span>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

EquipmentFilters.propTypes = {
  open: PropTypes.bool,
  setQueryParams: PropTypes.func,
  setItems: PropTypes.func
};
