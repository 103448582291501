import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Form } from 'react-bootstrap';

const AccountSettings = ({ register, errors, watch }) => {
  const selectedRole = watch('role');
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Ustawienia profilu" />
      <Card.Body className="bg-light">
        <div>
          <h6 className="fw-bold">Uprawnienia konta</h6>
          <div className="ps-2 mb-2">
            <Form.Check
              type="radio"
              id="profile-service"
              label="Serwisant"
              className="form-label-nogutter"
              value="SERVICE"
              name="role"
              isInvalid={!!errors.role}
              {...register('role')}
              disabled
            />
            <Form.Check
              type="radio"
              id="profile-service"
              label="Brygadzista"
              className="form-label-nogutter"
              value="BRIGADE-MANAGER"
              name="role"
              isInvalid={!!errors.role}
              {...register('role')}
              disabled
            />
            <Form.Check
              type="radio"
              id="profile-manager"
              label="Kierownik serwisu"
              className="form-label-nogutter"
              value="SERVICE-MANAGER"
              name="role"
              isInvalid={!!errors.role}
              {...register('role')}
              disabled
            />
            <Form.Check
              type="radio"
              id="profile-construction-manager"
              label="Kierownik budowy"
              className="form-label-nogutter"
              value="CONSTRUCTION-MANAGER"
              name="role"
              isInvalid={!!errors.role}
              {...register('role')}
              disabled
            />
            <Form.Check
              type="radio"
              id="profile-construction-admin"
              label="Administrator Utrzymiania Ruchu"
              className="form-label-nogutter"
              value="MAINTENANCE-ADMIN"
              name="role"
              isInvalid={!!errors.role}
              {...register('role')}
              disabled
            />
            <Form.Check
              type="radio"
              id="profile-admin"
              label="Administrator"
              className="form-label-nogutter"
              value="ADMIN"
              name="role"
              isInvalid={!!errors.role}
              {...register('role')}
              disabled
            />
          </div>
        </div>

        <div className="border-dashed border-bottom my-3" />

        <h6 className="fw-bold">Ograniczenia uprawnień</h6>
        <div className="ps-2">
          <Form.Check
            type="checkbox"
            id="show-followers"
            label="Okres próbny"
            className="form-label-nogutter"
            name="inTraining"
            {...register('inTraining')}
          />
        </div>

        <div className="border-dashed border-bottom my-3" />
        {selectedRole === 'SERVICE' && (
          <div className="ps-2">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Zezwalaj na logowanie do systemu"
              className="form-label-nogutter"
              name="allowPanelLogin"
              {...register('allowPanelLogin')}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

AccountSettings.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired
};

export default AccountSettings;
