import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import axios from 'axios';

import CardDropdown from 'components/common/CardDropdown';
import CategoryTableHeader from './CategoryTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';

const columns = [
  {
    accessor: 'name',
    Header: 'Nazwa kategorii',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name } = rowData.row.original;
      return (
        <p className="mb-0">
          <strong>{name}</strong>
        </p>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id, name } = rowData.row.original;
      const { handleDeleteClick, handleEditClick, loadingRows } =
        rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item
              as="button"
              onClick={() => handleEditClick(_id, name)}
            >
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const EquipmentCategoryList = ({ loadingRows, setRowLoading }) => {
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoryModal, setCategoryModal] = useState({
    isOpen: false,
    id: '',
    name: ''
  });

  const handleCloseModal = () =>
    setCategoryModal({ isOpen: false, id: '', name: '' });

  const handleDeleteClick = async id => {
    const confirmed = window.confirm(
      'Jesteś pewny, że chcesz usunąć tę kategorię?'
    );

    if (confirmed) {
      try {
        setRowLoading(id, true);
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/equipment/categories/${id}`
        );
        toast.success(`Usunięto ${response.data.category.name}`);
        setCategories(categories.filter(category => category._id !== id));
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas usuwania');
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  const handleCategoriesAdded = addedCategories => {
    setCategories([addedCategories.category, ...categories]);
    setShouldFetchData(true);
  };

  const handleEditClick = (id, name) => {
    setCategoryModal({ isOpen: true, id, name });
  };

  const handleEditSubmit = async e => {
    e.preventDefault();

    if (!categoryModal.name) {
      toast.error('Nazwa kategorii nie może być pusta');
      return;
    }

    try {
      setRowLoading(categoryModal.id, true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/equipment/categories/${categoryModal.id}`,
        { name: categoryModal.name }
      );
      toast.success(`Zmieniono nazwę ${response.data.category.name}`);
      setCategories(
        categories.map(category =>
          category._id === categoryModal.id
            ? { ...category, name: categoryModal.name }
            : category
        )
      );
      setCategoryModal({ isOpen: false, id: '', name: '' });
    } catch (error) {
      console.error(error);
      toast.error('Błąd podczas zmiany nazwy kategorii');
    } finally {
      setRowLoading(categoryModal.id, false);
    }
  };

  const handleChangeCategoryName = e => {
    setCategoryModal({ ...categoryModal, name: e.target.value });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/categories/`
      );
      if (response.data.categories.length === 0) {
        setShouldFetchData(false);
      } else {
        setCategories(response.data.categories);
        setShouldFetchData(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  useEffect(() => {
    if (shouldFetchData && categories.length === 0) {
      fetchData();
    }
  }, [categories]);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns.map(column => ({
          ...column,
          handleDeleteClick,
          handleEditClick,
          loadingRows
        }))}
        data={categories}
        selection
        sortable
        pagination
      >
        <Card className="mb-3">
          <Card.Header>
            <CategoryTableHeader
              table
              onCategoryAdded={handleCategoriesAdded}
              categories={categories}
              setCategories={setCategories}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={categories.length}
              table
              rowsPerPageSelection
            />
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>

      <Modal show={categoryModal.isOpen} onHide={handleCloseModal}>
        <Form onSubmit={handleEditSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edytuj kategorię</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="categoryName">
              <Form.Label>Nazwa kategorii</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę kategorii"
                value={categoryModal.name}
                onChange={handleChangeCategoryName}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Anuluj
            </Button>
            <Button variant="primary" type="submit">
              Zmień nazwę
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

EquipmentCategoryList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(EquipmentCategoryList);
