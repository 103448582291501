/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  const renderPageNumbers = () => {
    const pages = [];
    const totalDisplayedPages = 5;
    const halfDisplayedPages = Math.floor(totalDisplayedPages / 2);

    let startPage = Math.max(0, pageIndex - halfDisplayedPages);
    let endPage = Math.min(pageCount - 1, pageIndex + halfDisplayedPages);

    if (pageIndex <= halfDisplayedPages) {
      endPage = Math.min(pageCount - 1, totalDisplayedPages - 1);
    } else if (pageIndex + halfDisplayedPages >= pageCount) {
      startPage = Math.max(0, pageCount - totalDisplayedPages);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={classNames({ active: pageIndex === i })}>
          <Button
            size="sm"
            variant="falcon-default"
            className="page me-2"
            style={{ minWidth: '50px' }}
            onClick={() => gotoPage(i)}
          >
            {i + 1}
          </Button>
        </li>
      );
    }

    return pages;
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-2">{renderPageNumbers()}</ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
