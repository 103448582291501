import GoogleMap from 'components/map/GoogleMap';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { mapPropTypes } from 'shapes/mapPropTypes';

const MapDark = ({ center: [lng, lat] }) => {
  return (
    <GoogleMap
      initialCenter={{
        lat: lat,
        lng: lng
      }}
      zoom={12}
      mapStyle="Cobalt"
      className="vh-50 rounded-soft mt-5"
    />
  );
};
const MapLight = ({ center: [lng, lat] }) => {
  return (
    <GoogleMap
      initialCenter={{
        lat: lat,
        lng: lng
      }}
      zoom={12}
      mapStyle="Default"
      className="vh-50 rounded-soft mt-5"
    />
  );
};

const ConstructionDetailGmap = ({ center }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <>{isDark ? <MapDark center={center} /> : <MapLight center={center} />}</>
  );
};

ConstructionDetailGmap.propTypes = mapPropTypes;
MapLight.propTypes = mapPropTypes;
MapDark.propTypes = mapPropTypes;

export default ConstructionDetailGmap;
