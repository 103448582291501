import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row } from 'react-bootstrap';

const BrigadeBar = ({ title, className, setStatus, isLoading }) => {
  const { id } = useParams();

  const handleSaveDraft = () => {
    setStatus('draft');
  };

  return (
    <Card className={className}>
      <Card.Body>
        <Row className="flex-between-center">
          {title && (
            <Col md>
              <h5 className="mb-2 mb-md-0">{title}</h5>
            </Col>
          )}
          <Col xs="auto">
            {(!id || status === 'draft') && (
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                type="submit"
                onClick={handleSaveDraft}
                disabled={isLoading}
              >
                Zapisz szkic
              </Button>
            )}
            <Button size="sm" variant="falcon-primary" type="submit">
              {id ? 'Zapisz' : 'Opublikuj'}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

BrigadeBar.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default BrigadeBar;
