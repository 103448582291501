import { Collapse, Form, Col, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const ConstructionTransferFilters = ({
  open,
  setQueryParams,
  setItems
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      status: [],
      priority: []
    }
  });
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [constructionOptions, setConstructionOptions] = useState([]);

  const fetchData = async () => {
    try {
      // Fetch equipment options
      const equipmentResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/`
      );
      const equipmentOptionsData = equipmentResponse.data.equipment.map(
        ({ _id, equipmentId, name }) => ({
          value: _id,
          label: `${equipmentId} | ${name}`
        })
      );
      setEquipmentOptions(equipmentOptionsData);

      // Fetch construction options
      const constructionResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/construction/`
      );
      const constructionOptionsData =
        constructionResponse.data.construction.map(({ _id, name }) => ({
          value: _id,
          label: name
        }));
      setConstructionOptions(constructionOptionsData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const onSubmit = data => {
    let queryParts = [];

    if (data.equipment?.length > 0) {
      const equipmentIds = data.equipment.map(item => item.value);
      equipmentIds.map(id => queryParts.push(`equipment[in]=${id}`));
    }

    if (data.originConstruction?.length > 0) {
      const originConstructionIds = data.originConstruction.map(
        item => item.value
      );
      originConstructionIds.map(id =>
        queryParts.push(`originConstruction[in]=${id}`)
      );
    }

    if (data.destinationConstruction?.length > 0) {
      const destinationConstructionIds = data.destinationConstruction.map(
        item => item.value
      );
      destinationConstructionIds.map(id =>
        queryParts.push(`destinationConstruction[in]=${id}`)
      );
    }

    setQueryParams(queryParts?.join('&') || '');
    setItems([]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formValues = watch();

  return (
    <Collapse in={open}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              controlId="equipment"
              className="mb-3 d-flex flex-column"
            >
              <Flex className="flex-between-center">
                <Form.Label>Urządzenia</Form.Label>
              </Flex>
              <Controller
                name="equipment"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti
                    isOptionDisabled={option =>
                      option.isDisabled || formValues.equipment?.length >= 5
                    }
                    options={equipmentOptions}
                    placeholder="Wybierz urządzenia..."
                    isInvalid={!!errors.originConstruction}
                    clearable
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              controlId="equipment"
              className="mb-3 d-flex flex-column"
            >
              <Flex className="flex-between-center">
                <Form.Label>Budowa źródłowa</Form.Label>
              </Flex>
              <Controller
                name="originConstruction"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti
                    isOptionDisabled={option =>
                      option.isDisabled ||
                      formValues.originConstruction?.length >= 5
                    }
                    options={constructionOptions}
                    placeholder="Wybierz budowe źródłową..."
                    isInvalid={!!errors.originConstruction}
                    clearable
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              controlId="destinationConstruction"
              className="mb-3 d-flex flex-column"
            >
              <Flex className="flex-between-center">
                <Form.Label>Budowa docelowa</Form.Label>
              </Flex>
              <Controller
                name="destinationConstruction"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti
                    isOptionDisabled={option =>
                      option.isDisabled ||
                      formValues.destinationConstruction?.length >= 5
                    }
                    options={constructionOptions}
                    placeholder="Wybierz budowe docelową..."
                    isInvalid={!!errors.destinationConstruction}
                    clearable
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Flex
              justifyContent="end"
              alignItems="end"
              style={{
                height: '100%'
              }}
            >
              <Button
                size="sm"
                variant="falcon-primary"
                type="submit"
                transform="shrink-3"
              >
                <span>Zastosuj filtry</span>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

ConstructionTransferFilters.propTypes = {
  open: PropTypes.bool,
  setQueryParams: PropTypes.func,
  setItems: PropTypes.func
};
