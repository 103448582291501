import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import dayjs from 'dayjs';
import pl from 'date-fns/locale/pl';

import AuthContext from 'context/auth/AuthContext';

import IconItem from 'components/common/icon/IconItem';
import MultiSelect from 'components/common/MultiSelect';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CustomDateInput from 'components/common/CustomDateInput';

import { formatCurrency } from 'helpers/utils';

import useFormatDate from 'hooks/useFormatDate';
import useTranslation from 'hooks/useTranslation';
import { ADMIN_ROLES } from 'constants/users';

const assignedEquipmentCsvHeaders = [
  { label: 'Numer ID', key: 'equipment.equipmentId' },
  { label: 'Nazwa urządzenia', key: 'equipment.name' },
  { label: 'Brygada', key: 'brigade.name' },
  { label: 'Data przypisania', key: 'dateFrom' },
  { label: 'Koszt', key: 'cost' }
];

const columns = [
  {
    accessor: 'equipment.equipmentId',
    Header: 'Urządzenia',
    Cell: rowData => {
      const { _id, name, equipmentId } = rowData.row.original.equipment;
      return (
        <>
          <Link to={`/equipment/${_id}`}>
            <strong>{equipmentId}</strong>
          </Link>
          <br />
          {name}
        </>
      );
    }
  },
  {
    accessor: 'brigade.name',
    Header: 'Brygada'
  },
  {
    accessor: 'dateFrom',
    Header: 'Data przypisania',
    Cell: rowData => {
      const { dateFrom } = rowData.row.original;
      const [formattedDate] = useFormatDate(dateFrom, false);
      return <span>{formattedDate} </span>;
    }
  },
  {
    accessor: 'cost',
    Header: 'Koszt',
    Cell: rowData => {
      const { cost } = rowData.row.original;
      return (
        <span>
          {Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 0
          }).format(cost)}
        </span>
      );
    }
  },
  {
    Header: 'Działania',
    disableSortBy: true,
    headerProps: { className: 'pe-4 text-end' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id } = rowData.row.original;
      const {
        handleShowTransferModal,
        handleShowExcludedDatesModal,
        handleShowDetailsModal
      } = rowData.column;

      return (
        <div className="d-flex align-items-center justify-content-end">
          <IconItem
            tag="button"
            icon="arrow-right-arrow-left"
            size="sm"
            className="btn rounded-3 me-2 fs--1 text-primary"
            onClick={() => handleShowTransferModal(rowData.row.original)}
          />
          <IconItem
            tag="button"
            icon="calendar-times"
            size="sm"
            className="btn rounded-3 me-2 fs--1 text-primary"
            onClick={() => handleShowExcludedDatesModal(_id)}
          />
          <IconItem
            tag="button"
            icon="info"
            size="sm"
            className="btn rounded-3 me-2 fs--1 "
            onClick={() => handleShowDetailsModal(rowData.row.original)}
          />
        </div>
      );
    }
  }
];

const ConstructionDetailEquipment = ({
  constructionId,
  equipment,
  setConstruction
}) => {
  const ITEMS_PER_PAGE = 10;

  const csvLink = useRef();
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showExcludedDatesModal, setShowExcludedDatesModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [transferEquipment, setTransferEquipment] = useState(null);
  const [destinationConstruction, setDestinationConstruction] = useState(null);
  const [constructions, setConstructions] = useState([]);
  const [excludedDate, setExcludedDate] = useState(null);
  const [excludedReason, setExcludedReason] = useState('');

  const { user } = useContext(AuthContext);

  equipment = equipment.filter(equipment => equipment.dateTo === null);

  const handleShowTransferModal = equipment => {
    setShowTransferModal(true);
    setTransferEquipment(equipment);
  };
  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
    setDestinationConstruction(null);
  };
  const handleShowExcludedDatesModal = assignmentId => {
    setShowExcludedDatesModal(assignmentId);
  };
  const handleCloseModal = () => {
    setShowExcludedDatesModal(false);
    setShowDetailsModal(false);
  };

  const handleShowDetailsModal = assignment => {
    setShowDetailsModal(assignment);
  };

  const handleExcludedDatesSubmit = async e => {
    e.preventDefault();

    const excludedDatesData = {
      date: excludedDate,
      reason: excludedReason
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/equipment-assignments/${showExcludedDatesModal}/excluded-dates`,
        excludedDatesData
      );
      handleCloseModal();
      setExcludedDate(null);
      setExcludedReason('');

      setConstruction(prevState => {
        const updatedEquipment = prevState.assignedEquipment.map(assignment => {
          if (assignment._id === showExcludedDatesModal) {
            return {
              ...assignment,
              datesExcluded: [
                ...assignment.datesExcluded,
                {
                  date: excludedDate,
                  reason: excludedReason
                }
              ]
            };
          }
          return assignment;
        });

        return {
          ...prevState,
          assignedEquipment: updatedEquipment
        };
      });

      toast.success('Dodano datę wykluczenia z kosztów', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleDeleteExcludedDate = async (assignmentId, excludedDateId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/equipment-assignments/${assignmentId}/excluded-dates/${excludedDateId}`
      );
      handleCloseModal();

      setConstruction(prevState => {
        const updatedEquipment = prevState.assignedEquipment.map(assignment => {
          if (assignment._id === assignmentId) {
            return {
              ...assignment,
              datesExcluded: assignment.datesExcluded.filter(
                date => date._id !== excludedDateId
              )
            };
          }
          return assignment;
        });

        toast.success('Usunięto datę wykluczenia z kosztów', {
          theme: 'colored'
        });

        return {
          ...prevState,
          assignedEquipment: updatedEquipment
        };
      });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleDestinationConstructionChange = selected => {
    setDestinationConstruction(selected);
  };

  const handleTransferSubmit = async e => {
    e.preventDefault();

    const transferData = {
      equipmentId: transferEquipment?.equipment?._id,
      originConstructionId: constructionId,
      originBrigadeId: transferEquipment?.brigade?._id,
      destinationConstructionId: destinationConstruction.value
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/construction/transfers/add`,
        transferData
      );
      handleCloseTransferModal();
      setTransferEquipment(null);
      setDestinationConstruction(null);

      toast.success('Dodano transfer urządzenia', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    const fetchConstructions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/construction`
        );
        setConstructions(response.data.construction);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania budowy');
      }
    };

    fetchConstructions();
  }, []);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns.map(column => ({
          ...column,
          handleShowTransferModal,
          handleShowExcludedDatesModal,
          handleShowDetailsModal
        }))}
        data={equipment}
        sortable
        pagination
        perPage={ITEMS_PER_PAGE}
      >
        <Card className="mb-3">
          <FalconCardHeader
            title="Przypisane urządzenia"
            titleTag="h6"
            endEl={
              <Button
                variant="falcon-secondary"
                size="sm"
                className="px-2"
                onClick={() => csvLink?.current?.link?.click()}
              >
                Eksportuj
              </Button>
            }
          />
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-light text-800 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                borderless: true,
                className: 'fs--2 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer className="bg-light py-2">
            {equipment.length > ITEMS_PER_PAGE && (
              <>
                <AdvanceTableFooter
                  rowCount={equipment.length}
                  table
                  rowsPerPageSelection
                />
                <AdvanceTablePagination table />
              </>
            )}
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      <Modal show={showTransferModal} onHide={handleCloseTransferModal}>
        <Form onSubmit={handleTransferSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Nowy transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
              <p className="mb-2 fs--1">
                Przenosisz{' '}
                <strong>
                  {transferEquipment?.equipment?.equipmentId} |{' '}
                  {transferEquipment?.equipment?.name}
                </strong>
                <br />
                Aktualna brygada:{' '}
                <strong>
                  {transferEquipment?.brigade?.name ||
                    'Brak przypisanej brygady'}
                </strong>
              </p>
              <Form.Group controlId="categoryName">
                <Form.Label>Budowa docelowa</Form.Label>
                <MultiSelect
                  closeMenuOnSelect={true}
                  isMulti={false}
                  clearable
                  options={constructions.map(construction => ({
                    value: construction._id,
                    label: construction.name
                  }))}
                  placeholder="Wyszukaj..."
                  value={destinationConstruction}
                  onChange={handleDestinationConstructionChange}
                  required
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseTransferModal}>
              Anuluj
            </Button>
            <Button variant="primary" type="submit">
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showExcludedDatesModal} onHide={handleCloseModal}>
        <Form onSubmit={handleExcludedDatesSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Data wykluczona z kosztów</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
              <Form.Group controlId="assignmentId">
                <Form.Control
                  type="hidden"
                  defaultValue={showExcludedDatesModal}
                  readOnly
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="excludeDate"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Form.Label>Data wykluczenia</Form.Label>
                <DatePicker
                  selected={excludedDate}
                  locale={pl}
                  dateFormat="dd/MM/yyyy"
                  onChange={setExcludedDate}
                  customInput={
                    <CustomDateInput
                      formControlProps={{
                        placeholder: 'd/m/y',
                        required: true
                      }}
                    />
                  }
                />
              </Form.Group>
              <Form.Group
                controlId="excludeReason"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Form.Label>Powód wykluczenia</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Wprowadź powód wykluczenia"
                  value={excludedReason}
                  onChange={e => setExcludedReason(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Anuluj
            </Button>
            <Button variant="primary" type="submit">
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showDetailsModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Szczegóły przypisanego urządzenia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDetailsModal !== false && (
            <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
              {showDetailsModal.equipment && (
                <p>
                  Urządzenie:{' '}
                  <Link to={`/equipment/${showDetailsModal.equipment._id}`}>
                    {`${showDetailsModal.equipment.equipmentId} | ${showDetailsModal.equipment.name}`}
                  </Link>
                </p>
              )}
              {showDetailsModal.brigade && (
                <p>
                  <strong>Brygada:</strong>{' '}
                  <Link
                    to={`/brigade/${showDetailsModal.brigade._id}`}
                    className="text-secondary"
                  >
                    {showDetailsModal.brigade.name}
                  </Link>
                </p>
              )}
              {showDetailsModal.cost !== undefined && (
                <p>
                  <strong>Całkowity koszt:</strong>{' '}
                  {formatCurrency(showDetailsModal.cost)}
                </p>
              )}
              {showDetailsModal.dateFrom && (
                <p>
                  <strong>Data przypisania:</strong>{' '}
                  {`${dayjs(showDetailsModal.dateFrom).format('DD-MM-YYYY')}`}{' '}
                  {ADMIN_ROLES.includes(user.role) && (
                    <Button
                      variant="link"
                      type="button"
                      className="p-0 ms-2 fs--1"
                      onClick={() => console.log('edit')}
                    >
                      edytuj
                    </Button>
                  )}
                </p>
              )}
              {showDetailsModal.datesExcluded && (
                <div>
                  <p className="mb-1">
                    <strong>Wykluczone daty:</strong>
                  </p>
                  {showDetailsModal.datesExcluded
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map(date => (
                      <div key={date._id}>
                        <div className="d-flex flex-column flex-md-row gap-3">
                          <p className="w-25 flex-shrink-0 mb-1">
                            {dayjs(date.date).format('DD-MM-YYYY')}
                          </p>{' '}
                          <p className="mb-1">{date.reason}</p>
                          {ADMIN_ROLES.includes(user.role) && (
                            <Button
                              variant="link"
                              type="button"
                              className="p-0 ms-2 fs--1"
                              onClick={() =>
                                handleDeleteExcludedDate(
                                  showDetailsModal._id,
                                  date._id
                                )
                              }
                            >
                              usuń
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
      <CSVLink
        data={equipment}
        headers={assignedEquipmentCsvHeaders}
        filename={`urządzenia_${constructionId}_${dayjs().format(
          'DD-MM-YYYY_HH-mm'
        )}.csv`}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

ConstructionDetailEquipment.propTypes = {
  constructionId: PropTypes.string.isRequired,
  equipment: PropTypes.array.isRequired,
  setConstruction: PropTypes.func.isRequired
};

export default ConstructionDetailEquipment;
