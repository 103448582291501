import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import TaskTableHeader from './TaskTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import useFormatDate from 'hooks/useFormatDate';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import StatusBadge from 'components/common/StatusBadge';
import PriorityBadge from 'components/common/PriorityBadge';

const columns = [
  {
    accessor: 'name',
    Header: 'Zadanie',
    headerProps: { style: { width: '250px' }, className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name } = rowData.row.original;
      return (
        <>
          <Link to={`/task/${_id}`}>
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'createdBy.firstName',
    Header: 'Autor',
    Cell: rowData => {
      const { createdBy } = rowData.row.original;
      return (
        <>
          {createdBy && (
            <p className="mb-0 d-flex flex-column">
              <Link className="text-500" to={`/user/${createdBy._id}`}>
                {createdBy.firstName} {createdBy.lastName}
              </Link>
            </p>
          )}
        </>
      );
    }
  },
  {
    accessor: 'startDate',
    Header: 'Data',
    headerProps: { className: 'pe-7' },
    Cell: rowData => {
      const { startDate, endDate } = rowData.row.original;
      const [formattedStartDate] = useFormatDate(startDate, false);
      const [formattedEndDate] = useFormatDate(endDate, false);
      return (
        <>
          <span>Od: {formattedStartDate}</span> <br />
          <span>Do: {formattedEndDate}</span>
        </>
      );
    }
  },
  {
    accessor: 'location.formattedAddress',
    Header: 'Lokalizacja',
    Cell: rowData => {
      const { location } = rowData.row.original;
      const locationAddressArray = location.formattedAddress.split(',');
      return (
        <>
          <p className="mb-0">
            {locationAddressArray[0]},
            <br />
            {locationAddressArray[1]} {locationAddressArray[2]}
          </p>
        </>
      );
    }
  },
  {
    accessor: 'users[0].firstName',
    Header: 'Pracownicy',
    Cell: rowData => {
      const { users } = rowData.row.original;
      return (
        <>
          {users.length > 0 && (
            <p className="mb-0 d-flex flex-column">
              {users.map(({ _id, firstName, lastName }, index) => (
                <Link key={_id} className="text-500" to={`/user/${_id}`}>
                  {firstName} {lastName}
                  {index !== users.length - 1 && ', '}
                  {(index + 1) % 2 === 0 && <br />}
                </Link>
              ))}
            </p>
          )}
        </>
      );
    }
  },
  {
    accessor: 'equipment.equipme',
    Header: 'Urządzenie',
    Cell: rowData => {
      const {
        equipment: { _id, name, equipmentId }
      } = rowData.row.original;
      return (
        <>
          <p className="mb-0">
            <Link to={`/equipment/${_id}`}>
              {equipmentId} / {name}
            </Link>
          </p>
        </>
      );
    }
  },
  {
    accessor: 'priority',
    Header: 'Priorytet',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'fs-0 text-center'
    },
    Cell: rowData => {
      const { priority } = rowData.row.original;
      return <PriorityBadge priority={priority} small />;
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'fs-0'
    },
    Cell: rowData => {
      const { status, deletedAt } = rowData.row.original;
      return <StatusBadge status={deletedAt ? 'trash' : status} />;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id, status, deletedAt } = rowData.row.original;
      const {
        handleDeleteClick,
        handleArchiveClick,
        handleUpdateClick,
        loadingRows
      } = rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/task/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            {!deletedAt && (
              <>
                {status !== 'completed' && <Dropdown.Divider as="div" />}
                {status !== 'draft' &&
                  status !== 'completed' &&
                  status !== 'trash' && (
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleUpdateClick(_id, 'completed')}
                    >
                      Zakończ
                    </Dropdown.Item>
                  )}
                {status === 'active' && (
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleUpdateClick(_id, 'onhold')}
                  >
                    Wstrzymaj
                  </Dropdown.Item>
                )}
                {status === 'onhold' && (
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleUpdateClick(_id, 'active')}
                  >
                    Wznów
                  </Dropdown.Item>
                )}
                {status === 'draft' && (
                  <Dropdown.Item
                    as="button"
                    className="text-success"
                    onClick={() => handleUpdateClick(_id, 'pending')}
                  >
                    Aktywuj
                  </Dropdown.Item>
                )}
                <Dropdown.Divider as="div" />
                <Dropdown.Item as={Link} to={`/create-task/${_id}`}>
                  Edycja
                </Dropdown.Item>
                <Dropdown.Item
                  href="#!"
                  className="text-warning"
                  onClick={() => handleArchiveClick(_id)}
                >
                  Archiwizuj
                </Dropdown.Item>
                <Dropdown.Item
                  href="#!"
                  className="text-danger"
                  onClick={() => handleDeleteClick(_id)}
                >
                  Usuń
                </Dropdown.Item>
              </>
            )}
          </div>
        </CardDropdown>
      );
    }
  }
];

const TaskList = ({ loadingRows, setRowLoading }) => {
  const [tasks, setTasks] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const handleDeleteClick = async id => {
    try {
      setRowLoading(id, true);
      await axios.put(`${process.env.REACT_APP_API_URL}/tasks/${id}`, {
        deletedAt: new Date()
      });

      setTasks([]);
      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Zadanie usunięte pomyślnie.
            <span
              className="p-0 text-decoration-underline"
              onClick={() => {
                handleUpdateClick(
                  id,
                  'pending',
                  'Zadanie przywrócone pomyślnie',
                  {
                    deletedAt: null
                  }
                );
              }}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(id, false);
    }
  };

  const handleArchiveClick = async id => {
    try {
      setRowLoading(id, true);
      await axios.patch(`${process.env.REACT_APP_API_URL}/tasks/${id}/status`, {
        status: 'archived'
      });
      toast.success('Zadanie zarchiwizowane pomyślnie');
      setTasks(tasks.filter(task => task._id !== id));
      setTasks(
        tasks.map(task =>
          task._id === id ? { ...task, status: 'archived' } : task
        )
      );
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(id, false);
    }
  };

  const handleUpdateClick = async (
    id,
    status,
    alert = 'Status zadania zaktualizowany pomyślnie',
    data = { status }
  ) => {
    try {
      setRowLoading(id, true);
      await axios.put(`${process.env.REACT_APP_API_URL}/tasks/${id}`, data);

      if (alert) {
        toast.success(alert);
      }
      setTasks([]);
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(id, false);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks?${queryParams}&deletedAt[in]`
      );
      setTasks(response.data.tasks);
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    if (tasks.length > 0) return;

    fetchTasks();
  }, [queryParams, tasks]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleDeleteClick,
        handleArchiveClick,
        handleUpdateClick,
        loadingRows
      }))}
      data={tasks}
      selection
      sortable
      pagination
      setClearFunction={setClearSelect}
    >
      <Card className="mb-3">
        <Card.Header>
          <TaskTableHeader
            table
            setTasks={setTasks}
            tasks={tasks}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={tasks.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

TaskList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(TaskList);
