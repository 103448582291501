import React from 'react';
import SharedFiles from 'components/common/SharedFiles';
import { Row, Card, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';

const BrigadeDetailAside = ({ brigade }) => {
  const { _id, uploadedFiles, manager } = brigade;

  if (!brigade) {
    return (
      <Card className="mb-3 fs--1">
        <Card.Body>
          <Placeholder as={Row} animation="glow" />
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6>Brygadzista</h6>
          <p className="mb-1">{`${manager?.firstName} ${manager?.lastName}`}</p>
        </Card.Body>
      </Card>
      {uploadedFiles.length > 0 && (
        <SharedFiles
          className="mb-3"
          files={uploadedFiles}
          parent={{ _id, type: 'constructions' }}
        />
      )}
    </div>
  );
};

BrigadeDetailAside.propTypes = {
  brigade: PropTypes.object.isRequired
};

export default BrigadeDetailAside;
