import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { handleMultiple } from '../../../../../helpers/bulkAction';
import IconButton from 'components/common/IconButton';
import { ConstructionFilters } from '../construction-list/ConstructionFilters';

const EquipmentTableHeader = ({
  selectedRowIds,
  constructionList,
  setConstructionList,
  setQueryParams
}) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [open, setOpen] = useState(false);

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'restore':
        handleMultiple(
          selectedRowIds,
          'construction/restore',
          constructionList,
          setConstructionList,
          {
            deletedAt: null
          }
        );
        break;
      case 'permanentDelete':
        handleMultiple(
          selectedRowIds,
          'construction/permanentDelete',
          constructionList,
          setConstructionList
        );
        break;
    }
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Usunięte budowy</h5>
      </Col>
      <Col
        xs={8}
        sm="auto"
        className="ms-auto text-end ps-0 d-flex justify-content-end"
      >
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-3"
          className="mx-2"
          onClick={() => setOpen(!open)}
        >
          <span>Filtruj</span>
        </IconButton>
        {Object.keys(selectedRowIds).length > 0 && (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Działania masowe"
              value={selectedAction}
              onChange={e => setSelectedAction(e.target.value)}
            >
              <option value="">Działania masowe</option>
              <option value="restore">Przywróć</option>
              <option value="permanentDelete">Usuń na stałe</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleApplyAction}
              disabled={!selectedAction}
            >
              Zastosuj
            </Button>
          </div>
        )}
      </Col>
      <Col xs={12} className="mt-2">
        <ConstructionFilters
          open={open}
          setQueryParams={setQueryParams}
          setItems={setConstructionList}
        />
      </Col>
    </Row>
  );
};

EquipmentTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  constructionList: PropTypes.array,
  setConstructionList: PropTypes.func,
  setQueryParams: PropTypes.func
};

export default EquipmentTableHeader;
