import { useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import React from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';
import CustomDateInput from 'components/common/CustomDateInput';
import DatePicker from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export const constructionTypes = [
  {
    value: 'gazociag',
    label: 'Gazociąg'
  },
  {
    value: 'energetyka',
    label: 'Energetyka'
  },
  {
    value: 'fotowoltaika',
    label: 'Fotowoltaika'
  },
  {
    value: 'baza_sprzetow',
    label: 'Baza sprzętów'
  }
];

const ConstructionOtherInfo = ({ dates }) => {
  const {
    formState: { errors },
    control,
    register,
    setValue
  } = useFormContext();
  const [categoriesList, setCategoriesList] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [brigadeOptions, setBrigadeOptions] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [formData, setFormData] = useState({
    startDate: null
  });

  const handleShowCategoryModal = () => setShowCategoryModal(true);

  const handleCloseModal = () => {
    setShowCategoryModal(false);
  };

  const handleSubmitCategory = async () => {
    if (!categoryName)
      return toast.error('Nazwa kategorii jest wymagana', { theme: 'colored' });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/construction/categories/add`,
        {
          name: categoryName
        }
      );
      setCategoryName('');
      handleCloseModal();
      handleCategoriesAdded(response.data.category);
      toast.success('Dodano kategorię budowy', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error('Nie udało się dodać kategorii budowy', {
        theme: 'colored'
      });
    }
  };

  const handleCategoriesAdded = addedCategories => {
    setCategoriesList([
      { value: addedCategories._id, label: addedCategories.name },
      ...categoriesList
    ]);
  };

  const fetchData = async () => {
    try {
      const categoriesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/construction/categories`
      );
      const categories = categoriesResponse.data.categories.map(category => ({
        value: category._id,
        label: category.name
      }));
      setCategoriesList(categories || []);

      // Fetch managers options
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/users?role=CONSTRUCTION-MANAGER`
      );
      const userOptionsData = usersResponse?.data?.users?.map(
        ({ _id, firstName, lastName }) => ({
          value: _id,
          label: `${firstName} ${lastName}`
        })
      );
      setUserOptions(userOptionsData || []);

      // Fetch brigades options
      const brigadesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/brigade`
      );
      const brigadesOptionData = brigadesResponse?.data?.brigade?.map(
        ({ _id, name }) => ({
          value: _id,
          label: name
        })
      );
      setBrigadeOptions(brigadesOptionData);
    } catch (error) {
      console.error(error);
      toast.error('Błąd podczas pobierania listy danych');
    }
  };

  const handleDateChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dates) {
      setFormData({
        ...formData,
        startDate: dates.startDate
      });
    }
  }, [dates]);

  return (
    <>
      <Card>
        <Card.Header as="h5">Pozostałe informacje</Card.Header>
        <Card.Body className="bg-light">
          <Form.Group className="mb-3">
            <Flex className="flex-between-center">
              <Form.Label>Kierownik budowy</Form.Label>
            </Flex>
            <Controller
              name="manager"
              rules={{ required: 'To pole jest wymagane' }}
              render={({ ref, field }) => (
                <MultiSelect
                  ref={ref}
                  {...field}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={userOptions}
                  placeholder="Wybierz użytkownika..."
                  isInvalid={!!errors.manager}
                />
              )}
              control={control}
            />
            {errors?.manager && (
              <Form.Text className="text-danger">
                {errors.manager.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Flex className="flex-between-center">
              <Form.Label>Brygady</Form.Label>
            </Flex>
            <Controller
              name="brigades"
              render={({ ref, field }) => (
                <MultiSelect
                  ref={ref}
                  {...field}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  options={brigadeOptions}
                  placeholder="Wybierz brygady..."
                  isInvalid={!!errors.user}
                />
              )}
              control={control}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Flex className="flex-between-center">
              <Form.Label>Rodzaj budowy</Form.Label>
            </Flex>
            <Controller
              name="type"
              rules={{ required: 'To pole jest wymagane' }}
              render={({ ref, field }) => (
                <MultiSelect
                  {...field}
                  ref={ref}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={constructionTypes}
                  placeholder="Wybierz rodzaj budowy..."
                  isInvalid={!!errors.type}
                />
              )}
              control={control}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Flex alignItems="center" justifyContent="between">
              <Form.Label>Kategorie</Form.Label>
              <Button
                size="sm"
                variant="link"
                className="p-0"
                onClick={handleShowCategoryModal}
              >
                Dodaj nową kategorię
              </Button>
            </Flex>
            <Controller
              name="categories"
              render={({ field, ref }) => (
                <MultiSelect
                  {...field}
                  ref={ref}
                  closeMenuOnSelect={false}
                  isMulti
                  options={categoriesList}
                  placeholder="Wybierz kategorię..."
                />
              )}
              control={control}
            />
          </Form.Group>

          <Form.Group
            controlId="startDate"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Form.Label>Data rozpoczęcia</Form.Label>
            <DatePicker
              selected={formData.startDate}
              filterDate={date =>
                !formData.endDate ||
                dayjs(date).isSameOrBefore(dayjs(formData.endDate), 'days')
              }
              locale={pl}
              dateFormat="dd/MM/yyyy"
              onChange={newDate => {
                handleDateChange('startDate', newDate);
                setValue('startDate', newDate);
              }}
              customInput={
                <CustomDateInput
                  isInvalid={!!errors.startDate}
                  errorMessage={errors.startDate?.message}
                  formControlProps={{
                    placeholder: 'd/m/y',
                    ...register('startDate', {
                      required: 'Data rozpoczęcia jest wymagana'
                    })
                  }}
                />
              }
            />
          </Form.Group>
        </Card.Body>
      </Card>

      <Modal show={showCategoryModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj kategorię</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoryName">
              <Form.Label>Nazwa kategorii</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę kategorii"
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleSubmitCategory}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConstructionOtherInfo.propTypes = {
  dates: PropTypes.object.isRequired
};

export default ConstructionOtherInfo;
