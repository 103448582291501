import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getThemeColor } from 'helpers/utils';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Flex from 'components/common/Flex';
import { ReactChart } from 'chartjs-react';
import { formatCurrency } from 'helpers/utils';
import CountUp from 'react-countup';

dayjs.extend(isBetween);

const ConstructionDetailSummary = ({ construction }) => {
  const { schedule, assignedEquipment } = construction;
  const [selectedSchedule, setSelectedSchedule] = useState(0);

  if (schedule.length < 0) return;

  const generateDaysLabels = schedule => {
    if (!schedule) return [];
    const startDate = dayjs(schedule.startDate);
    const endDate = dayjs(schedule.endDate);
    const daysLabels = [];
    const daysBetween = endDate.diff(startDate, 'day');

    for (let i = 0; i <= daysBetween; i++) {
      const date = dayjs(startDate).add(i, 'day');
      daysLabels.push({
        day: date.format('DD'),
        entireDate: date
      });
    }

    return daysLabels;
  };

  const generateData = (assignedEquipment, schedule) => {
    if (!schedule) return { data: [], totalScheduleCost: 0 };
    const startDate = dayjs(schedule.startDate);
    const endDate = dayjs(schedule.endDate);
    const daysBetween = dayjs(endDate).diff(startDate, 'day');

    const data = Array.from({ length: daysBetween + 1 }, (_, i) => {
      let dailyCost = 0;
      const date = dayjs(startDate).add(i, 'day');

      assignedEquipment.forEach(assignment => {
        const assignmentStartDate = dayjs(assignment.dateFrom);
        const assignmentEndDate = dayjs(assignment.dateTo);

        if (
          date.isBetween(
            assignmentStartDate.add(-1, 'day'),
            assignmentEndDate.add(1, 'day'),
            'day',
            '[]'
          )
        ) {
          dailyCost += assignment.equipment.costPerDay;
        }
      });

      return dailyCost;
    });

    return {
      data,
      totalScheduleCost: data.reduce((partialSum, a) => partialSum + a, 0)
    };
  };

  const getTotalCost = () => {
    let totalCost = 0;
    const today = dayjs();

    schedule.forEach(schedule => {
      const scheduleStartDate = dayjs(schedule.startDate);
      const scheduleEndDate = dayjs(schedule.endDate).isAfter(today)
        ? today
        : dayjs(schedule.endDate);
      const daysBetween = scheduleEndDate.diff(scheduleStartDate, 'day');

      const data = Array.from({ length: daysBetween + 1 }, (_, i) => {
        let dailyCost = 0;
        const date = dayjs(scheduleStartDate).add(i, 'day');

        assignedEquipment.forEach(assignment => {
          const assignmentStartDate = dayjs(assignment.dateFrom);
          const assignmentEndDate = dayjs(assignment.dateTo);

          if (
            date.isBetween(
              assignmentStartDate.add(-1, 'day'),
              assignmentEndDate.add(1, 'day'),
              'day',
              '[]'
            )
          ) {
            dailyCost += assignment.equipment.costPerDay;
          }
        });

        return dailyCost;
      });

      totalCost += data.reduce((partialSum, a) => partialSum + a, 0);
    });

    return totalCost;
  };

  const summarizedCost = getTotalCost();

  const labels = generateDaysLabels(schedule[selectedSchedule]);
  const daysLabels = labels.map(label => label.day);

  const generatedData = generateData(
    assignedEquipment,
    schedule[selectedSchedule]
  );

  const data = {
    labels: daysLabels,
    datasets: [
      {
        data: generatedData.data,
        fill: false,
        pointStyle: false,
        borderColor: getThemeColor('primary')
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: context => {
            return formatCurrency(context.parsed.y);
          },
          title: context => {
            return `${context[0].label} ${labels
              .find(label => label.day === context[0].label)
              .entireDate.format('MMM')}`;
          }
        },
        intersect: false,
        mode: 'nearest'
      },
      legend: false
    },
    scales: {
      x: {
        ticks: {
          color: getThemeColor('gray-500'),
          callback: function (value, index) {
            return `${labels[index].day}${
              index === 0 || index === labels.length - 1
                ? ` ${labels[index].entireDate.format('MMM')}`
                : ''
            }`;
          }
        }
      },
      y: {
        ticks: {
          color: getThemeColor('gray-500')
        },
        beginAtZero: true
      }
    }
  };

  schedule.sort((a, b) =>
    dayjs(b.endDate).isAfter(dayjs(a.endDate)) ? 1 : -1
  );

  return (
    <Card className="mb-3 fs--1">
      <Card.Header>
        <Flex justifyContent="between" alignItems="center">
          <Flex
            justifyContent="center"
            alignItems="start"
            direction="column"
            className="w-100"
          >
            <h6 className="h5">Suma kosztów</h6>
            <p className="h3 text-primary">
              <CountUp
                start={0}
                end={summarizedCost}
                duration={2.75}
                suffix=" zł"
                separator=" "
                decimals={0}
                decimal="."
              />
            </p>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{ minWidth: 130 }}
          >
            <Form.Select
              className="d-inline-block ps-2 pe-4"
              onChange={e => setSelectedSchedule(e.target.value)}
            >
              {schedule.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className="position-relative">
        <div>
          <ReactChart type="line" data={data} options={options} height={200} />
        </div>
        <Flex alignItems="center" className="mt-3" gap={2}>
          <h6 className=" m-0">
            Suma kosztów w dekadzie:{' '}
            <span className="text-primary">
              {formatCurrency(generatedData.totalScheduleCost)}
            </span>
          </h6>
        </Flex>
      </Card.Body>
    </Card>
  );
};

ConstructionDetailSummary.propTypes = {
  construction: PropTypes.object.isRequired
};

export default ConstructionDetailSummary;
