/**
 * Handles multiple selection of tasks and performs the specified API action.
 *
 * @param {Object} selectedRowIds - The selected row IDs.
 * @param {string} apiEndpoint - The API endpoint to call.
 * @param {Array} items - The items to update.
 * @param {Function} setItems - The function to update the items.
 * @param {string} method - The HTTP method to use (e.g. 'delete', 'put').
 * @param {Object} [data] - The data to send with the API request (optional).
 * @returns {Function} - The async function that performs the API action.
 */

import { toast } from 'react-toastify';
import axios from 'axios';
import Flex from 'components/common/Flex';
import React from 'react';

const bulkMap = [
  {
    type: 'tasks/archive',
    endpoint: 'tasks',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz zarchiwizować te zadania?',
    success: successfulIds =>
      `Wybrane zadania (${successfulIds.length}) zostały zarchiwizowane`,
    globalError: () => 'Błąd podczas archiwizacji zadań',
    singleError: rejectedNames =>
      `Nie można zarchiwizować zadań: ${rejectedNames.join(', ')}`
  },
  {
    type: 'tasks/delete',
    endpoint: 'tasks',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te zadania?',
    success: successfulIds =>
      `Wybrane zadania (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania zadań',
    singleError: rejectedNames =>
      `Nie można usunąć zadań: ${rejectedNames.join(', ')}`,
    allowRevert: true
  },
  {
    type: 'tasks/permanentDelete',
    endpoint: 'tasks',
    method: 'delete',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz usunąć te zadania na stałe? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrane zadania (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania zadań',
    singleError: rejectedNames =>
      `Nie można usunąć urządzeń: ${rejectedNames.join(', ')}`
  },
  {
    type: 'tasks/deleteCategory',
    endpoint: 'tasks/categories',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te kategorie?',
    success: successfulIds =>
      `Wybrane kategorie (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania kategorii',
    singleError: rejectedNames =>
      `Nie można usunąć kategorii: ${rejectedNames.join(', ')}`
  },
  {
    type: 'tasks/deleteLocation',
    endpoint: 'tasks/locations',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te lokalizacje?',
    success: successfulIds =>
      `Wybrane lokalizacje (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania lokalizacje',
    singleError: rejectedNames =>
      `Nie można usunąć lokalizacji: ${rejectedNames.join(', ')}`
  },
  {
    type: 'tasks/deleteChecklist',
    endpoint: 'tasks/checklists',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te checklisty?',
    success: successfulIds =>
      `Wybrane checklisty (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania checklisty',
    singleError: rejectedNames =>
      `Nie można usunąć checklisty: ${rejectedNames.join(', ')}`
  },
  {
    type: 'tasks/restore',
    endpoint: 'tasks',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz przywrócić te zadania?',
    success: successfulIds =>
      `Wybrane zadania (${successfulIds.length}) zostały przywrócone`,
    globalError: () => 'Błąd podczas przywracania zadań',
    singleError: rejectedNames =>
      `Nie można przywrócić zadań: ${rejectedNames.join(', ')}`
  },
  {
    type: 'equipment/delete',
    endpoint: 'equipment',
    method: 'put',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz usunąć te urządzenia? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrane urządzenia (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania urządzeń',
    singleError: rejectedNames =>
      `Nie można usunąć urządzeń, które mają przypisane zadania: 
      ${rejectedNames.join(', ')}`,
    allowRevert: true
  },
  {
    type: 'equipment/permanentDelete',
    endpoint: 'equipment',
    method: 'delete',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz usunąć te urządzenia na stałe? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrane urządzenia (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania urządzeń',
    singleError: rejectedNames =>
      `Nie można usunąć urządzeń, które mają przypisane zadania: 
      ${rejectedNames.join(', ')}`
  },
  {
    type: 'equipment/restore',
    endpoint: 'equipment',
    method: 'put',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz przywrócić te urządzenia? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrane urządzenia (${successfulIds.length}) zostały przywrócone`,
    globalError: () => 'Błąd podczas przywracania urządzeń',
    singleError: rejectedNames =>
      `Nie można przywrócić urządzeń: 
      ${rejectedNames.join(', ')}`
  },
  {
    type: 'equipment/deleteCategories',
    endpoint: 'equipment/categories',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te kategorie?',
    success: successfulIds =>
      `Wybrane kategorie (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania kategorii',
    singleError: rejectedNames =>
      `Nie można usunąć kategorii: ${rejectedNames.join(', ')}`
  },
  {
    type: 'equipment/deleteManufacturers',
    endpoint: 'equipment/manufacturers',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć tych producentów?',
    success: successfulIds =>
      `Wybrani producenci (${successfulIds.length}) zostali usunięci`,
    globalError: () => 'Błąd podczas usuwania producentów',
    singleError: rejectedNames =>
      `Nie można usunąć producentów przypisanych do urządzeń: 
      ${rejectedNames.join(', ')}`
  },
  {
    type: 'users/delete',
    endpoint: 'users',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć tych użytkowników?',
    success: successfulIds =>
      `Wybrani użytkownicy (${successfulIds.length}) zostali usunięci`,
    globalError: () => 'Błąd podczas usuwania użytkowników',
    singleError: rejectedNames =>
      `Nie można usunąć użytkowników: ${rejectedNames.join(', ')}`,
    allowRevert: true
  },
  {
    type: 'users/restore',
    endpoint: 'users',
    method: 'put',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz przywrócić tych użytkowników? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrani użytkownicy (${successfulIds.length}) zostali przywróceni`,
    globalError: () => 'Błąd podczas przywracania użytkowników',
    singleError: rejectedNames =>
      `Nie można przywrócić użytkowników: 
      ${rejectedNames.join(', ')}`
  },
  {
    type: 'users/archive',
    endpoint: 'users',
    method: 'put',
    confirmation: () =>
      'Jesteś pewny, że chcesz zarchiwizować tych użytkowników?',
    success: successfulIds =>
      `Wybrani użytkownicy (${successfulIds.length}) zostali zarchiwizowani`,
    globalError: () => 'Błąd podczas archiwizacji użytkowników',
    singleError: rejectedNames =>
      `Nie można zarchiwizować użytkowników: ${rejectedNames.join(', ')}`
  },
  {
    type: 'construction/delete',
    endpoint: 'construction',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te budowy?',
    success: successfulIds =>
      `Wybrane budowy (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania budów',
    singleError: rejectedNames =>
      `Nie można usunąć budów: ${rejectedNames.join(', ')}`,
    allowRevert: true
  },
  {
    type: 'construction/permanentDelete',
    endpoint: 'construction',
    method: 'delete',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz usunąć te budowy na stałe? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrane budowy (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania budów',
    singleError: rejectedNames =>
      `Nie można usunąć budów: ${rejectedNames.join(', ')}`
  },
  {
    type: 'construction/deleteCategory',
    endpoint: 'construction/categories',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te kategorie?',
    success: successfulIds =>
      `Wybrane kategorie (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania kategorii',
    singleError: rejectedNames =>
      `Nie można usunąć kategorii: ${rejectedNames.join(', ')}`
  },
  {
    type: 'construction/restore',
    endpoint: 'construction',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz przywrócić te budowy?',
    success: successfulIds =>
      `Wybrane budowy (${successfulIds.length}) zostały przywrócone`,
    globalError: () => 'Błąd podczas przywracania brygad',
    singleError: rejectedNames =>
      `Nie można przywrócić brygad: ${rejectedNames.join(', ')}`
  },
  {
    type: 'construction/deleteTransfer',
    endpoint: 'construction/transfers',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te transfery?',
    success: successfulIds =>
      `Wybrane transfery (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania transferów',
    singleError: rejectedNames =>
      `Nie można usunać transferu: ${rejectedNames.join(', ')}`
  },
  {
    type: 'brigade/delete',
    endpoint: 'brigade',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te brygady?',
    success: successfulIds =>
      `Wybrane brygady (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania brygad',
    singleError: rejectedNames =>
      `Nie można usunąć brygad: ${rejectedNames.join(', ')}`,
    allowRevert: true
  },
  {
    type: 'brigade/permanentDelete',
    endpoint: 'brigade',
    method: 'delete',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz usunąć te budowy na stałe? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrane brygady (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania brygad',
    singleError: rejectedNames =>
      `Nie można usunąć brygad: ${rejectedNames.join(', ')}`
  },
  {
    type: 'brigade/restore',
    endpoint: 'brigade',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz przywrócić te brygady?',
    success: successfulIds =>
      `Wybrane brygady (${successfulIds.length}) zostały przywrócone`,
    globalError: () => 'Błąd podczas przywracania brygad',
    singleError: rejectedNames =>
      `Nie można przywrócić brygad: ${rejectedNames.join(', ')}`
  }
];

export const handleMultiple = async (
  selectedRowIds,
  type,
  items,
  setItems,
  data
) => {
  const selectedIds = Object.keys(selectedRowIds);
  const callDefinition = bulkMap.find(bulkItem => bulkItem.type == type);
  const confirmed = window.confirm(callDefinition.confirmation(selectedIds));

  const revertDelete = async items => {
    const callDefinitionBase = callDefinition.endpoint.split('/')[0];
    const restoreDefinition = bulkMap.find(
      bulkItem => bulkItem.type == `${callDefinitionBase}/restore`
    );

    const promises = items.map(id => ({
      id,
      promise: axios.put(
        `${process.env.REACT_APP_API_URL}/${restoreDefinition.endpoint}/${id}`,
        { deletedAt: null }
      )
    }));
    await Promise.allSettled(
      promises.map(({ id, promise }) =>
        promise.then(() => ({ id, status: 'fulfilled' }))
      )
    );

    toast.success(restoreDefinition.success(items), {
      theme: 'colored'
    });
    setItems([]);
  };

  if (confirmed) {
    try {
      const promises = selectedIds.map(id => ({
        id,
        promise: axios[callDefinition.method](
          `${process.env.REACT_APP_API_URL}/${callDefinition.endpoint}/${id}`,
          data
        )
      }));
      const results = await Promise.allSettled(
        promises.map(({ id, promise }) =>
          promise.then(() => ({ id, status: 'fulfilled' }))
        )
      );

      const successfulIds = results
        .filter(result => result.value?.status == 'fulfilled')
        .map(result => result.value.id);

      const rejectedIds = selectedIds.filter(id => !successfulIds.includes(id));

      const rejectedNames = items
        .filter(item => rejectedIds.includes(item._id))
        .map(
          item =>
            `${item.name || item._id}${
              item.equipmentId ? ` (${item.equipmentId})` : ''
            }`
        );

      if (rejectedNames?.length > 0) {
        toast.error(callDefinition.singleError(rejectedNames), {
          theme: 'colored'
        });
      }

      if (successfulIds.length > 0) {
        const successMessage = callDefinition.success(successfulIds);

        toast.success(
          callDefinition.allowRevert ? (
            <>
              <Flex direction="column" alignItems="start" width="100%">
                {successMessage}
                <span
                  className="p-0 text-decoration-underline"
                  onClick={() => revertDelete(successfulIds)}
                >
                  Cofnij
                </span>
              </Flex>
            </>
          ) : (
            successMessage
          ),
          {
            theme: 'colored'
          }
        );
      }

      setItems([]);
    } catch (error) {
      console.error(error);
      toast.error(callDefinition.globalError, { theme: 'colored' });
    }
  }
};
