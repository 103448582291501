export default [
  {
    id: 0,
    content: [
      {
        senderUserId: 1,
        message:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt luctus, nisi nisl aliquam massa, nec aliquet nunc nisl sit amet mauris. Sed euismod, nisl vel tincidunt luctus, nisi nisl aliquam massa, nec aliquet nunc nisl sit amet mauris.',
        time: {
          day: 'Czwartek',
          hour: '11:54',
          date: 'Marzec 23, 2023'
        }
      },
      {
        senderUserId: 1,
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        time: {
          day: 'Czwartek',
          hour: '11:54',
          date: 'Marzec 23, 2023'
        }
      },
      {
        senderUserId: 1,
        message: 'How are you?',
        time: {
          day: 'Czwartek',
          hour: '11:54',
          date: 'Marzec 23, 2023'
        }
      },
      {
        senderUserId: 3,
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        status: 'delivered',
        time: {
          day: 'Piątek',
          hour: '12:54',
          date: 'Marzec 24, 2023'
        }
      },
      {
        senderUserId: 3,
        message: {
          text: ``
        },
        status: 'sent',
        time: {
          day: 'Piątek',
          hour: '12:54',
          date: 'Marzec 24, 2023'
        }
      },
      {
        senderUserId: 1,
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        time: {
          day: 'Piątek',
          hour: '12:54',
          date: 'Marzec 24, 2023'
        }
      }
    ]
  },
  {
    id: 1,
    content: [
      {
        senderUserId: 13,
        message:
          'In an organization stature, this is a must. Besides, we need to quickly establish all other professional appearances, e.g. having a website where members’ profile will be displayed along with other organizations information. Providing services to existing members is more important than attracting new members at this moment, in my opinion.',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: 'Your are right 👍',
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 2,
        message: 'We should divide the tasks among all other members.',
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 4,
        message: 'I will make a list of all the tasks.',
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: 'I can help you to do this.',
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 7, 2019'
        }
      },
      {
        senderUserId: 1,
        message:
          'It will be a great opportunity if I can contribute to this task 😊',
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 7, 2019'
        }
      },
      {
        senderUserId: 5,
        message: `Wow, it will be great!`,
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 7, 2019'
        }
      },
      {
        senderUserId: 5,
        message: `<a href="#!" class="text-primary">@Emma</a> What do you think about the plan?`,
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 7, 2019'
        }
      }
    ]
  },
  {
    id: 2,
    content: [
      {
        senderUserId: 3,
        message: 'Hello',
        status: 'seen',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: '🙂',
        status: 'seen',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 3,
    content: [
      {
        senderUserId: 3,
        message: 'Hello',
        status: 'sent',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 13,
        message: 'What are you doing?',
        time: {
          day: 'Sat',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 4,
    content: [
      {
        senderUserId: 4,
        message: "What's Up!",
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: 'Hey!',
        status: 'sent',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 4,
        message: 'How are you?',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 5,
    content: [
      {
        senderUserId: 5,
        message: "What's Up!",
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: 'Hey!',
        status: 'sent',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: '👋',
        status: 'sent',
        time: {
          day: 'Wed',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 6,
    content: [
      {
        senderUserId: 10,
        message: "What's Up!",
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: 'Hey!',
        status: 'sent',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 10,
        message: 'Hello!',
        time: {
          day: 'Fri',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 7,
    content: [
      {
        senderUserId: 11,
        message: 'I got my visa',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 11,
        message: `Let's have fun`,
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: '😮',
        status: 'sent',
        time: {
          day: 'Sun',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 8,
    content: [
      {
        senderUserId: 12,
        message: "What's Up!",
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: 'Hey!',
        status: 'sent',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 12,
        message: 'Hello!',
        time: {
          day: 'Wed',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 9,
    content: [
      {
        senderUserId: 22,
        message: "What's Up!",
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message: 'Hey!',
        status: 'sent',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      },
      {
        senderUserId: 3,
        message:
          'Yes, in an organization stature, this is a must. Besides, we need to quickly establish all other professional appearances, e.g., having a website where members’ profile will be displayed along with additional organizational information. Providing services to existing members is more important than attracting new members at this moment, in my opinion..',

        status: 'sent',
        time: {
          day: 'Mon',
          hour: '11:54 AM',
          date: 'May 5, 2019'
        }
      }
    ]
  },
  {
    id: 10,
    content: []
  }
];
