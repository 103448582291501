import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconItem from 'components/common/icon/IconItem';
import classNames from 'classnames';
import axios from 'axios';
import { downloadFile } from 'helpers/downloadFile';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';

const columns = [
  {
    accessor: 'name',
    Header: 'Dekada'
  },
  {
    accessor: 'startDate',
    Header: 'Data rozpoczęcia',
    Cell: rowData => {
      return dayjs(rowData.row.original.startDate).format('YYYY-MM-DD');
    }
  },
  {
    accessor: 'endDate',
    Header: 'Data zakończenia',
    Cell: rowData => {
      return dayjs(rowData.row.original.endDate).format('YYYY-MM-DD');
    }
  },
  {
    Header: 'Działania',
    disableSortBy: true,
    headerProps: { className: 'pe-4 text-end' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { handleShowModal, loadingRows } = rowData.column;
      const { _id, startDate, endDate, name } = rowData.row.original;
      const isLoading = loadingRows[_id];

      return (
        <div className="d-flex align-items-center justify-content-end">
          <IconItem
            tag="button"
            icon={isLoading ? 'spinner' : 'download'}
            size="sm"
            className={classNames({
              'btn rounded-3 me-2 fs--1 text-primary': true,
              'pe-none': isLoading
            })}
            iconClass={isLoading ? 'fa-spin' : undefined}
            onClick={() => handleShowModal(_id, startDate, endDate, name)}
          />
        </div>
      );
    }
  }
];

const ConstructionDetailSchedule = ({
  construction,
  loadingRows,
  setRowLoading
}) => {
  const ITEMS_PER_PAGE = 10;
  const handleShowModal = async (rowId, startDate, endDate, name) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    try {
      setRowLoading(rowId, true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/construction/${construction._id}/costs`,
        {
          reportDateFrom: startDate,
          reportDateTo: endDate,
          scheduleName: name
        },
        {
          responseType: 'blob'
        }
      );

      const fileName = `Raport_kosztow_${formattedStartDate}_${formattedEndDate}.pdf`;
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      downloadFile(pdfBlob, fileName);
    } catch (error) {
      toast.error(
        useTranslation(error.response?.data?.message) || 'Błąd pobierania pliku'
      );
      console.error(error);
    } finally {
      setRowLoading(rowId, false);
    }
  };

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        loadingRows,
        handleShowModal
      }))}
      data={construction?.schedule}
      sortable
      pagination
      perPage={ITEMS_PER_PAGE}
    >
      <Card className="mb-3">
        <FalconCardHeader title="Harmonogram dekad" titleTag="h6" />
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-800 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              borderless: true,
              className: 'fs--2 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          {construction?.schedule.length > ITEMS_PER_PAGE && (
            <>
              <AdvanceTableFooter
                rowCount={construction?.schedule.length}
                table
                rowsPerPageSelection
              />
              <AdvanceTablePagination table />
            </>
          )}
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

ConstructionDetailSchedule.propTypes = {
  construction: PropTypes.object.isRequired,
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(ConstructionDetailSchedule);
