/**
 * Simple implementation of file download
 * @note consider using FileSaver.js if we need download implementation in more places
 * @see https://www.npmjs.com/package/file-saver
 */
export const downloadFile = (fileBlob, fileName) => {
  const downloadUrl = window.URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
