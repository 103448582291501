import React, { useEffect, useState, useContext } from 'react';
import { Card, Dropdown, Modal, Form, Button } from 'react-bootstrap';
import MultiSelect from 'components/common/MultiSelect';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import ConstructionTransferTableHeader from './ConstructionTransferTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { toast } from 'react-toastify';
import axios from 'axios';
import PropTypes from 'prop-types';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import useTranslation from 'hooks/useTranslation';
import classNames from 'classnames';
import IconItem from 'components/common/icon/IconItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from 'context/auth/AuthContext';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import pl from 'date-fns/locale/pl';
import dayjs from 'dayjs';

const statusMap = {
  completed: 'Zakończony',
  pending: 'Oczekujący',
  admin_approved: 'Zatwierdzony przez administratora',
  manager_approved: 'Zatwierdzony przez kierownika',
  rejected: 'Odrzucony'
};
const completedStatuses = ['completed', 'rejected'];
const approvedStatuses = ['admin_approved', 'manager_approved'];

const isActionDisabled = (currentUser, status) =>
  completedStatuses.includes(status) ||
  (currentUser.role === 'DUR-ADMIN' && status === 'admin_approved') ||
  (currentUser.role === 'CONSTRUCTION-MANAGER' && status !== 'admin_approved');

const statusIconMap = {
  completed: 'arrow-right',
  pending: 'arrow-right',
  admin_approved: 'hourglass',
  manager_approved: 'hourglass-end',
  rejected: 'xmark'
};

const columns = [
  {
    accessor: 'equipment.equipmentId',
    Header: 'Urządzenie',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name, equipmentId } = rowData.row.original.equipment;
      return (
        <>
          <Link to={`/equipment/${_id}`}>
            <strong>{equipmentId}</strong>
          </Link>
          <br />
          {name}
        </>
      );
    }
  },
  {
    accessor: 'originConstruction.name',
    Header: 'Budowa pochodzenia',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { originConstruction, originBrigade } = rowData.row.original;
      return (
        <>
          <Link to={`/construction/${originConstruction._id}`}>
            <strong>
              {originConstruction.mpkSymbol} {originConstruction.name}
            </strong>
          </Link>
          <br />
          {originBrigade && (
            <Link
              to={`/brigade/${originBrigade._id}`}
              className="text-secondary"
            >
              {originBrigade.name}
            </Link>
          )}
        </>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    disableSortBy: true,
    headerProps: { className: 'pe-1 text-center' },
    cellProps: {
      className: 'py-2 text-center'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <FontAwesomeIcon
          className={classNames({
            'fs--1': true,
            'text-success': status === 'completed',
            'text-warning': approvedStatuses.includes(status),
            'text-danger': status === 'rejected'
          })}
          icon={statusIconMap[status]}
        />
      );
    }
  },
  {
    accessor: 'destinationConstruction.name',
    Header: 'Budowa docelowa',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name, mpkSymbol } =
        rowData.row.original.destinationConstruction;
      return (
        <>
          <Link to={`/construction/${_id}`}>
            <strong>
              {mpkSymbol} {name}
            </strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'createdBy.firstName',
    Header: 'Osoba zgłaszająca',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { createdBy } = rowData.row.original;
      return <>{`${createdBy.firstName} ${createdBy.lastName}`}</>;
    }
  },
  {
    Header: 'Działania',
    disableSortBy: true,
    headerProps: { className: 'pe-4 text-end' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, status, destinationConstruction, customTransferDate } =
        rowData.row.original;
      const {
        isLoading,
        currentUser,
        handleAcceptClick,
        handleRejectClick,
        handleDeleteClick,
        handleShowAcceptTransferModal,
        handleShowDetailsModal
      } = rowData.column;

      const buttonClassNames = color =>
        classNames({
          'btn rounded-3 me-2 fs--1': true,
          [`text-${color}`]: true && !isActionDisabled(currentUser, status),
          'pe-none': isLoading,
          disabled: isLoading || isActionDisabled(currentUser, status)
        });

      const approveStatus = () => {
        handleShowAcceptTransferModal(
          _id,
          destinationConstruction.brigades,
          customTransferDate
        );
      };

      return (
        <div className="d-flex align-items-center justify-content-end">
          <IconItem
            tag="button"
            icon="check"
            size="sm"
            className={buttonClassNames('success')}
            onClick={() =>
              currentUser.role === 'DUR-ADMIN'
                ? handleAcceptClick(_id)
                : approveStatus()
            }
          />
          <IconItem
            tag="button"
            icon="xmark"
            size="sm"
            className={buttonClassNames('danger')}
            onClick={() => handleRejectClick(_id)}
          />
          <CardDropdown
            className={classNames({
              'pe-none': isLoading
            })}
            btnRevealClass="icon-item icon-item-sm rounded-3"
            icon={isLoading ? 'spinner' : undefined}
            iconUtilClass={
              isLoading ? 'fa-spin pointer-events-none' : undefined
            }
          >
            <div className="py-2">
              <Dropdown.Item
                as="button"
                onClick={() => handleShowDetailsModal(_id)}
              >
                Szczegóły
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                as="button"
                className="text-danger"
                onClick={() => handleDeleteClick(_id)}
              >
                Usuń
              </Dropdown.Item>
            </div>
          </CardDropdown>
        </div>
      );
    }
  }
];

const ConstructionTransferList = ({ loadingRows }) => {
  const [transferList, setTransferList] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [rowId, setRowId] = useState();
  const [brigadeOptions, setBrigadeOptions] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);
  const [customTransferDate, setCustomTransferDate] = useState();

  const [showAcceptTransferModal, setShowAcceptTransferModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const [notes, setNotes] = useState('');

  const [destinationBrigade, setDestinationBrigade] = useState([]);

  const { user } = useContext(AuthContext);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/construction/transfers?${queryParams}`
      );
      setTransferList(response.data.transfers);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleDeleteClick = async id => {
    const confirmation = window.confirm(
      'Jesteś pewny, że chcesz usunąć ten transfer?\nTej operacji nie da się odwrócić!'
    );
    if (confirmation) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/construction/transfers/${id}`
        );

        setTransferList(transferList.filter(transfer => transfer._id !== id));
        toast.success('Transfer usunięty pomyślnie.', {
          theme: 'colored'
        });
      } catch (error) {
        console.error('Error deleting data:', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      }
    }
  };

  const handleAcceptClick = async id => {
    const status =
      user.role === 'ADMIN' || user.role === 'DUR-ADMIN'
        ? 'admin_approved'
        : 'manager_approved';

    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/construction/transfers/${id}/approve`,
        {
          status,
          destinationBrigade: destinationBrigade.value,
          customTransferDate
        }
      );

      setTransferList(
        transferList.map(transfer =>
          transfer._id === rowId
            ? { ...transfer, status: 'completed' }
            : transfer
        )
      );
      toast.success('Transfer zaakceptowany pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleReject = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/construction/transfers/${rowId}/reject`,
        {
          status: 'rejected',
          notes
        }
      );
      setTransferList(
        transferList.map(transfer =>
          transfer._id === rowId
            ? { ...transfer, status: 'rejected' }
            : transfer
        )
      );
      toast.success('Transfer odrzucony pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleRejectClick = _id => {
    setShowRejectModal(true);
    setRowId(_id);
  };

  const handleShowAcceptTransferModal = (_id, brigades, customTransferDate) => {
    setShowAcceptTransferModal(true);
    setBrigadeOptions(
      brigades.map(brigade => ({ value: brigade._id, label: brigade.name }))
    );
    setCustomTransferDate(customTransferDate);
    setRowId(_id);
  };

  const handleShowDetailsModal = _id => {
    const item = transferList.find(transfer => transfer._id === _id);
    setShowDetailsModal(item);
  };

  const handleCloseModal = () => {
    setShowAcceptTransferModal(false);
    setShowDetailsModal(false);
    setShowRejectModal(false);
  };

  const handleDestinationBrigadeChange = selected => {
    setDestinationBrigade(selected);
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    fetchData();
  }, []);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns.map(column => ({
          ...column,
          loadingRows,
          currentUser: user,
          handleDeleteClick,
          handleAcceptClick,
          handleRejectClick,
          handleShowAcceptTransferModal,
          handleShowDetailsModal,
          setBrigadeOptions,
          fetchData,
          setCustomTransferDate
        }))}
        data={transferList !== false ? transferList : []}
        selection
        sortable
        pagination
        perPage={25}
        setClearFunction={setClearSelect}
      >
        <Card className="mb-3">
          <Card.Header>
            <ConstructionTransferTableHeader
              table
              setTransferList={setTransferList}
              transferList={transferList}
              setQueryParams={setQueryParams}
              user={user}
              fetchData={fetchData}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      <Modal show={showAcceptTransferModal} onHide={handleCloseModal}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Wybierz docelową brygadę</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
              <Form.Group className="mb-3" controlId="categoryName">
                <Form.Label>Brygada docelowa</Form.Label>
                <MultiSelect
                  closeMenuOnSelect={true}
                  isMulti={false}
                  clearable
                  options={brigadeOptions}
                  placeholder="Wyszukaj..."
                  value={destinationBrigade}
                  onChange={handleDestinationBrigadeChange}
                />
              </Form.Group>
              {!customTransferDate && (
                <Form.Group
                  controlId="customTransferDate"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Form.Label>Data przypisania urządzenia</Form.Label>
                  <DatePicker
                    selected={customTransferDate}
                    locale={pl}
                    dateFormat="dd/MM/yyyy"
                    onChange={newDate => {
                      setCustomTransferDate(newDate);
                    }}
                    customInput={<CustomDateInput />}
                  />
                </Form.Group>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Anuluj
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                handleAcceptClick(rowId);
                handleCloseModal();
              }}
            >
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showDetailsModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Szczegóły transferu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDetailsModal !== false && (
            <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
              {showDetailsModal.equipment && (
                <p>
                  Urządzenie:{' '}
                  <Link to={`/equipment/${showDetailsModal.equipment._id}`}>
                    {`${showDetailsModal.equipment.equipmentId} | ${showDetailsModal.equipment.name}`}
                  </Link>
                </p>
              )}
              {showDetailsModal.createdBy && (
                <p>
                  Osoba zgłaszająca:{' '}
                  {`${showDetailsModal.createdBy.firstName} ${showDetailsModal.createdBy.lastName}`}
                </p>
              )}
              {showDetailsModal.originConstruction && (
                <p>
                  Budowa pochodzenia:{' '}
                  <Link
                    to={`/construction/${showDetailsModal.originConstruction._id}`}
                  >
                    {showDetailsModal.originConstruction.name}
                  </Link>
                </p>
              )}
              {showDetailsModal.originBrigade && (
                <p>
                  Brygada pochodzenia:{' '}
                  <Link
                    to={`/brigade/${showDetailsModal.originBrigade._id}`}
                    className="text-secondary"
                  >
                    {showDetailsModal.originBrigade.name}
                  </Link>
                </p>
              )}
              {showDetailsModal.destinationConstruction && (
                <p>
                  Budowa docelowa:{' '}
                  <Link
                    to={`/construction/${showDetailsModal.destinationConstruction._id}`}
                  >
                    {showDetailsModal.destinationConstruction.name}
                  </Link>
                </p>
              )}
              {showDetailsModal.destinationBrigade && (
                <p>
                  Brygada docelowa:{' '}
                  <Link
                    to={`/brigade/${showDetailsModal.destinationBrigade._id}`}
                    className="text-secondary"
                  >
                    {showDetailsModal.destinationBrigade.name}
                  </Link>
                </p>
              )}
              <p>
                Data utworzenia:{' '}
                {dayjs(showDetailsModal.createdAt).format('DD-MM-YYYY')}
              </p>
              {showDetailsModal.customTransferDate && (
                <p>
                  Data przypisania urządzenia:{' '}
                  {dayjs(showDetailsModal.customTransferDate).format(
                    'DD-MM-YYYY'
                  )}
                </p>
              )}
              <p>Status: {statusMap[showDetailsModal.status]}</p>
              {showDetailsModal.approvedAt && (
                <p>
                  Data zatwierdzenia:{' '}
                  {dayjs(showDetailsModal.approvedAt).format('DD-MM-YYYY')}
                </p>
              )}
              {showDetailsModal.notes && (
                <p>Notatki: {showDetailsModal.notes}</p>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* rejection modal */}
      <Modal show={showRejectModal} onHide={handleCloseModal}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Podaj powód odrzucenia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
              <Form.Group controlId="reason">
                <Form.Label>Powód odrzucenia</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="reason"
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Anuluj
            </Button>
            <Button
              variant="danger"
              type="button"
              disabled={!notes}
              onClick={() => {
                handleCloseModal();
                handleReject();
              }}
            >
              Odrzuć
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

ConstructionTransferList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(ConstructionTransferList);
