import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ChecklistDetails from './ChecklistDetails';
import ChecklistUpload from './ChecklistUpload';
import ChecklistBanner from './ChecklistBanner';
import { Col, Form, Row } from 'react-bootstrap';
import ChecklistOtherInfo from './ChecklistOtherInfo';
import { useForm } from 'react-hook-form';
import ChecklistCustomField from './ChecklistCustomField';
import useTranslation from 'hooks/useTranslation';
import { toast } from 'react-toastify';

import axios from 'axios';
import SharedFiles from 'components/common/SharedFiles';

const CreateChecklist = () => {
  const { checklistId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [defaultValues, setDefaultValues] = useState({
    createdBy: '646a5da7b990da0b485da39c',
    uploadedFiles: []
  });
  const [checklist, setChecklist] = useState({});

  const fetchChecklist = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/checklists/${checklistId}`
      );
      setChecklist(response.data.checklist);

      const mappedCategories = response.data.checklist.categories.map(
        category => ({
          value: category._id,
          label: category.name
        })
      );
      setDefaultValues({
        ...response.data.checklist,
        categories: mappedCategories
      });
      setCustomFields(response.data.checklist.fields);
      reset({
        ...response.data.checklist,
        categories: mappedCategories
      });
      setValue('uploadedFiles', []);
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
      setDefaultValues({}); // set defaultValues to empty object in case of error.
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (checklistId) {
      fetchChecklist();
    } else {
      setDefaultValues({}); // defaultValues set to empty object if no checklistId is available.
    }
  }, [checklistId]);

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
    watch
  } = useForm({
    defaultValues
  });

  const onSubmit = async data => {
    let response = {};
    setIsLoading(true);

    const formData = new FormData();

    for (const key in data) {
      if (key === 'categories') {
        data[key]?.map(category => {
          formData.append('categories', category.value);
        });
      } else if (key === 'fields') {
        formData.append('fields', JSON.stringify(customFields));
      } else if (key === 'uploadedFiles') {
        if (data[key] && data[key].length > 0) {
          for (let file of data[key]) {
            formData.append('uploadedFiles', file);
          }
        }
      } else {
        formData.append(key, data[key]);
      }
    }

    try {
      if (checklistId) {
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/tasks/checklists/${checklistId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/tasks/checklists/add`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      }

      toast.success(useTranslation(response.data.message, 'pl'), {
        theme: 'colored'
      });
      navigate('/task-checklist-list');
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-3">
        <Col xs={12}>
          <ChecklistBanner
            title={`${checklistId ? 'Edytuj' : 'Dodaj'} checklistę`}
            isLoading={isLoading}
            itemID={checklistId}
          />
        </Col>
        <Col lg={8}>
          <ChecklistDetails register={register} errors={errors} />
          <ChecklistCustomField
            register={register}
            unregister={unregister}
            control={control}
            setValue={setValue}
            checklistFields={defaultValues.fields}
            onCustomFieldsChange={customFields => {
              setCustomFields(customFields);
            }}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar mb-3">
            <ChecklistOtherInfo
              register={register}
              control={control}
              errors={errors}
              watch={watch}
            />
            {checklist?.uploadedFiles?.length > 0 && (
              <SharedFiles
                className="mb-3"
                files={checklist?.uploadedFiles}
                parent={{ _id: checklist?._id, type: 'tasks/checklists' }}
              />
            )}
            <ChecklistUpload setValue={setValue} />
          </div>
        </Col>
        <Col>
          <ChecklistBanner isLoading={isLoading} />
        </Col>
      </Row>
    </Form>
  );
};

export default CreateChecklist;
