import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { handleMultiple } from '../../../../helpers/bulkAction';

import PropTypes from 'prop-types';
import axios from 'axios';
import { useWatch } from 'react-hook-form';

import IconButton from 'components/common/IconButton';
import useTranslation from 'hooks/useTranslation';

const LocationTableHeader = ({
  selectedRowIds,
  setLocations,
  locations,
  showModal,
  setShowModal,
  register,
  handleSubmit,
  errors,
  control,
  unregister
}) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [loading, setLoading] = useState(false);

  const fields = ['name', 'streetName', 'city', 'zip', 'country', 'id'];
  const errorFieldRequired = (
    <Form.Text className="text-danger">To pole jest wymagane</Form.Text>
  );

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'delete':
        handleMultiple(
          selectedRowIds,
          'tasks/deleteLocation',
          locations,
          setLocations
        );
        break;
    }
  };

  const id = useWatch({ control, name: 'id' });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmitCustom = async data => {
    if (!loading) {
      setLoading(true);
      if (id) {
        const dataToSend = data;
        delete dataToSend.id;

        try {
          await axios.put(
            `${process.env.REACT_APP_API_URL}/tasks/locations/${id}`,
            dataToSend
          );
          toast.success('Lokalizacja została zaktualizowana');
          const index = locations.findIndex(location => location._id === id);
          const newLocations = [...locations];
          newLocations[index] = data;
          setLocations(newLocations);
        } catch (error) {
          console.error('Failed to create/update task location', error);
          toast.error(useTranslation(error.response?.data.message, 'pl'), {
            theme: 'colored'
          });
        } finally {
          setLoading(false);
          setShowModal(false);
          fields.forEach(field => unregister(field));
        }
      } else {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/tasks/locations/add`,
            data
          );
          toast.success('Lokalizacja została dodana');
          setLocations([res.data.location, ...locations]);
          setLoading(false);
        } catch (error) {
          console.error('Failed to create/update task location', error);
          toast.error(useTranslation(error.response?.data.message, 'pl'), {
            theme: 'colored'
          });
        } finally {
          setShowModal(false);
        }
      }
    }
  };

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Lokalizacje</h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select
                size="sm"
                aria-label="Działania masowe"
                value={selectedAction}
                onChange={e => setSelectedAction(e.target.value)}
              >
                <option>Działania masowe</option>
                <option value="delete">Usuń</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={handleApplyAction}
              >
                Zastosuj
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                className="ms-2"
                onClick={handleShowModal}
              >
                <span className="d-none d-sm-inline-block ms-1">
                  Dodaj lokalizację
                </span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edytuj lokalizację</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handleSubmitCustom)}>
            <Form.Group className="mb-2">
              <Form.Label>Nazwa</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwe"
                {...register('name', { required: true })}
              />
              {errors.name && errorFieldRequired}
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Ulica</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź ulicę"
                {...register('streetName', { required: true })}
              />
              {errors.streetName && errorFieldRequired}
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Miasto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź miasto"
                {...register('city', { required: true })}
              />
              {errors.city && errorFieldRequired}
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Kod pocztowy</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź kod pocztowy"
                {...register('zip', { required: true })}
              />
              {errors.zip && errorFieldRequired}
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Kraj</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź kraj"
                {...register('country', { required: true })}
              />
              {errors.country && errorFieldRequired}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit(handleSubmitCustom)}
            disabled={loading}
          >
            {id ? 'Zapisz' : 'Stwórz'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

LocationTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  setLocations: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired
};

export default LocationTableHeader;
