import changelog from 'data/changelog';

export const dashboardRoutes = {
  label: 'Panel główny',
  labelDisable: true,
  children: [
    {
      name: 'Panel główny',
      to: '/',
      exact: true,
      active: true,
      icon: 'chart-pie'
    }
  ]
};
export const appRoutes = {
  label: 'FSM',
  children: [
    {
      name: 'Kalendarz',
      icon: 'calendar-alt',
      to: '/calendar',
      active: true,
      authorizeRoles: ['ADMIN', 'SERVICE-MANAGER']
    },
    {
      name: 'Wiadomości',
      icon: 'comments',
      to: '/chat',
      active: false,
      authorizeRoles: ['ADMIN', 'SERVICE-MANAGER'],
      badge: {
        type: 'warning',
        text: 'wkrótce'
      }
    },
    {
      name: 'Zadania',
      icon: 'table',
      active: true,
      authorizeRoles: ['ADMIN', 'SERVICE-MANAGER'],
      children: [
        {
          name: 'Dodaj zadanie',
          to: '/create-task',
          active: true
        },
        {
          name: 'Lista zadań',
          to: '/task-list',
          active: true
        },
        {
          name: 'Definicje checklisty',
          to: '/task-checklist-list',
          active: true
        },
        {
          name: 'Kategorie',
          to: '/task-category-list',
          active: true
        },
        {
          name: 'Kosz',
          to: '/task-trash',
          active: true,
          authorizeRoles: ['ADMIN']
        }
      ]
    },
    {
      name: 'Urządzenia',
      icon: 'truck-pickup',
      active: true,
      authorizeRoles: ['ADMIN', 'DUR-ADMIN', 'CONSTRUCTION-MANAGER'],
      children: [
        {
          name: 'Dodaj urządzenie',
          to: '/create-equipment',
          active: true
        },
        {
          name: 'Lista urządzeń',
          to: '/equipment-list',
          active: true
        },
        {
          name: 'Lista producentów',
          to: '/equipment-manufacturer-list',
          active: true
        },
        {
          name: 'Kategorie',
          to: '/equipment-category-list',
          active: true
        },
        {
          name: 'Kosz',
          to: '/equipment-trash',
          active: true,
          authorizeRoles: ['ADMIN', 'DUR-ADMIN']
        }
      ]
    },
    {
      name: 'Brygady',
      icon: 'users',
      authorizeRoles: [
        'ADMIN',
        'DUR-ADMIN',
        'BRIGADE-MANAGER',
        'CONSTRUCTION-MANAGER'
      ],
      active: true,
      children: [
        {
          name: 'Dodaj brygadę',
          to: '/create-brigade',
          active: true
        },
        {
          name: 'Lista brygad',
          to: '/brigade-list',
          active: true
        },
        {
          name: 'Kosz',
          to: '/brigade-trash',
          active: true,
          authorizeRoles: ['ADMIN', 'DUR-ADMIN']
        }
      ]
    },
    {
      name: 'Budowy',
      icon: 'shapes',
      active: true,
      authorizeRoles: ['ADMIN', 'DUR-ADMIN', 'CONSTRUCTION-MANAGER'],
      children: [
        {
          name: 'Dodaj budowę',
          to: '/create-construction',
          active: true
        },
        {
          name: 'Lista budów',
          to: '/construction-list',
          active: true
        },
        {
          name: 'Lista Transferów',
          to: '/construction-transfer-list',
          active: true
        },
        {
          name: 'Kategorie',
          to: '/construction-category-list',
          active: true
        },
        {
          name: 'Kosz',
          to: '/construction-trash',
          active: true,
          authorizeRoles: ['ADMIN', 'DUR-ADMIN']
        }
      ]
    },
    {
      name: 'Lokalizacje',
      icon: 'location-pin',
      active: true,
      authorizeRoles: [
        'ADMIN',
        'DUR-ADMIN',
        'CONSTRUCTION-MANAGER',
        'SERVICE-MANAGER'
      ],
      children: [
        {
          name: 'Lista lokalizacji',
          to: '/task-location-list',
          active: true
        }
      ]
    },
    {
      name: 'Użytkownicy',
      icon: 'user',
      active: true,
      authorizeRoles: ['ADMIN'],
      children: [
        {
          name: 'Lista użytkowników',
          to: '/user-list',
          active: true
        },
        {
          name: 'Dodaj użytkownika',
          to: '/create-user',
          active: true
        }
      ]
    }
    // TODO: Add reports routes
    // {
    //   name: 'Raporty',
    //   icon: 'chart-line',
    //   to: '/support-desk/reports',
    //   active: true
    // }
  ]
};

// HELPER ROUTES for development purposes
export const pagesRoutes = {
  label: 'Pozostałe strony',
  authorizeRoles: ['SUPERADMIN'],
  children: [
    {
      name: 'Logowanie',
      icon: 'lock',
      active: true,
      children: [
        {
          name: 'Logowanie',
          to: '/login',
          active: true
        },
        {
          name: 'Wylogowanie',
          to: '/logout',
          active: true
        },
        {
          name: 'Przypomnienie hasła',
          to: '/forgot-password',
          active: true
        },
        {
          name: 'Reset hasła',
          to: '/reset-password',
          active: true
        }
      ]
    }
  ]
};

export const helpPages = {
  label: 'Wsparcie techniczne',
  children: [
    {
      name: 'Pomoc',
      icon: 'question-circle',
      active: true,
      children: [
        {
          name: 'FAQ',
          to: '/help/faq-accordion',
          active: true
        },
        {
          name: 'Formularz kontaktowy',
          to: '/help/contact-form',
          active: true
        }
      ]
    },

    {
      name: 'Lista zmian',
      icon: 'code-branch',
      to: '/changelog',
      active: true,
      badge: {
        type: 'info',
        text: changelog[0].version
      }
    }
  ]
};

export default [dashboardRoutes, appRoutes, pagesRoutes, helpPages];
