import { Collapse, Form, Col, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { constructionTypes } from '../create-construction/ConstructionOtherInfo';

export const ConstructionFilters = ({ open, setQueryParams }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      categories: [],
      type: [],
      users: []
    }
  });
  const [categoriesList, setCategoriesList] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const formValues = watch();

  const fetchData = async () => {
    try {
      const categoriesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/construction/categories`
      );
      const categories = categoriesResponse.data.categories?.map(category => ({
        value: category._id,
        label: category.name
      }));
      setCategoriesList(categories);

      // Fetch user options
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/users?role=CONSTRUCTION-MANAGER`
      );
      const userOptionsData = usersResponse.data.users.map(
        ({ _id, firstName, lastName }) => ({
          value: _id,
          label: `${firstName} ${lastName}`
        })
      );
      setUserOptions(userOptionsData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const onSubmit = data => {
    let queryParts = [];

    if (data.name) {
      queryParts.push(`q=${encodeURIComponent(data.name)}`);
    }

    if (data.mpkSymbol) {
      queryParts.push(`mpkSymbol=${encodeURIComponent(data.mpkSymbol)}`);
    }

    if (data.category?.length > 0) {
      data.category.map(category => {
        queryParts.push(`categories[in]=${category.value}`);
      });
    }

    if (data.type.length > 0) {
      data.type.map(type => {
        queryParts.push(`type[in]=${type.value}`);
      });
    }

    if (data.users.length > 0) {
      data.users.map(user => {
        queryParts.push(`users[in]=${user.value}`);
      });
    }

    setQueryParams(queryParts?.join('&') || '');
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  return (
    <Collapse in={open}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              controlId="mpkSymbol"
              className="mb-3 d-flex flex-column"
            >
              <Form.Label>MPK</Form.Label>
              <Form.Control
                type="text"
                name="mpkSymbol"
                placeholder="Symbol MPK"
                isInvalid={!!errors.mpkSymbol}
                {...register('mpkSymbol')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mpkSymbol && errors.mpkSymbol.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="name" className="mb-3 d-flex flex-column">
              <Form.Label>Szukaj</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nazwa budowy"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group className="mb-3 d-flex flex-column">
              <Form.Label>Kategoria</Form.Label>
              <Controller
                name="category"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    isMulti
                    clearable
                    closeMenuOnSelect={true}
                    options={categoriesList}
                    placeholder="Wybierz kategorię..."
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="type" className="mb-3 d-flex flex-column">
              <Form.Label>Rodzaj budowy</Form.Label>
              <Controller
                name="type"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti
                    clearable
                    options={constructionTypes}
                    placeholder="Wybierz rodzaj budowy..."
                    isInvalid={!!errors.type}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="manager" className="mb-3 d-flex flex-column">
              <Form.Label>Kierownik budowy</Form.Label>
              <Controller
                name="manager"
                render={({ ref, field }) => (
                  <MultiSelect
                    ref={ref}
                    {...field}
                    closeMenuOnSelect={false}
                    isMulti
                    isOptionDisabled={option =>
                      option.isDisabled || formValues.manager?.length >= 5
                    }
                    clearable
                    options={userOptions}
                    placeholder="Wybierz kierownika..."
                    isInvalid={!!errors.manager}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Flex
              justifyContent="end"
              alignItems="end"
              style={{
                height: '100%'
              }}
            >
              <Button
                size="sm"
                variant="falcon-primary"
                type="submit"
                transform="shrink-3"
              >
                <span>Zastosuj filtry</span>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

ConstructionFilters.propTypes = {
  open: PropTypes.bool,
  setQueryParams: PropTypes.func,
  setItems: PropTypes.func
};
