import React from 'react';
import { Card, Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { useState, useEffect } from 'react';
import axios from 'axios';

const ConstructionDetails = () => {
  const {
    register,
    formState: { errors },
    control,
    setValue
  } = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationsPrepared, setLocationsPrepared] = useState([]);
  const selectedLocation = useWatch({ control, name: 'locationId' });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/locations`
      );
      setLocations(response.data.locations);
      const responsePrepare = response.data.locations.map(({ _id, name }) => ({
        value: _id,
        label: name
      }));
      setLocationsPrepared(responsePrepare);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  useEffect(() => {
    fetchData();
  }, []);

  const handleLocationSelect = () => {
    if (selectedLocation) {
      const location = locations.find(
        location => location._id === selectedLocation.value
      );
      setValue('locationName', location.name);
      setValue('streetName', location.streetName);
      setValue('city', location.city);
      setValue('zip', location.zip);
      setValue('country', location.country);
      handleCloseModal();
    }
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col xs="3" xxl="2">
              <Form.Group controlId="mpkSymbol">
                <Form.Label>
                  <span className="d-none d-md-inline">Symbol</span> MPK
                </Form.Label>
                <Form.Control
                  type="text"
                  name="mpkSymbol"
                  {...register('mpkSymbol', {
                    required: 'To pole jest wymagane'
                  })}
                  placeholder="MPK"
                  isInvalid={!!errors.mpkSymbol}
                />
                <Form.Control.Feedback type="invalid">
                  Pole jest wymagane
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="9" xxl="10">
              <Form.Group controlId="name">
                <Form.Label>Nazwa budowy</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  {...register('name', {
                    required: 'To pole jest wymagane'
                  })}
                  placeholder="Nazwa budowy"
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  Pole jest wymagane
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="12">
              <div className="border-dashed border-bottom"></div>
            </Col>
            <Col md="6">
              <Form.Group controlId="locationName">
                <Form.Label>Miejsce budowy </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Miejsce budowy "
                  name="locationName"
                  isInvalid={!!errors.locationName}
                  {...register('locationName', {
                    required: 'To pole jest wymagane'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.locationName && errors.locationName.message}
                </Form.Control.Feedback>
                <Button
                  size="sm"
                  variant="link"
                  className="p-0"
                  onClick={handleOpenModal}
                >
                  Wybierz z katalogu
                </Button>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="streetName">
                <Form.Label>Ulica</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ulica"
                  name="streetName"
                  isInvalid={!!errors.streetName}
                  {...register('streetName', {
                    required: 'To pole jest wymagane'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetName && errors.streetName.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="city">
                <Form.Label>Miasto</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Miasto"
                  name="city"
                  isInvalid={!!errors.city}
                  {...register('city', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city && errors.city.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="zip">
                <Form.Label>Kod pocztowy</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kod pocztowy"
                  name="zip"
                  isInvalid={!!errors.zip}
                  {...register('zip', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zip && errors.zip.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="country">
                <Form.Label>Państwo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Państwo"
                  name="country"
                  {...register('country')}
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group controlId="description">
                <Form.Label>Opis budowy</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="description"
                  {...register('description')}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Wybierz lokalizację z katalogu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Flex className="flex-between-center">
                <Form.Label>Wybierz lokalizacje</Form.Label>
              </Flex>
              <Controller
                name="locationId"
                rules={{ required: 'To pole jest wymagane' }}
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    options={locationsPrepared}
                    placeholder="Wybierz lokalizacje..."
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleLocationSelect}>
            Wybierz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConstructionDetails;
