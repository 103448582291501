import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

import BrigadeDetailHeader from './BrigadeDetailHeader';
import BrigadeDetailAside from './BrigadeDetailAside';
import BrigadeDetailContent from './BrigadeDetailContent';

const BrigadeDetail = () => {
  const { brigadeId } = useParams();
  const [brigade, setBrigade] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/brigade/${brigadeId}`
        );
        setBrigade(response.data.brigade);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania brygady');
      }
    };

    fetchData();
  }, [brigadeId]);

  if (!brigade) {
    return (
      <Row className="align-items-center">
        <Col>
          <h5 className="mb-0">Szczegóły brygady</h5>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <BrigadeDetailHeader brigade={brigade} />
      <Row className="g-3">
        <Col lg={8}>
          <BrigadeDetailContent brigade={brigade} />
        </Col>
        <Col lg={4}>
          <BrigadeDetailAside brigade={brigade} />
        </Col>
      </Row>
    </>
  );
};

export default BrigadeDetail;
