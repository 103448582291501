import { themeColors } from 'helpers/utils';

import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import { users as userList } from '../users/users';
import tasks from 'data/tasks/tasks';

const ticketsBadges = [
  { content: 'Recent', type: 'success' },
  { content: 'Overdue', type: 'danger' },
  { content: 'Remaining', type: 'warning' },
  { content: 'Responded', type: 'info' },
  { content: 'Closed', type: 'secondary' }
];

export const greetingItems = [
  {
    title: 'Dodaj zadanie',
    text: 'Wypełnij formularz i przydziel zadanie',
    icon: 'check',
    color: 'success',
    link: '/create-task'
  },
  {
    title: 'Lista zadań',
    text: 'Przejdź do listy wszystkich zadań',
    icon: 'star',
    color: 'info',
    link: '/task-list'
  },
  {
    title: 'Przejrzyj raporty',
    text: 'Monitoruj postęp prac',
    icon: 'chart-line',
    color: 'secondary',
    link: '#!',
    disabled: true
  }
];

export const greetingItemsConstructionManager = [
  {
    title: 'Dodaj budowę',
    text: 'Wypełnij formularz i zarządzaj budowami',
    icon: 'check',
    color: 'success',
    link: '/create-construction'
  },
  {
    title: 'Lista budów',
    text: 'Przejdź do listy wszystkich budów',
    icon: 'star',
    color: 'info',
    link: '/construction-list'
  },
  {
    title: 'Lista transferów',
    text: 'Monitoruj i zarządzaj transferami urządzeń',
    icon: 'arrow-right-arrow-left',
    color: 'secondary',
    link: '/construction-transfer-list'
  }
];

// ----------- Calendar Management events ------------------------
const date = new Date();
const thisMonthNumber = dayjs(date).format('MM');
const thisMonthName = dayjs(date).format('MMM');

const thisYear = dayjs().format('YYYY');
export const managementEvents = tasks.map(task => {
  return {
    id: uuid(),
    title: task.title,
    start: `${thisYear}-${thisMonthNumber}-${Math.floor(
      Math.random() * 10 + 1
    )}`,
    end: `${thisYear}-${thisMonthNumber}-${Math.floor(Math.random() * 29 + 1)}`,
    startTime: `07 ${thisMonthName}, ${thisYear}`,
    endTime: `10 ${thisMonthName}, ${thisYear}`,
    extendedProps: {
      description: task.description,
      location: task.address,
      organizer: task.country
    },
    display: 'background',
    color: 'primary',
    classNames: `border border-2 border-primary bg-100`
  };
});

export const membersInfo = userList.map(user => {
  const randomNum = Math.floor(Math.random() * 100) + 1;
  const { id, firstName, lastName, position, avatar } = user;
  const currentTask = tasks.find(task => {
    return task.users.some(user => user.id === id);
  });

  return {
    id: id,
    name: `${firstName} ${lastName}`,
    avatar,
    status: ticketsBadges[Math.floor(Math.random() * ticketsBadges.length)],
    role: position,
    currentTask: currentTask
      ? {
          id: currentTask.id,
          title: currentTask.title
        }
      : {
          title: 'Brak zadania'
        },
    today: {
      amount: Math.floor(Math.random() * 100) + 1,
      color:
        Object.keys(themeColors)[
          Math.floor(Math.random() * (Object.keys(themeColors).length - 5))
        ],
      time:
        Math.floor(Math.random() * 8) +
        ':' +
        Math.floor(Math.random() * 60) +
        'h'
    },
    thisWeek: {
      amount: 68,
      time:
        Math.floor(Math.random() * 40) +
        ':' +
        Math.floor(Math.random() * 60) +
        'h',
      color: 'success'
    },
    data: Array.from({ length: 6 }, () => Math.floor(Math.random() * 85) + 15),
    priority: {
      title: randomNum + '%',
      data: randomNum,
      color: `hsl(${Math.floor((randomNum / 100) * 120)}, 100%, 50%)`
    }
  };
});

export const runningProjects = tasks.map(task => {
  const { id, title, address, users } = task;

  return {
    id,
    title,
    address,
    users: users.map(user => {
      const { id, name } = user;
      const avatar = userList.find(user => user.id === id).avatar;
      return {
        id,
        name,
        avatar,
        size: 'xl'
      };
    }),
    tasks: Math.floor(Math.random() * 100) + 24,
    color: 'primary',
    duration:
      Math.floor(Math.random() * 8) +
      'h ' +
      Math.floor(Math.random() * 60) +
      'm',
    date:
      '2023/' +
      ('0' + Math.floor(Math.random() * 3 + 1)).slice(-2) +
      '/' +
      ('0' + Math.floor(Math.random() * 31)).slice(-2)
  };
});

export const recentActivities = [
  {
    id: 0,
    title: 'Jan Kowalski dodał zdjęcia do zadania',
    text: 'W zadaniu "Vestibulum Justo Sem" zostały dodane zdjęcia',
    icon: 'image',
    time: '2m temu',
    status: false
  },
  {
    id: 1,
    title: 'Anna Nowak zakończyla zadanie',
    text: "Zadanie 'Vestibulum Justo Sem' zostało zakończone",
    icon: 'check',
    time: '26m temu',
    status: 'completed'
  },
  {
    id: 2,
    title: 'Piotr wiśniewski rozpoczął nowe zadanie',
    text: 'Zadanie "Vestibulum Justo Sem" zostało rozpoczęte',
    icon: 'clock',
    time: '1h ago',
    status: 'current'
  },
  {
    id: 3,
    title: 'Jan Kowalski dodał zdjęcia do zadania',
    text: 'W zadaniu "Vestibulum Justo Sem" zostały dodane zdjęcia',
    icon: 'image',
    time: '2m temu',
    status: false
  },
  {
    id: 4,
    title: 'Anna Nowak zakończyla zadanie',
    text: "Zadanie 'Vestibulum Justo Sem' zostało zakończone",
    icon: 'check',
    time: '26m temu',
    status: 'completed'
  },
  {
    id: 5,
    title: 'Piotr wiśniewski rozpoczął nowe zadanie',
    text: 'Zadanie "Vestibulum Justo Sem" zostało rozpoczęte',
    icon: 'clock',
    time: '1h ago',
    status: 'current'
  }
];

export const markers = [
  {
    id: 0,
    lat: 54.352025,
    long: 18.646638,
    name: 'Zamek Królewski',
    street: 'ul. Świętego Jana 3',
    location: '80-834 Gdańsk'
  },
  {
    id: 1,
    lat: 51.107883,
    long: 17.038538,
    name: 'Ostrów Tumski',
    street: 'plac Katedralny 18',
    location: '50-328 Wrocław'
  },
  {
    id: 2,
    lat: 52.229676,
    long: 21.012229,
    name: 'Zamek Królewski',
    street: 'plac Zamkowy 4',
    location: '00-277 Warszawa'
  },
  {
    id: 3,
    lat: 50.06465,
    long: 19.94498,
    name: 'Rynek Główny',
    street: 'plac Mariacki 1',
    location: '31-042 Kraków'
  },
  {
    id: 4,
    lat: 54.347637,
    long: 18.659486,
    name: 'Muzeum Narodowe',
    street: 'al. Jana Pawła II 9',
    location: '80-809 Gdańsk'
  },
  {
    id: 5,
    lat: 53.77842,
    long: 20.494232,
    name: 'Zamek Krzyżacki',
    street: 'ul. Zamkowa 2',
    location: '11-010 Olsztyn'
  },
  {
    id: 6,
    lat: 50.056087,
    long: 19.935453,
    name: 'Wawel',
    street: 'Wawel 5',
    location: '31-001 Kraków'
  },
  {
    id: 7,
    lat: 54.513851,
    long: 18.538007,
    name: 'Zaspa Młyniec',
    street: 'ul. Obrońców Wybrzeża 20',
    location: '80-398 Gdańsk'
  },
  {
    id: 8,
    lat: 52.406374,
    long: 16.925168,
    name: 'Park Kasprowicza',
    street: 'ul. Matejki 8',
    location: '60-767 Poznań'
  },
  {
    id: 9,
    lat: 50.064384,
    long: 19.921819,
    name: 'Muzeum Sztuki Współczesnej w Krakowie MOCAK',
    street: 'Lipowa 4',
    location: '30-702 Kraków'
  }
];
