import React from 'react';
import bgVideo from 'assets/video/romgos_intro_final.mp4';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
  return (
    <AuthSplitLayout bgProps={{ video: [bgVideo], position: '50% 20%' }}>
      <div className="text-center">
        <h4>Zapomniałeś hasła?</h4>
        <p>
          Twoje hasło może zostać zresetowane wyłącznie przez administratora
          systemu informatycznego w domenie romgos.pl.
        </p>
        <p>
          Skontaktuj się z działem IT lub swoim przełożonym aby odzyskać dostęp
          do aplikacji
        </p>
        <Button
          as={Link}
          color="primary"
          size="sm"
          className="mt-3"
          to={`/login`}
        >
          <FontAwesomeIcon
            icon="chevron-left"
            transform="shrink-4 down-1"
            className="me-1"
          />
          Powrót do logowania
        </Button>
      </div>
    </AuthSplitLayout>
  );
};

export default ForgetPassword;
