import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import EquipmentTableHeader from './ConstructionTrashHeader';
import useFormatDate from 'hooks/useFormatDate';

const columns = [
  {
    accessor: 'name',
    Header: 'Nazwa budowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name, malfunction } = rowData.row.original;
      return (
        <>
          <Link
            className={malfunction ? 'text-warning' : ''}
            to={`/construction/${_id}`}
          >
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'startDate',
    Header: 'Data rozpoczęcia',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { startDate } = rowData.row.original;
      const [formattedStartDate] = useFormatDate(startDate);
      return formattedStartDate;
    }
  },
  {
    accessor: 'location.formattedAddress',
    Header: 'Adres',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { locationName, streetName, city, zip, country } =
        rowData.row.original;
      return (
        <>{`${locationName}, ${streetName}, ${zip} ${city}, ${country} `}</>
      );
    }
  },
  {
    accessor: 'manager',
    Header: 'Osoba odpowiedzialna',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { manager } = rowData.row.original;
      return <>{`${manager.firstName} ${manager.lastName}`}</>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id } = rowData.row.original;
      const { loadingRows, handleRestore, handlePermanentDelete } =
        rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/equipment/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-success"
              onClick={() => handleRestore(_id)}
            >
              Przywróć
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handlePermanentDelete(_id)}
            >
              Usuń na stałe
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const EquipmentTrash = ({ loadingRows, setRowLoading }) => {
  const [constructionList, setConstructionList] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/construction?${queryParams}&deletedAt[nin]`
      );
      setConstructionList(response.data.construction);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleRestore = async id => {
    try {
      setRowLoading(id, true);
      await axios.put(`${process.env.REACT_APP_API_URL}/construction/${id}`, {
        deletedAt: null
      });
      toast.success('Budowa przywrócone pomyślnie');
      setConstructionList([]);
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(id, false);
    }
  };

  const handlePermanentDelete = async id => {
    const confirmation = window.confirm(
      'Czy na pewno chcesz usunąć budowy na stałe?'
    );
    if (confirmation) {
      try {
        setRowLoading(id, true);
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/construction/${id}`
        );
        toast.success('Budowa usunięte pomyślnie');
        setConstructionList([]);
      } catch (error) {
        console.error('Error updating data:', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    fetchData();
  }, [queryParams]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleRestore,
        handlePermanentDelete,
        loadingRows
      }))}
      data={constructionList}
      selection
      sortable
      pagination
      perPage={25}
      setClearFunction={setClearSelect}
    >
      <Card className="mb-3">
        <Card.Header>
          <EquipmentTableHeader
            table
            setConstructionList={setConstructionList}
            constructionList={constructionList}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={constructionList.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

EquipmentTrash.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(EquipmentTrash);
