import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const CustomButtonGroup = ({
  handleMoveUp,
  handleMoveDown,
  handleRemove,
  id
}) => {
  return (
    <div
      id={id}
      className="position-absolute end-0 top-0 gap-2 z-index-1 hover-actions mt-2 me-2"
    >
      <Button size="sm" variant="link" className="p-0" onClick={handleMoveUp}>
        <FontAwesomeIcon icon="chevron-up" />
      </Button>
      <Button size="sm" variant="link" className="p-0" onClick={handleMoveDown}>
        <FontAwesomeIcon icon="chevron-down" />
      </Button>
      <Button
        size="sm"
        variant="link"
        className="p-0"
        onClick={() => handleRemove(id)}
      >
        <FontAwesomeIcon className="text-danger" icon="times-circle" />
      </Button>
    </div>
  );
};

CustomButtonGroup.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  handleRemove: PropTypes.func,
  handleMoveDown: PropTypes.func,
  handleMoveUp: PropTypes.func
};

export default CustomButtonGroup;
