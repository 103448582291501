import React, { useState, useEffect } from 'react';
import BrigadeDetails from './BrigadeDetails';
import BrigadeUpload from './BrigadeUpload';
import BrigadeBar from './BrigadeBar';
import { Col, Form, Row } from 'react-bootstrap';
import BrigadeOtherInfo from './BrigadeOtherInfo';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import LoadingSpinner from 'components/common/LoadingSpinner';

const CreateBrigade = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('active');
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm();

  const { setValue } = methods;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/brigade/${id}`
          );
          const brigade = response.data.brigade;
          if (brigade) {
            setValue('name', brigade.name);
            setValue('description', brigade.description);
            setValue('manager', {
              value: brigade.manager._id,
              label: `${brigade.manager.firstName} ${brigade.manager.lastName}`
            });
            setValue(
              'users',
              brigade.users.map(user => ({
                value: user._id,
                label: `${user.firstName} ${user.lastName}`
              }))
            );
            setValue('uploadedFiles', '');
          }
        }
      } catch (error) {
        console.error('Failed to fetch brigade', error);
        toast.error('Błąd podczas pobierania brygady');
      }
    };

    fetchData();
  }, [id, methods.setValue]);

  const onSubmit = async data => {
    setIsLoading(true);

    const formData = new FormData();
    for (let key in data) {
      if (key === 'uploadedFiles') {
        for (let file of data[key]) {
          formData.append('uploadedFiles', file);
        }
      } else if (key === 'manager') {
        formData.append('manager', data[key].value);
      } else if (key === 'users') {
        data[key].forEach(user => {
          formData.append('users', user.value);
        });
      } else {
        formData.append(key, data[key]);
      }
    }
    formData.append('status', status);

    try {
      if (id) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/brigade/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        toast.success('Brygada została zaktualizowana');
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/brigade/add`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        toast.success(
          id ? 'Brygada została zapisana' : 'Brygada została dodana'
        );
      }
    } catch (error) {
      console.error('Failed to create/update brigade', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
      navigate('/brigade-list', { replace: true });
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="position-relative"
      >
        <Row className="g-3">
          <Col xs={12}>
            <BrigadeBar
              title={id ? 'Edytuj brygadę' : 'Dodaj brygadę'}
              setStatus={setStatus}
              isLoading={isLoading}
            />
          </Col>
          <Col lg={8}>
            <BrigadeDetails />
            <BrigadeUpload />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar mb-3">
              <BrigadeOtherInfo />
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {id ? 'Zapisz brygadę' : 'Dodaj brygadę'}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CreateBrigade;
