import React, { useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import PropTypes from 'prop-types';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import axios from 'axios';
import useTranslation from 'hooks/useTranslation';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PriorityBadge from 'components/common/PriorityBadge';

const TaskOtherInfo = ({ register, control, errors }) => {
  const { id } = useParams();
  const [disabledChecklist, setDisabledChecklist] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmitCategory = async () => {
    if (!categoryName)
      return toast.error('Nazwa kategorii jest wymagana', { theme: 'colored' });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tasks/categories/add`,
        {
          name: categoryName
        }
      );
      setCategoryName('');
      handleCloseModal();
      handleCategoriesAdded(response.data.category);
      toast.success('Dodano kategorię urządzenia', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleCategoriesAdded = addedCategories => {
    setCategoryOptions([
      { value: addedCategories._id, label: addedCategories.name },
      ...categoryOptions
    ]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch equipment options
        const equipmentResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/equipment/`
        );
        const equipmentOptionsData = equipmentResponse.data.equipment.map(
          ({ _id, equipmentId, name }) => ({
            value: _id,
            label: `${equipmentId} | ${name}`
          })
        );
        setEquipmentOptions(equipmentOptionsData);

        // Fetch user options
        const usersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/users?role=SERVICE`
        );
        const userOptionsData = usersResponse.data.users.map(
          ({ _id, firstName, lastName }) => ({
            value: _id,
            label: `${firstName} ${lastName}`
          })
        );
        setUserOptions(userOptionsData);

        // Fetch checklist options
        const checklistsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks/checklists/`
        );
        const checklistOptionsData = checklistsResponse.data.checklists.map(
          ({ _id, name }) => ({
            value: _id,
            label: name
          })
        );
        setChecklistOptions(checklistOptionsData);

        // Fetch category options
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks/categories/`
        );
        const categoryOptionsData = categoriesResponse.data.categories.map(
          ({ _id, name }) => ({
            value: _id,
            label: name
          })
        );
        setCategoryOptions(categoryOptionsData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();

    if (id) {
      setDisabledChecklist(true);
    }
  }, []);

  return (
    <Card>
      <Card.Header as="h5">Pozostałe informacje</Card.Header>
      <Card.Body className="bg-light">
        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Urządzenia</Form.Label>
          </Flex>
          <Controller
            name="equipment"
            rules={{ required: 'To pole jest wymagane' }}
            render={({ ref, field }) => (
              <MultiSelect
                {...field}
                ref={ref}
                closeMenuOnSelect={true}
                isMulti={false}
                options={equipmentOptions}
                placeholder="Wybierz urządzenie..."
                isInvalid={!!errors.equipment}
              />
            )}
            control={control}
          />
          {errors.equipment && (
            <Form.Text className="text-danger">
              {errors.equipment.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Pracownik</Form.Label>
          </Flex>
          <Controller
            name="users"
            rules={{ required: 'To pole jest wymagane' }}
            render={({ ref, field }) => (
              <MultiSelect
                ref={ref}
                {...field}
                closeMenuOnSelect={false}
                isMulti
                options={userOptions}
                placeholder="Wybierz pracownika..."
                isInvalid={!!errors.users}
              />
            )}
            control={control}
          />
          {errors.users && (
            <Form.Text className="text-danger">
              {errors.users.message}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Typ zadania</Form.Label>
          </Flex>
          <Controller
            name="type"
            rules={{ required: 'To pole jest wymagane' }}
            render={({ ref, field }) => (
              <MultiSelect
                {...field}
                ref={ref}
                closeMenuOnSelect={true}
                isMulti={false}
                options={[
                  {
                    value: 'Naprawa',
                    label: 'Naprawa'
                  },
                  {
                    value: 'Obsługa techniczna',
                    label: 'Obsługa techniczna'
                  },
                  {
                    value: 'Kontrola',
                    label: 'Kontrola'
                  }
                ]}
                placeholder="Wybierz typ zadania..."
                isInvalid={!!errors.type}
              />
            )}
            control={control}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Checklista</Form.Label>
          </Flex>
          <Controller
            name="checklist"
            rules={{ required: 'To pole jest wymagane' }}
            render={({ ref, field }) => (
              <>
                <MultiSelect
                  {...field}
                  ref={ref}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  className={id ? 'text-warning' : ''}
                  isDisabled={disabledChecklist}
                  options={checklistOptions}
                  placeholder="Wybierz checklistę..."
                  isInvalid={!!errors.checklist}
                />
                {disabledChecklist && (
                  <Form.Text
                    className="text-warning cursor-pointer"
                    onClick={() =>
                      id &&
                      window.confirm(
                        'Zmiana checklisty spowoduje wyczyszczenie postępów zadań!\nTej operacji nie można cofnąć!\n\nCzy na pewno chcesz odblokować możliwość zmiany checklisty?'
                      )
                        ? setDisabledChecklist(false)
                        : setDisabledChecklist(true)
                    }
                  >
                    Kliknij tutaj, aby odblokować zmianę checklisty.
                  </Form.Text>
                )}
              </>
            )}
            control={control}
          />
          {errors.checklist && (
            <Form.Text className="text-danger">
              {errors.checklist.message}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Flex alignItems="center" justifyContent="between">
            <Form.Label>Kategorie</Form.Label>
            <Button
              size="sm"
              variant="link"
              className="p-0"
              onClick={handleShowModal}
            >
              Dodaj nową
            </Button>
          </Flex>
          <Controller
            name="categories"
            render={({ field, ref }) => (
              <MultiSelect
                {...field}
                ref={ref}
                closeMenuOnSelect={false}
                isMulti
                options={categoryOptions}
                placeholder="Wybierz kategorię..."
              />
            )}
            control={control}
          />
        </Form.Group>

        <div className="border-dashed border-bottom my-3"></div>
        <h6>Priorytet </h6>

        <Form.Group className="mb-3">
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ columnGap: '10px' }}
          >
            <Form.Check className="mb-0 px-0" id="priorityLow">
              <Form.Check.Input
                type="radio"
                value="9"
                className="btn-check visibility-hidden"
                {...register(`priority`)}
              />
              <PriorityBadge priority="9" />
            </Form.Check>
            <Form.Check className="mb-0 px-0" id="priorityMedium">
              <Form.Check.Input
                type="radio"
                value="5"
                className="btn-check visibility-hidden"
                defaultChecked
                {...register(`priority`)}
              />
              <PriorityBadge priority="5" />
            </Form.Check>
            <Form.Check className="mb-0 px-0" id="priorityHigh">
              <Form.Check.Input
                type="radio"
                value="0"
                className="btn-check"
                style={{ width: 0, height: 0 }}
                {...register(`priority`)}
              />
              <PriorityBadge priority="0" />
            </Form.Check>
          </Flex>
          {errors.privacy && <span>This field is required</span>}
        </Form.Group>

        <div className="border-dashed border-bottom my-3"></div>

        <h6>Opcje prywatności</h6>
        <Form.Check className="mb-3" id="publicTask">
          <Form.Check.Input
            type="radio"
            value="public"
            defaultChecked
            {...register(`privacy`)}
          />
          <Form.Check.Label className="mb-0 form-label fw-semi-bold">
            Pozwalaj na wyświetlanie:
          </Form.Check.Label>
          <div className="form-text mt-0">
            Pozwól innym kierwonikom wyświetalać oraz edytować ten wpis.
          </div>
        </Form.Check>
        <Form.Check className="mb-3" id="privateTask">
          <Form.Check.Input
            type="radio"
            value="private"
            {...register(`privacy`)}
          />
          <Form.Check.Label className="mb-0 form-label fw-semi-bold">
            Nie pozwalaj na wyświetlanie:
          </Form.Check.Label>
          <div className="form-text mt-0">
            Tylko autor zadania może edytować ten wpis.
          </div>
        </Form.Check>

        <div className="border-dashed border-bottom my-3"></div>

        <h6>Powiadomienia</h6>
        <Form.Check
          id="taskNotification"
          type="checkbox"
          label="Otrzymuj powiadomienia o zmianach statusów zadania na adres e-mail"
          {...register(`taskNotification`)}
        />
      </Card.Body>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj kategorię</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoryName">
              <Form.Label>Nazwa kategorii</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę kategorii"
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleSubmitCategory}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};
TaskOtherInfo.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object
};

export default TaskOtherInfo;
