import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from './SoftBadge';

export const statusLabelsMap = {
  completed: 'Zakończone',
  active: 'W trakcie',
  pending: 'Do potwierdzenia',
  onhold: 'Wstrzymane',
  archived: 'Zarchiwizowane',
  draft: 'Szkic',
  trash: 'W koszu'
};

const statusColorsMap = {
  completed: 'success',
  active: 'primary',
  pending: 'secondary',
  draft: 'secondary',
  onhold: 'warning',
  archived: 'warning',
  trash: 'light'
};

const StatusBadge = ({ status, clickable = false, ...rest }) => {
  return (
    <SoftBadge
      pill
      bg={statusColorsMap[status]}
      classname={`d-block border border-${statusColorsMap[status]}-subtle ${
        rest.checked && `border border-${statusColorsMap[status]}`
      } ${clickable && 'cursor-pointer'}`}
      {...rest}
    >
      {statusLabelsMap[status]}
    </SoftBadge>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  clickable: PropTypes.bool
};

export default StatusBadge;
