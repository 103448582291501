/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTableFooter = ({
  page,
  pageSize,
  pageIndex,
  pageCount,
  rowCount,
  setPageSize,
  canPreviousPage,
  canNextPage,
  viewAllBtn,
  nextPage,
  previousPage,
  rowInfo,
  perPage,
  rowsPerPageSelection,
  navButtons,
  gotoPage,
  rowsPerPageOptions = [10, 25, 50, 100],
  className
}) => {
  const [isAllVisible, setIsAllVisible] = useState(false);
  const [customPage, setCustomPage] = useState('');

  const handleInputChange = e => {
    const value = e.target.value;
    setCustomPage(value);
  };

  const handlePageSubmit = e => {
    e.preventDefault();
    if (customPage !== '' && !isNaN(customPage)) {
      const page = Number(customPage) - 1;
      if (page >= 0 && page < pageCount) {
        gotoPage(page);
      }
    }
  };
  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}
    >
      <Flex alignItems="center" className="fs--1">
        {rowInfo && (
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              {pageSize * pageIndex + 1} to {pageSize * pageIndex + page.length}{' '}
              of {rowCount}
            </span>
            {viewAllBtn && (
              <>
                <span className="d-none d-sm-inline-block me-2">&mdash;</span>
                <Button
                  variant="link"
                  size="sm"
                  className="py-2 px-0 fw-semi-bold"
                  onClick={() => {
                    setIsAllVisible(!isAllVisible);
                    setPageSize(isAllVisible ? perPage : rowCount);
                  }}
                >
                  View {isAllVisible ? 'less' : 'all'}
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="ms-1 fs--2"
                  />
                </Button>
              </>
            )}
          </p>
        )}
        {rowsPerPageSelection && (
          <>
            <Form.Select
              size="sm"
              className="w-auto"
              onChange={e => setPageSize(Number(e.target.value))}
              defaultValue={pageSize}
            >
              {rowsPerPageOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
            <p className="mb-0 mx-2">Elementów na stronie</p>
          </>
        )}
      </Flex>
      {navButtons && (
        <Flex>
          <Button
            size="sm"
            variant={canPreviousPage ? 'primary' : 'light'}
            onClick={() => previousPage()}
            className={classNames({ disabled: !canPreviousPage })}
          >
            Previous
          </Button>
          <Button
            size="sm"
            variant={canNextPage ? 'primary' : 'light'}
            className={classNames('px-4 ms-2', {
              disabled: !canNextPage
            })}
            onClick={() => nextPage()}
          >
            Next
          </Button>
        </Flex>
      )}
      {gotoPage && (
        <Flex>
          <form
            onSubmit={handlePageSubmit}
            className="d-inline-flex align-items-center ms-3"
          >
            <input
              type="text"
              className="form-control form-control-sm"
              value={customPage}
              onChange={handleInputChange}
              style={{ width: '50px' }}
            />
            <Button
              type="submit"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Przejdź do strony
            </Button>
          </form>
        </Flex>
      )}
    </Flex>
  );
};

export default AdvanceTableFooter;
